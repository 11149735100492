









import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import { isDebugMode, validationPeriod } from '@/logic/utils';
import dayjs from 'dayjs';
import SpecialProductService from '@/logic/special-product.service';
import NotFound from '@/components/common/not-found.vue';
import '@/assets/special/style/common.scss';

// 特集ページの内容を実装したコンポーネントを読み込みます。
// ---- ↓ サンプル ------
import SampleComponent1 from '@/components/special/sample-component1.vue';
import SampleComponent2 from '@/components/special/sample-component2.vue';
import SampleComponent3 from '@/components/special/sample-component3.vue';
import SampleComponent4 from '@/components/special/sample-component4.vue';
import SampleAmountSimulator from '@/components/special/sample-amount-simulator.vue';
import Component20210401 from '@/components/special/2021/date-0401.vue';
import Component20210402 from '@/components/special/2021/date-0402-test.vue';
import VuetifyComponentList from '@/components/special/vuetify-component-list.vue';
// ---- ↑ サンプル ------

import Componentwireless from '@/components/special/general/wireless-image-share.vue';
// camera
import Componentdslr from '@/components/special/camera/dslr.vue';
import Storage from '@/components/special/camera/storage.vue';
import Lensfilter from '@/components/special/camera/lensfilter.vue';
import Girlscamera from '@/components/special/camera/girlscamera.vue';
import Operaglass from '@/components/special/camera/operaglass.vue';
import Frame from '@/components/special/camera/frame.vue';
import Photomount from '@/components/special/camera/photomount.vue';
import Hosizora from '@/components/special/camera/hosizora.vue';
import Travel from '@/components/special/camera/travel.vue';
//import OkamotoYutaka from '@/components/special/camera/okamoto-yutaka.vue';
import Wearable from '@/components/special/camera/wearable.vue';
import Mirrorlessoldlens from '@/components/special/camera/mirrorless-oldlens.vue';
import Strap from '@/components/special/camera/strap/strap.vue';
import Strap380 from '@/components/special/camera/strap/380.vue';
import Waterproof from '@/components/special/camera/waterproof.vue';
import Waterproof4129 from '@/components/special/camera/waterproof/4129.vue';
import Waterproof4131 from '@/components/special/camera/waterproof/4131.vue';
import Coolshot from '@/components/special/camera/coolshot.vue';
import Momijigari from '@/components/special/camera/momijigari.vue';
import Bird from '@/components/special/camera/bird/bird.vue';
import Birdcamera from '@/components/special/camera/bird/camera.vue';

// lensfilter
import Lensfilter1869 from '@/components/special/camera/lensfilter/1869.vue';
import Lensfilter2638 from '@/components/special/camera/lensfilter/2638.vue';
import Lensfilter1104 from '@/components/special/camera/lensfilter/1104.vue';
import LensfilterNd500 from '@/components/special/camera/lensfilter/nd500.vue';
import LensfilterExus from '@/components/special/camera/lensfilter/exus.vue';
import LensfilterNikonArcrest from '@/components/special/camera/lensfilter/nikon-arcrest.vue';
import Lensfilter341 from '@/components/special/camera/lensfilter/341.vue';
import Lensfilter343 from '@/components/special/camera/lensfilter/343.vue';
import Lensfilter344 from '@/components/special/camera/lensfilter/344.vue';
import LensfilterNikonArcrestnd from '@/components/special/camera/lensfilter/nikon-arcrest-nd.vue';

// original
import Originalkitamuracamera from '@/components/special/camera/original/kitamuracamera.vue';

// compact
import Componentcompact from '@/components/special/camera/compact/compact.vue';
import Componentcompactitem01 from '@/components/special/camera/compact/item01.vue';
import Componentcompactitem02 from '@/components/special/camera/compact/item02.vue';
// lens
import Componentlens from '@/components/special/camera/lens/lens.vue';
import Componentilenscanon from '@/components/special/camera/lens/item-canon.vue';
import Componentilensnikon from '@/components/special/camera/lens/item-nikon.vue';
import Componentilenspentax from '@/components/special/camera/lens/item-pentax.vue';
import Componentilenssony from '@/components/special/camera/lens/item-sony.vue';
import Componentilensmicroft from '@/components/special/camera/lens/item-microft.vue';
import Componentilensfuji from '@/components/special/camera/lens/item-fuji.vue';
import Componentilensbouen from '@/components/special/camera/lens/item-bouen.vue';
import Componentilenstansyouten from '@/components/special/camera/lens/item-tansyouten.vue';
import Componentilensmacro from '@/components/special/camera/lens/item-macro.vue';
import Componentilenskoukaku from '@/components/special/camera/lens/item-koukaku.vue';
import Componentutsurundesu from '@/components/special/camera/filmcamera/utsurundesu.vue';
import LensBatis from '@/components/special/camera/lens/batis.vue';
import Fujinon from '@/components/special/camera/lens/fujinon.vue';
import LensSony5074 from '@/components/special/camera/lens/sony/5074.vue';
import LensMzuiko from '@/components/special/camera/lens/mzuiko.vue';
import Lumixg from '@/components/special/camera/lens/lumixg.vue';
import Lens809 from '@/components/special/camera/lens/809.vue';
import LensFujifilmXf100400 from '@/components/special/camera/lens/fujifilm/xf100-400.vue';
import LensTouit from '@/components/special/camera/lens/touit.vue';
import LensTamron182701402 from '@/components/special/camera/lens/tamron18270/1402.vue';
import LensSonye from '@/components/special/camera/lens/sonye.vue';

// travel
import travelAccessory from '@/components/special/camera/travel/accessory.vue';
import travelMovingimage from '@/components/special/camera/travel/moving_image.vue';
import travelRecommendcamera from '@/components/special/camera/travel/recommended_camera.vue';
import travelWaterproof360 from '@/components/special/camera/travel/waterproof_360.vue';

//新製品扉
import Componentfeature from '@/components/special/camera/feature/feature.vue';
import Componentfeaturedslr from '@/components/special/camera/feature/dslr.vue';
import Componentfeaturelens from '@/components/special/camera/feature/lens.vue';
import Componentfeaturecompact from '@/components/special/camera/feature/compact.vue';
import Componentfeaturevideo from '@/components/special/camera/feature/video.vue';
import Leica from '@/components/special/camera/feature/leica.vue';
// canon
import CanonEosr5r6 from '@/components/special/camera/feature/canon/eosr5-r6.vue';
import CanonRf1435mmf4lisusm from '@/components/special/camera/feature/canon/rf14-35mmf4lisusm.vue';
import CanonTripod from '@/components/special/camera/tripod.vue';
import CanonRf100f28l from '@/components/special/camera/feature/canon/rf100-f28l.vue';
import CanonRf400f28l from '@/components/special/camera/feature/canon/rf400-f28l.vue';
import CanonEos90d from '@/components/special/camera/feature/canon/eos90d.vue';
import CanonEoskissm2 from '@/components/special/camera/feature/canon/eoskiss_m2.vue';
import CanonRf50f18 from '@/components/special/camera/feature/canon/rf50-f18.vue';
import CanonEosrp from '@/components/special/camera/feature/canon/eosrp.vue';
import CanonEoskissx10i from '@/components/special/camera/feature/canon/eoskissx10i.vue';
import Canon6dmk2 from '@/components/special/camera/feature/canon/6dmk2.vue';
import Canon7dmk2 from '@/components/special/camera/feature/canon/7dmk2.vue';
import CanonrF100500mmf4571lisusm from '@/components/special/camera/feature/canon/rf100-500mm-f4.5-7.1l-is-usm.vue';
import CanonrEosr3 from '@/components/special/camera/feature/canon/eosr3.vue';
import Canonr1dxmk3 from '@/components/special/camera/feature/canon/1dxmk3.vue';
import CanonrRf600mmRf800mmF11IsStm from '@/components/special/camera/feature/canon/rf600mm-rf800mm-f11-is-stm.vue';
import Canonrf70200 from '@/components/special/camera/feature/canon/rf70-200-f4l.vue';
import CanonEos80d from '@/components/special/camera/feature/canon/eos80d.vue';
import Canon5dmk4 from '@/components/special/camera/feature/canon/5dmk4.vue';
import CanonEosr from '@/components/special/camera/feature/canon/eosr.vue';
import Canon5ds from '@/components/special/camera/feature/canon/5ds.vue';
import CanonEoskiss10I4496 from '@/components/special/camera/feature/canon/eoskiss10I/4496.vue';
import CanonFv100 from '@/components/special/camera/feature/canon/fv100.vue';
import Canon1dxmk2 from '@/components/special/camera/feature/canon/1dxmk2.vue';
import CanonF28lis from '@/components/special/camera/feature/canon/f28lis.vue';
import CanonRf600mmrf800mmf11isstm4806 from '@/components/special/camera/feature/canon/rf600mm-rf800mm_f11_is_stm/4806.vue';
import CanonRf100mm400mmRf16 from '@/components/special/camera/feature/canon/rf100-400_rf16.vue';
import CanonRf800mmf56 from '@/components/special/camera/feature/canon/rf800mmf56-rf1200mmf8.vue';
import CanonEosr7 from '@/components/special/camera/feature/canon/eosr7.vue';
import CanonRf24f18 from '@/components/special/camera/feature/canon/rf24f18_rf15-30f45-63.vue';
import CanonRf135 from '@/components/special/camera/feature/canon/rf135.vue';
import CanonEosr6mk2 from '@/components/special/camera/feature/canon/eosr6mk2.vue';
import CanonEosr8 from '@/components/special/camera/feature/canon/eosr8.vue';
import CanonRf100mmf300 from '@/components/special/camera/feature/canon/rf100-300-f28l.vue';
import CanonRf1020mmf4l from '@/components/special/camera/feature/canon/rf10-20mmf4l.vue';
import CanonRf200800mmRf24105mm from '@/components/special/camera/feature/canon/rf200800mm-rf24105mm.vue';
import CanonEosr5mk2 from '@/components/special/camera/feature/canon/eosr5mk2.vue';
import CanonEosr1 from '@/components/special/camera/feature/canon/eosr1.vue';
// sony
import Sony7rm4a7rm3a from '@/components/special/camera/feature/sony/7rm4a-7rm3a.vue';
import Sonyfe14f18gm from '@/components/special/camera/feature/sony/fe14f18gm.vue';
import SonySel24f28g from '@/components/special/camera/feature/sony/sel24f28g.vue';
import SonySel50f12gm from '@/components/special/camera/feature/sony/sel50f12gm.vue';
import SonyCinemalineFx3 from '@/components/special/camera/feature/sony/cinemaline-fx3.vue';
import SonyA1 from '@/components/special/camera/feature/sony/a1.vue';
import SonySel35f14gm from '@/components/special/camera/feature/sony/sel35f14gm.vue';
import SonyActioncam from '@/components/special/camera/feature/sony/actioncam.vue';
import SonyVlogcamZv1Zv1g from '@/components/special/camera/feature/sony/vlogcam_zv1_zv1g.vue';
import Sony7sm3 from '@/components/special/camera/feature/sony/7sm3.vue';
import Sony7c from '@/components/special/camera/feature/sony/7c.vue';
import Sony7m3 from '@/components/special/camera/feature/sony/7m3.vue';
import Sony6600 from '@/components/special/camera/feature/sony/6600.vue';
import Sony7r4 from '@/components/special/camera/feature/sony/7r4.vue';
import SonyRx100m7 from '@/components/special/camera/feature/sony/rx100-m7.vue';
import SonyA5100 from '@/components/special/camera/feature/sony/a5100.vue';
import SonyWfc500 from '@/components/special/camera/feature/sony/wf-c500.vue';
import Sony9II from '@/components/special/camera/feature/sony/9II.vue';
import SonySel70200gm2 from '@/components/special/camera/feature/sony/sel70200gm2.vue';
import SonyRx100m3 from '@/components/special/camera/feature/sony/rx100m3.vue';
import SonyVlogcamzv1zv1g4709 from '@/components/special/camera/feature/sony/vlogcam_zv1_zv1g/4709.vue';
import SonyA9 from '@/components/special/camera/feature/sony/a9.vue';
import SonyA7r3 from '@/components/special/camera/feature/sony/a7r3.vue';
import SonyHx90vwx500 from '@/components/special/camera/feature/sony/hx90vwx500.vue';
import SonyA99ii from '@/components/special/camera/feature/sony/a99ii.vue';
import SonyA7r2 from '@/components/special/camera/feature/sony/a7r2.vue';
import SonyA7r689 from '@/components/special/camera/feature/sony/a7r/689.vue';
import SonyWh1000xm4lm from '@/components/special/camera/feature/sony/wh-1000xm4lm.vue';
import SonyA7iv from '@/components/special/camera/feature/sony/a7iv.vue';
import SonyZve10 from '@/components/special/camera/feature/sony/vlogcam_zv_e10.vue';
import SonyWm1zm2Wm1am2 from '@/components/special/camera/feature/sony/wm1zm2_wm1am2.vue';
import Sonywfl900 from '@/components/special/camera/feature/sony/linkbuds_wf-l900.vue';
import Sonyselp1635g from '@/components/special/camera/feature/sony/selp1635g.vue';
import Sonysel2470gm2 from '@/components/special/camera/feature/sony/sel2470gm2.vue';
import Sonywh1000xm5 from '@/components/special/camera/feature/sony/wh-1000xm5.vue';
import Sonywfls900n from '@/components/special/camera/feature/sony/wf-ls900n.vue';
import Sonyepz1020e11e15 from '@/components/special/camera/feature/sony/epz1020-e11-e15.vue';
import Sonyinzoneh9h7h3m9 from '@/components/special/camera/feature/sony/inzone_h9_h7_h3_m9.vue';
import Sonyfx30 from '@/components/special/camera/feature/sony/fx30.vue';
import Sonya7rv from '@/components/special/camera/feature/sony/a7rv.vue';
import Sonyzx707 from '@/components/special/camera/feature/sony/nw-zx707.vue';
import Sonyfe2070mm from '@/components/special/camera/feature/sony/fe20-70mmf4g.vue';
import Sonyfe50mmf14gm from '@/components/special/camera/feature/sony/fe50mmf14gm.vue';
import SonyZve1 from '@/components/special/camera/feature/sony/vlogcam_zv_e1.vue';
import SonyA6700 from '@/components/special/camera/feature/sony/a6700.vue';
import Sonyfe70200mm from '@/components/special/camera/feature/sony/fe70-200mmf4goss2.vue';
import Sonyfe1635mm from '@/components/special/camera/feature/sony/fe16-35mmf28gm2.vue';
// import Sonya7c2a7cr from '@/components/special/camera/feature/sony/a7c2-a7cr.vue';
import SonyA9III from '@/components/special/camera/feature/sony/a9III.vue';
import SonyFe300mmf28gmoss from '@/components/special/camera/feature/sony/fe300mmf28gmoss.vue';
import SonyFe24mm50mmf28g from '@/components/special/camera/feature/sony/fe24mm50mmf28g.vue';
import SonyFe1625mmf28g from '@/components/special/camera/feature/sony/fe16-25mmf28g.vue';
import SonyZve10m2 from '@/components/special/camera/feature/sony/zv-e10m2.vue';

// fujifilm
import FujifilmGfx100s from '@/components/special/camera/feature/fujifilm/gfx100s.vue';
import FujifilmXf70300mmf456rlmoiswr from '@/components/special/camera/feature/fujifilm/xf70-300mmf4-56rlm-ois-wr.vue';
import FujifilmXe4 from '@/components/special/camera/feature/fujifilm/x-e4.vue';
import FujifilmXs10 from '@/components/special/camera/feature/fujifilm/x-s10.vue';
import FujifilmXf18mmf14rlmwr from '@/components/special/camera/feature/fujifilm/xf18mmf14-r-lm-wr.vue';
import FujifilmXt4 from '@/components/special/camera/feature/fujifilm/xt4.vue';
import Fujifilmxf33f14 from '@/components/special/camera/feature/fujifilm/xf33f14.vue';
import Fujifilnewgoods from '@/components/special/camera/feature/fujifilm/newgoods.vue';
import FujifilmX100v from '@/components/special/camera/feature/fujifilm/x100v.vue';
import FujifilmXt200 from '@/components/special/camera/feature/fujifilm/x-t200.vue';
import FujifilmXpro3 from '@/components/special/camera/feature/fujifilm/xpro3.vue';
import Fujifilmxf50mmf1rwr from '@/components/special/camera/feature/fujifilm/xf50mm-f1rwr.vue';
import FujifilmXt44531 from '@/components/special/camera/feature/fujifilm/xt4/4531.vue';
import FujifilmInstaxMini11 from '@/components/special/camera/feature/fujifilm/instax/mini11.vue';
import Fujifilgfx50sII from '@/components/special/camera/feature/fujifilm/gfx50sII.vue';
import FujifilmXt3 from '@/components/special/camera/feature/fujifilm/xt3.vue';
import FujifilmXt2 from '@/components/special/camera/feature/fujifilm/xt2.vue';
import FujifilmXc35mmf2 from '@/components/special/camera/feature/fujifilm/xc35mm-f2.vue';
import FujifilmX100v4475 from '@/components/special/camera/feature/fujifilm/x100v/4475.vue';
import FujifilmXh2s from '@/components/special/camera/feature/fujifilm/xh2s.vue';
import FujifilmXf150600 from '@/components/special/camera/feature/fujifilm/xf150-600.vue';
import FujifilmXh2 from '@/components/special/camera/feature/fujifilm/xh2.vue';
import FujifilmXf18200xf56 from '@/components/special/camera/feature/fujifilm/xf18-200_xf56.vue';
import FujifilmXt5 from '@/components/special/camera/feature/fujifilm/xt5.vue';
import FujifilmXt5list from '@/components/special/camera/feature/fujifilm/xt5-list.vue';
import FujifilmXs20 from '@/components/special/camera/feature/fujifilm/x-s20.vue';
import FujifilmX100vi from '@/components/special/camera/feature/fujifilm/x-100vi.vue';

// nikon
import NikonZfc from '@/components/special/camera/feature/nikon/zfc.vue';
import NikonZ5 from '@/components/special/camera/feature/nikon/z_5.vue';
import NikonD780 from '@/components/special/camera/feature/nikon/D780.vue';
import NikonZ50 from '@/components/special/camera/feature/nikon/z_50.vue';
import NikonZ6IIZ7II from '@/components/special/camera/feature/nikon/z6II_z7II.vue';
import NikonD750 from '@/components/special/camera/feature/nikon/D750.vue';
import NikonD500 from '@/components/special/camera/feature/nikon/D500.vue';
import NikonD6 from '@/components/special/camera/feature/nikon/d6.vue';
//import NikonZ40mm from '@/components/special/camera/feature/nikon/z_40mm_f2.vue';
import NikonZ7z6 from '@/components/special/camera/feature/nikon/z7z6.vue';
import NikonD7500 from '@/components/special/camera/feature/nikon/d7500.vue';
import Nikonz50mmz14mm from '@/components/special/camera/feature/nikon/z50mm_z14mm.vue';
import NikonP950 from '@/components/special/camera/feature/nikon/p950.vue';
import NikonD850 from '@/components/special/camera/feature/nikon/d850.vue';
import NikonD5 from '@/components/special/camera/feature/nikon/d5.vue';
import NikonNikkorz70200mmf28vrs from '@/components/special/camera/feature/nikon/nikkorz_70-200mm_f2.8_vr_s.vue';
import NikonNikon1 from '@/components/special/camera/feature/nikon/nikon1.vue';
import NikonD810 from '@/components/special/camera/feature/nikon/d810.vue';
import NikonD4s from '@/components/special/camera/feature/nikon/d4s.vue';
import NikonZ9 from '@/components/special/camera/feature/nikon/z_9.vue';
import NikonDf from '@/components/special/camera/feature/nikon/df.vue';
import Nikonz24200mmf463vr from '@/components/special/camera/feature/nikon/z24-200mm_f4-6.3_vr.vue';
import NikonZnikkor120300mmf28efledsrvr from '@/components/special/camera/feature/nikon/nikkor_120_300mm_f2.8_e_fl_ed_sr_vr.vue';
import NikonZ20mmf18s from '@/components/special/camera/feature/nikon/z20mmf18s.vue';
import NikonZ100mm400mm from '@/components/special/camera/feature/nikon/z100-400mmf45-56vrs.vue';
import NikonZmc105mmf28 from '@/components/special/camera/feature/nikon/z_mc_105mm_f28_vr_s.vue';
import Nikonz800mmf63vrs from '@/components/special/camera/feature/nikon/z800mmf63vrs.vue';
import NikonZ30 from '@/components/special/camera/feature/nikon/z30.vue';
import NikonZ400mmf45 from '@/components/special/camera/feature/nikon/z400mmf45.vue';
import NikonZ600mmf4 from '@/components/special/camera/feature/nikon/z600mmf4.vue';
import NikonZ9list from '@/components/special/camera/feature/nikon/z_9-list.vue';
import NikonZ8 from '@/components/special/camera/feature/nikon/z_8.vue';
import NikonZ8list from '@/components/special/camera/feature/nikon/z_8-list.vue';
import NikonZ180600mmf5663 from '@/components/special/camera/feature/nikon/z180-600mmf56-63vr.vue';
import NikonZf from '@/components/special/camera/feature/nikon/z_f.vue';
import NikonZ600f63vrs from '@/components/special/camera/feature/nikon/z600f63vrs.vue';
import NikonZ6III from '@/components/special/camera/feature/nikon/z6III.vue';

// olympus
import OlympusPenEP7 from '@/components/special/camera/feature/olympus/pen-e-p7.vue';
import OlympusEm1m2 from '@/components/special/camera/feature/olympus/em1m2.vue';
import OlympusEm1m3 from '@/components/special/camera/feature/olympus/em1m3.vue';
import OlympusEm10 from '@/components/special/camera/feature/olympus/em10.vue';
import OlympusLens40150 from '@/components/special/camera/feature/olympus/lens40-150.vue';
import OlympusEm1m34484 from '@/components/special/camera/feature/olympus/em1m3/4484.vue';

// panasonic
import PanasonicGh5m2 from '@/components/special/camera/feature/panasonic/gh5m2.vue';
import Panasonicg100 from '@/components/special/camera/feature/panasonic/g100.vue';
import Panasonicg9 from '@/components/special/camera/feature/panasonic/g9.vue';
import Panasonictechnics from '@/components/special/camera/feature/panasonic/technics.vue';
import PanasonicCm10 from '@/components/special/camera/feature/panasonic/cm10.vue';
import PanasonicGh6 from '@/components/special/camera/feature/panasonic/gh6.vue';
import PanasonicDcs5m2 from '@/components/special/camera/feature/panasonic/dcs5m2.vue';
import PanasonicDcg9m2 from '@/components/special/camera/feature/panasonic/dcg9m2.vue';

// sigma
import SigmaFpl from '@/components/special/camera/feature/sigma/fp_l.vue';
// tamron
import Tamron150500mmf567 from '@/components/special/camera/feature/tamron/150-500mm-f5-6-7.vue';
// pentax
import Pentaxk3mk3 from '@/components/special/camera/feature/pentax/k-3mk3.vue';
import Pentaxk1 from '@/components/special/camera/feature/pentax/k-1.vue';
import Pentax645z from '@/components/special/camera/feature/pentax/645z.vue';
// ricoh
import Ricohgr3x from '@/components/special/camera/feature/ricoh/gr3x.vue';
import RicohThetasc from '@/components/special/camera/feature/ricoh/thetasc.vue';
// meishokougaku
import Meishokougaku11mmf28 from '@/components/special/camera/feature/meishokougaku/11mm_f2.8.vue';
// tokina
import Tokina4332 from '@/components/special/camera/feature/tokina/4332.vue';
// omsystem
import OmsystemOm1 from '@/components/special/camera/feature/omsystem/om1.vue';
import OmsystemOm5 from '@/components/special/camera/feature/omsystem/om5.vue';
import OmsystemEd90mmf35macro from '@/components/special/camera/feature/omsystem/ed90mmf35macro.vue';
import OmsystemOm1m2 from '@/components/special/camera/feature/omsystem/om1m2.vue';
import OmsystemEd150600mm from '@/components/special/camera/feature/omsystem/ed150-600mm.vue';

// general
import Tokutokukoukan from '@/components/special/general/tokutokukoukan.vue';
import Campaign from '@/components/special/general/campaign.vue';
import PrintServicesquare from '@/components/special/general/print-service/square.vue';
import Tuyutaisaku from '@/components/special/general/tuyutaisaku.vue';
//import Prepare from '@/components/special/general/prepare.vue';
import graduationEntrance from '@/components/special/general/graduation-entrance.vue';
import keirou from '@/components/special/general/keirou.vue';
import Pet from '@/components/special/general/pet.vue';
import Tokutokusueoki from '@/components/special/general/tokutokusueoki.vue';
import StoryAfterTaking from '@/components/special/general/story-after-taking.vue';
import highlyRecommended from '@/components/special/general/highly_recommended.vue';
import Summerhomework from '@/components/special/general/summerhomework.vue';
//import Photoconlearn from '@/components/special/general/photocon-learn.vue';
import graduation942 from '@/components/special/general/graduation_entrance/942.vue';
import graduation1442 from '@/components/special/general/graduation_entrance/1442.vue';
import graduation2017 from '@/components/special/general/graduation_entrance/2017.vue';
import graduation2035 from '@/components/special/general/graduation_entrance/2035.vue';
import graduation2044 from '@/components/special/general/graduation_entrance/2044.vue';
import graduation3278 from '@/components/special/general/graduation_entrance/3278.vue';
import graduation2698 from '@/components/special/general/graduation_entrance/2698.vue';
import Daikansyasai from '@/components/special/general/daikansyasai.vue';
import graduation5887 from '@/components/special/general/graduation_entrance/5887.vue';

// general/highly_recommended
import highlyRecommended20151120 from '@/components/special/general/highly_recommended/2015/1120.vue';
import highlyRecommended20150605 from '@/components/special/general/highly_recommended/2015/0605.vue';
import highlyRecommended20150410 from '@/components/special/general/highly_recommended/2015/0410.vue';
import highlyRecommended20151224 from '@/components/special/general/highly_recommended/2015/1224.vue';
import highlyRecommended20150501 from '@/components/special/general/highly_recommended/2015/0501.vue';
import highlyRecommended20150925 from '@/components/special/general/highly_recommended/2015/0925.vue';
import highlyRecommended20150828 from '@/components/special/general/highly_recommended/2015/0828.vue';
import highlyRecommended20150731 from '@/components/special/general/highly_recommended/2015/0731.vue';
import highlyRecommended20150522 from '@/components/special/general/highly_recommended/2015/0522.vue';
import highlyRecommended20150612 from '@/components/special/general/highly_recommended/2015/0612.vue';
import highlyRecommended20150327 from '@/components/special/general/highly_recommended/2015/0327.vue';
import highlyRecommended20150529 from '@/components/special/general/highly_recommended/2015/0529.vue';
import highlyRecommended20150807 from '@/components/special/general/highly_recommended/2015/0807.vue';
import highlyRecommended20150626 from '@/components/special/general/highly_recommended/2015/0626.vue';
import highlyRecommended20151015 from '@/components/special/general/highly_recommended/2015/1015.vue';
import highlyRecommended20150904 from '@/components/special/general/highly_recommended/2015/0904.vue';
import highlyRecommended20150403 from '@/components/special/general/highly_recommended/2015/0403.vue';
import highlyRecommended20194121 from '@/components/special/general/highly_recommended/2019/4121.vue';
import highlyRecommended20194357 from '@/components/special/general/highly_recommended/2019/4357.vue';
import highlyRecommended20194194 from '@/components/special/general/highly_recommended/2019/4194.vue';
import highlyRecommended20194261 from '@/components/special/general/highly_recommended/2019/4261.vue';
import highlyRecommended20194301 from '@/components/special/general/highly_recommended/2019/4301.vue';
import highlyRecommended20194161 from '@/components/special/general/highly_recommended/2019/4161.vue';
import highlyRecommended20194349 from '@/components/special/general/highly_recommended/2019/4349.vue';
import highlyRecommended20194380 from '@/components/special/general/highly_recommended/2019/4380.vue';
import highlyRecommended20194323 from '@/components/special/general/highly_recommended/2019/4323.vue';
import highlyRecommended20194318 from '@/components/special/general/highly_recommended/2019/4318.vue';
import highlyRecommended20194204 from '@/components/special/general/highly_recommended/2019/4204.vue';
import highlyRecommended20194367 from '@/components/special/general/highly_recommended/2019/4367.vue';
import highlyRecommended20194273 from '@/components/special/general/highly_recommended/2019/4273.vue';
import highlyRecommended20194242 from '@/components/special/general/highly_recommended/2019/4242.vue';
import highlyRecommended20204909 from '@/components/special/general/highly_recommended/2020/4909.vue';
import highlyRecommended20204725 from '@/components/special/general/highly_recommended/2020/4725.vue';
import highlyRecommended20204872 from '@/components/special/general/highly_recommended/2020/4872.vue';
import highlyRecommended20204722 from '@/components/special/general/highly_recommended/2020/4722.vue';
import highlyRecommended20204462 from '@/components/special/general/highly_recommended/2020/4462.vue';
import highlyRecommended20204925 from '@/components/special/general/highly_recommended/2020/4925.vue';
import highlyRecommended20204463 from '@/components/special/general/highly_recommended/2020/4463.vue';
import highlyRecommended20204830 from '@/components/special/general/highly_recommended/2020/4830.vue';
import highlyRecommended20204665 from '@/components/special/general/highly_recommended/2020/4665.vue';
import highlyRecommended20204541 from '@/components/special/general/highly_recommended/2020/4541.vue';
import highlyRecommended20204666 from '@/components/special/general/highly_recommended/2020/4666.vue';
import highlyRecommended20204723 from '@/components/special/general/highly_recommended/2020/4723.vue';
import highlyRecommended20204724 from '@/components/special/general/highly_recommended/2020/4724.vue';
import highlyRecommended20204829 from '@/components/special/general/highly_recommended/2020/4829.vue';
import highlyRecommended20204867 from '@/components/special/general/highly_recommended/2020/4867.vue';
import highlyRecommended20204914 from '@/components/special/general/highly_recommended/2020/4914.vue';
import highlyRecommended20151127 from '@/components/special/general/highly_recommended/2015/1127.vue';

// general/sportsday
import Sportsday from '@/components/special/general/sportsday.vue';
import Sportsday485 from '@/components/special/general/sportsday/485.vue';
import Sportsday531 from '@/components/special/general/sportsday/531.vue';
import Sportsday1196 from '@/components/special/general/sportsday/1196.vue';
import SportsdayPhotoKnowhow1 from '@/components/special/general/sportsday/photo-knowhow-1.vue';
import Sportsday532 from '@/components/special/general/sportsday/532.vue';
import Sportsday2786 from '@/components/special/general/sportsday/2786.vue';
import Sportsday486 from '@/components/special/general/sportsday/486.vue';

// general/photo_mono
import PhotomonoMacrolens from '@/components/special/general/photo-mono/macro-lens.vue';
import PhotomonoG9pro from '@/components/special/general/photo-mono/g9pro.vue';
import Photomono50mmlens from '@/components/special/general/photo-mono/50mmlens.vue';
import PhotomonoA7r3a9 from '@/components/special/general/photo-mono/a7r3-a9.vue';
import PhotomonoSonya9 from '@/components/special/general/photo-mono/sony-a9.vue';
import PhotomonoSigmaLens from '@/components/special/general/photo-mono/sigma-lens.vue';
// general/summerhomework
import SummerhomeworkMoon from '@/components/special/general/summerhomework/moon.vue';
import SummerhomeworkCamera from '@/components/special/general/summerhomework/camera.vue';
import SummerhomeworkObservationDiary from '@/components/special/general/summerhomework/observation-diary.vue';
import Summerhomework5988 from '@/components/special/general/summerhomework/5988.vue';
// general/stationery/mannenhitsu
import MannenhitsuNenga from '@/components/special/general/stationery/mannenhitsu/nenga.vue';
// general/how-to
import HowtoSkillup01 from '@/components/special/general/how-to/skillup/01.vue';
import HowtoNightView from '@/components/special/general/how-to/night-view.vue';
import GraduationEntranceFaq from '@/components/special/general/graduation_entrance/faq.vue';
import Howtohanabi from '@/components/special/general/how-to/hanabi.vue';
import HowtoGroup from '@/components/special/general/how-to/group.vue';
import HowtoSkillup02 from '@/components/special/general/how-to/skillup/02.vue';

// general/tokutokusueoki
import TokutokusueokiFelens from '@/components/special/general/tokutokusueoki/fe-lens.vue';
import TokutokusueokiRflens from '@/components/special/general/tokutokusueoki/rf-lens.vue';
import TokutokusueokiEflens from '@/components/special/general/tokutokusueoki/ef-lens.vue';
import TokutokusueokiZlens from '@/components/special/general/tokutokusueoki/z-lens.vue';
// general/watch
import WatchOmega from '@/components/special/general/watch/omega.vue';
// general/pet
import Pet1472 from '@/components/special/general/pet/1472.vue';

// page
import Deliver from '@/components/special/page/deliver.vue';
import TabletCalculateGuarantee from '@/components/special/page/tablet/calculate/guarantee.vue';
import Usednavi from '@/components/special/page/used-navi.vue';
import WrappingGift from '@/components/special/page/wrapping-gift.vue';
import Telephoneorderinfo from '@/components/special/page/telephoneorder-info.vue';
import Codepayment from '@/components/special/page/code-payment.vue';
import Paypay from '@/components/special/page/paypay.vue';
// page code-payment
import Paypay2 from '@/components/special/page/code-payment/paypay.vue';
import Rakutenpay from '@/components/special/page/code-payment/rakutenpay.vue';
import Aupay from '@/components/special/page/code-payment/aupay.vue';
import Merpay from '@/components/special/page/code-payment/merpay.vue';
import Dbarai from '@/components/special/page/code-payment/dbarai.vue';

// album
import AlbumFeature from '@/components/special/camera/album/feature.vue';
import AlbumFeature1985 from '@/components/special/camera/album/feature/1985.vue';
import AlbumFeature1986 from '@/components/special/camera/album/feature/1986.vue';
import AlbumFeature1996 from '@/components/special/camera/album/feature/1996.vue';
import AlbumFeature1997 from '@/components/special/camera/album/feature/1997.vue';
import AlbumOriginal1119 from '@/components/special/camera/album/original/1119.vue';
import Album from '@/components/special/camera/album.vue';
import Album1439 from '@/components/special/camera/album/1439.vue';
import Album1440 from '@/components/special/camera/album/1440.vue';
// import AlbumOriginal2052 from '@/components/special/camera/album/original/2052.vue';
import Album3765 from '@/components/special/camera/album/3765.vue';
import Album3766 from '@/components/special/camera/album/3766.vue';
import AlbumStorageWonderphotobox from '@/components/special/camera/album/storage/wonderphotobox.vue';

// other
import Backnumber from '@/components/special/camera/feature/backnumber.vue';
import Salefair from '@/components/special/sale-fair.vue';
import filmcamera from '@/components/special/camera/filmcamera/filmcamera.vue';
import filmcamera889 from '@/components/special/camera/filmcamera/889.vue';
import filmcamera890 from '@/components/special/camera/filmcamera/890.vue';
import filmcamera891 from '@/components/special/camera/filmcamera/891.vue';
import filmcamera892 from '@/components/special/camera/filmcamera/892.vue';

// polarie
import Polarie from '@/components/special/camera/polarie.vue';
import PolarieAcc from '@/components/special/camera/polarie/acc.vue';
import PolarieStartrail from '@/components/special/camera/polarie/startrail.vue';

// astronomical
import astronomical from '@/components/special/camera/astronomical/astronomical.vue';
import Moonlook from '@/components/special/camera/astronomical/moonlook.vue';
import Telescope from '@/components/special/camera/astronomical/telescope.vue';
import TelescopeFeature from '@/components/special/camera/astronomical/telescope-feature.vue';
// printer
import Printer759 from '@/components/special/camera/printer/759.vue';
import Printer3133 from '@/components/special/camera/printer/3133.vue';
import Printer from '@/components/special/camera/printer/printer.vue';
import PhotoPrinter from '@/components/special/camera/photoprinter.vue';
// kaden
import Iphone from '@/components/special/kaden/smart_phone/iphone.vue';
import XsXsMaxXr from '@/components/special/kaden/smart_phone/iphone/xs_xsmax_xr.vue';
import Componentaircleanercompare from '@/components/special/kaden/air-purifier/aircleanercompare.vue';
import KadenRiceCookerBestbuy from '@/components/special/kaden/rice-cooker-bestbuy.vue';
import KadenSmartPhoneIphone7 from '@/components/special/kaden/smart_phone/iphone7.vue';
import KadenToasterOven from '@/components/special/kaden/toaster-oven.vue';
import KadenRicemillInterview from '@/components/special/kaden/ricemill/interview.vue';
import KadenAirPurifierDaikin from '@/components/special/kaden/air-purifier/daikin.vue';
import KadenHeyabosi from '@/components/special/kaden/heyabosi.vue';
import KadenSmartphoneIphone7Iphone7camera from '@/components/special/kaden/smart_phone/iphone7/iphone7-camera.vue';
import KadenDcfan from '@/components/special/kaden/dcfan.vue';
import KadenBottle from '@/components/special/kaden/bottle.vue';
import KadenBento from '@/components/special/kaden/bento.vue';
import KadenFanfair from '@/components/special/kaden/fan-fair.vue';
import KadenSmartPhoneIphone12 from '@/components/special/kaden/smart_phone/iphone12.vue';
import KadenPanasonicDishwasher from '@/components/special/kaden/panasonic-dishwasher.vue';
import Kadenaircleanerfair from '@/components/special/kaden/air-purifier_fair/air-purifier_fair.vue';
import KadenBottleKids from '@/components/special/kaden/bottle/kids.vue';
import KadenBottleSports from '@/components/special/kaden/bottle/sports.vue';
import KadenBottleOffice from '@/components/special/kaden/bottle/office.vue';
import Kadenheater from '@/components/special/kaden/heater-best.vue';
import KadenRicemill from '@/components/special/kaden/ricemill/ricemill.vue';
import KadenFall from '@/components/special/kaden/fall-of-taste.vue';
import KadenSixpad from '@/components/special/kaden/sixpad.vue';
import KadenDigitalization from '@/components/special/kaden/digitalization.vue';
// kadencampaign
import KadenCpirisohyama240412 from '@/components/special/kaden/campaign/irisohyama240412.vue';

// bag/feature
import Trekking from '@/components/special/camera/bag/feature/trekking.vue';
import Trial from '@/components/special/camera/bag/trial/trial.vue';
import Bag from '@/components/special/camera/bag/bag.vue';
// photomount
import Photomount244 from '@/components/special/camera/photomount/244.vue';
import Photomount253 from '@/components/special/camera/photomount/253.vue';
import Photomount252 from '@/components/special/camera/photomount/252.vue';
import Photomount254 from '@/components/special/camera/photomount/254.vue';
import Photomount262 from '@/components/special/camera/photomount/262.vue';
import Photomount266 from '@/components/special/camera/photomount/266.vue';
import Photomount269 from '@/components/special/camera/photomount/269.vue';
import Photomount316 from '@/components/special/camera/photomount/316.vue';
import Photomount318 from '@/components/special/camera/photomount/318.vue';
import Photomount914 from '@/components/special/camera/photomount/914.vue';
import Photomount1362 from '@/components/special/camera/photomount/1362.vue';
import Photomount2433 from '@/components/special/camera/photomount/2433.vue';
import PhotomountFeature from '@/components/special/camera/photomount/feature.vue';
import PhotomountFeature317 from '@/components/special/camera/photomount/feature/317.vue';
import Photomount246 from '@/components/special/camera/photomount/246.vue';
import Photomount245 from '@/components/special/camera/photomount/245.vue';
// okamoto-yutaka
//import OkamotoYutakaLeofoto from '@/components/special/camera/okamoto-yutaka/leofoto.vue';
//import OkamotoYutakaSet from '@/components/special/camera/okamoto-yutaka/set.vue';
//tripod
import TripodType01 from '@/components/special/camera/tripod/type01.vue';
import TripodType02 from '@/components/special/camera/tripod/type02.vue';
import TripodType03 from '@/components/special/camera/tripod/type03.vue';
import TripodType04 from '@/components/special/camera/tripod/type04.vue';
import TripodType05 from '@/components/special/camera/tripod/type05.vue';
import TripodType06 from '@/components/special/camera/tripod/type06.vue';
import TripodParts01 from '@/components/special/camera/tripod/parts01.vue';
import TripodParts02 from '@/components/special/camera/tripod/parts02.vue';
import TripodParts03 from '@/components/special/camera/tripod/parts03.vue';
// dslr
import Dslr3228 from '@/components/special/camera/dslr/3228.vue';
import Dslr3229 from '@/components/special/camera/dslr/3229.vue';
// frame
import Frameordermade from '@/components/special/camera/frame/ordermade.vue';
import FrameShacolla from '@/components/special/camera/frame/shacolla.vue';
import Frame4086 from '@/components/special/camera/frame/4086.vue';
import Frame2037 from '@/components/special/camera/frame/2037.vue';
import Frame833 from '@/components/special/camera/frame/833.vue';
import Frame443 from '@/components/special/camera/frame/443.vue';
import Frame2441 from '@/components/special/camera/frame/2441.vue';
import Frame470 from '@/components/special/camera/frame/470.vue';
import Frame2446 from '@/components/special/camera/frame/2446.vue';
import Frame2889 from '@/components/special/camera/frame/2889.vue';
import Frame2442 from '@/components/special/camera/frame/2442.vue';
import Frame459 from '@/components/special/camera/frame/459.vue';
import Frame2440 from '@/components/special/camera/frame/2440.vue';
import Frame456 from '@/components/special/camera/frame/456.vue';
import Frame471 from '@/components/special/camera/frame/471.vue';
import Corkframe from '@/components/special/camera/frame/cork-frame.vue';
import Sapele from '@/components/special/camera/frame/sapele.vue';
// storage
import Storage363 from '@/components/special/camera/storage/363.vue';
import Storage366 from '@/components/special/camera/storage/366.vue';
import Storage368 from '@/components/special/camera/storage/368.vue';
import Storage371 from '@/components/special/camera/storage/371.vue';
// sale
import Sale534 from '@/components/special/sale/feature01/534.vue';
import Sale535 from '@/components/special/sale/feature01/535.vue';
import Sale536 from '@/components/special/sale/feature01/536.vue';
import Sale537 from '@/components/special/sale/feature01/537.vue';
import Saleused from '@/components/special/sale/usedsale/usedsale.vue';
import Saleusednikon from '@/components/special/sale/usedsale/nikon/nikon.vue';
import Saleusednikonlens from '@/components/special/sale/usedsale/nikon/lens.vue';
import Saleusednikondslr from '@/components/special/sale/usedsale/nikon/dslr.vue';
import Saleusednikonmirrorless from '@/components/special/sale/usedsale/nikon/mirrorless.vue';
import Saleusednikoncompact from '@/components/special/sale/usedsale/nikon/compact.vue';
import Saleusednikonfilmcamera from '@/components/special/sale/usedsale/nikon/filmcamera.vue';
import Saleusednikonother from '@/components/special/sale/usedsale/nikon/other.vue';
import Saleusedcanon from '@/components/special/sale/usedsale/canon/canon.vue';
import Saleusedcanonlens from '@/components/special/sale/usedsale/canon/lens.vue';
import Saleusedcanondslr from '@/components/special/sale/usedsale/canon/dslr.vue';
import Saleusedcanonmirrorless from '@/components/special/sale/usedsale/canon/mirrorless.vue';
import Saleusedcanoncompact from '@/components/special/sale/usedsale/canon/compact.vue';
import Saleusedcanonfilmcamera from '@/components/special/sale/usedsale/canon/filmcamera.vue';
import Saleusedcanonother from '@/components/special/sale/usedsale/canon/other.vue';
import Saleusedsony from '@/components/special/sale/usedsale/sony/sony.vue';
import Saleusedsonylens from '@/components/special/sale/usedsale/sony/lens.vue';
import Saleusedsonydslr from '@/components/special/sale/usedsale/sony/dslr.vue';
import Saleusedsonymirrorless from '@/components/special/sale/usedsale/sony/mirrorless.vue';
import Saleusedsonycompact from '@/components/special/sale/usedsale/sony/compact.vue';
import Saleusedsonyother from '@/components/special/sale/usedsale/sony/other.vue';
import Saleusedfujifilm from '@/components/special/sale/usedsale/fujifilm/fujifilm.vue';
import Saleusedfujifilmlens from '@/components/special/sale/usedsale/fujifilm/lens.vue';
import Saleusedfujifilmdslr from '@/components/special/sale/usedsale/fujifilm/dslr.vue';
import Saleusedfujifilmmirrorless from '@/components/special/sale/usedsale/fujifilm/mirrorless.vue';
import Saleusedfujifilmcompact from '@/components/special/sale/usedsale/fujifilm/compact.vue';
import Saleusedfujifilmfilmcamera from '@/components/special/sale/usedsale/fujifilm/filmcamera.vue';
import Saleusedfujifilmother from '@/components/special/sale/usedsale/fujifilm/other.vue';
import Saleusedolympus from '@/components/special/sale/usedsale/olympus/olympus.vue';
import Saleusedolympuslens from '@/components/special/sale/usedsale/olympus/lens.vue';
import Saleusedolympusdslr from '@/components/special/sale/usedsale/olympus/dslr.vue';
import Saleusedolympusmirrorless from '@/components/special/sale/usedsale/olympus/mirrorless.vue';
import Saleusedolympuscompact from '@/components/special/sale/usedsale/olympus/compact.vue';
import Saleusedolympusfilmcamera from '@/components/special/sale/usedsale/olympus/filmcamera.vue';
import Saleusedolympusother from '@/components/special/sale/usedsale/olympus/other.vue';
import Saleusedpanasonic from '@/components/special/sale/usedsale/panasonic/panasonic.vue';
import Saleusedpanasoniclens from '@/components/special/sale/usedsale/panasonic/lens.vue';
import Saleusedpanasonicmirrorless from '@/components/special/sale/usedsale/panasonic/mirrorless.vue';
import Saleusedpanasoniccompact from '@/components/special/sale/usedsale/panasonic/compact.vue';
import Saleusedpanasonicother from '@/components/special/sale/usedsale/panasonic/other.vue';
import Saleusedpentax from '@/components/special/sale/usedsale/pentax/pentax.vue';
import Saleusedpentaxlens from '@/components/special/sale/usedsale/pentax/lens.vue';
import Saleusedpentaxdslr from '@/components/special/sale/usedsale/pentax/dslr.vue';
import Saleusedpentaxmirrorless from '@/components/special/sale/usedsale/pentax/mirrorless.vue';
import Saleusedpentaxcompact from '@/components/special/sale/usedsale/pentax/compact.vue';
import Saleusedpentaxfilmcamera from '@/components/special/sale/usedsale/pentax/filmcamera.vue';
import Saleusedpentaxother from '@/components/special/sale/usedsale/pentax/other.vue';
import Saleusedsigma from '@/components/special/sale/usedsale/sigma/sigma.vue';
import Saleusedsigmalens from '@/components/special/sale/usedsale/sigma/lens.vue';
import Saleusedsigmamirrorless from '@/components/special/sale/usedsale/sigma/mirrorless.vue';
import Saleusedsigmaother from '@/components/special/sale/usedsale/sigma/other.vue';
import Saleusedtamron from '@/components/special/sale/usedsale/tamron/tamron.vue';
import Saleusedtamronlens from '@/components/special/sale/usedsale/tamron/lens.vue';
import Saleusedtamronother from '@/components/special/sale/usedsale/tamron/other.vue';
import Saleusedzeiss from '@/components/special/sale/usedsale/zeiss/zeiss.vue';
import Saleusedzeisslens from '@/components/special/sale/usedsale/zeiss/lens.vue';
import Saleusedleica from '@/components/special/sale/usedsale/leica/leica.vue';
import Saleusedleicalens from '@/components/special/sale/usedsale/leica/lens.vue';
import Saleusedleicadslr from '@/components/special/sale/usedsale/leica/dslr.vue';
import Saleusedleicamirrorless from '@/components/special/sale/usedsale/leica/mirrorless.vue';
import Saleusedleicacompact from '@/components/special/sale/usedsale/leica/compact.vue';
import Saleusedleicafilmcamera from '@/components/special/sale/usedsale/leica/filmcamera.vue';
import Saleusedleicaother from '@/components/special/sale/usedsale/leica/other.vue';
import Saleusedother from '@/components/special/sale/usedsale/other/othertop.vue';
import Saleusedotherlens from '@/components/special/sale/usedsale/other/lens.vue';
import Saleusedotherdslr from '@/components/special/sale/usedsale/other/dslr.vue';
import Saleusedothermirrorless from '@/components/special/sale/usedsale/other/mirrorless.vue';
import Saleusedothercompact from '@/components/special/sale/usedsale/other/compact.vue';
import Saleusedotherfilmcamera from '@/components/special/sale/usedsale/other/filmcamera.vue';
import Saleusedotherother from '@/components/special/sale/usedsale/other/other.vue';
import Sale716 from '@/components/special/sale/feature02/716.vue';
import Sale717 from '@/components/special/sale/feature02/717.vue';
import Sale718 from '@/components/special/sale/feature02/718.vue';
import Sale789 from '@/components/special/sale/feature01/789.vue';
import Sale4897 from '@/components/special/sale/feature01/4897.vue';
import Saleusedweek from '@/components/special/sale/usedsale/usedsaleweek.vue';
import Saleusednikonlens2 from '@/components/special/sale/usedsale/nikon/lens2.vue';
import Saleusedcanonlens2 from '@/components/special/sale/usedsale/canon/lens2.vue';
import Sale4898 from '@/components/special/sale/feature01/4898.vue';
import Sale5827 from '@/components/special/sale/feature01/5827.vue';
import Salehatsuuri from '@/components/special/sale/hatsuuri/hatsuuri.vue';
import Sale1738 from '@/components/special/sale/hatsuuri/1738.vue';
import Sale1905 from '@/components/special/sale/hatsuuri/1905.vue';
import Sale1906 from '@/components/special/sale/hatsuuri/1906.vue';
import Sale1907 from '@/components/special/sale/hatsuuri/1907.vue';
import Sale1908 from '@/components/special/sale/hatsuuri/1908.vue';
import Sale2175 from '@/components/special/sale/hatsuuri/2175.vue';
import Sale4398 from '@/components/special/sale/hatsuuri/4398.vue';
import Sale5731 from '@/components/special/sale/hatsuuri/5731.vue';
import Sale5732 from '@/components/special/sale/hatsuuri/5732.vue';
import Sale5733 from '@/components/special/sale/hatsuuri/5733.vue';
import Sale5734 from '@/components/special/sale/hatsuuri/5734.vue';
import Sale5735 from '@/components/special/sale/hatsuuri/5735.vue';
import Sale5736 from '@/components/special/sale/hatsuuri/5736.vue';
import Sale5507 from '@/components/special/sale/hatsuuri/5507.vue';
import Saleusedflash from '@/components/special/sale/usedsale/usedflashsale.vue';
import Saleusedflash5864 from '@/components/special/sale/usedsale/5864.vue';
import Saleusedflash5865 from '@/components/special/sale/usedsale/5865.vue';
import Saleusedflash5866 from '@/components/special/sale/usedsale/5866.vue';
import Saleusedflash5867 from '@/components/special/sale/usedsale/5867.vue';
import Saleusedminoltalens from '@/components/special/sale/usedsale/minolta/lens.vue';
import Saleusedminoltafilmcamera from '@/components/special/sale/usedsale/minolta/filmcamera.vue';
import Saleusedcontaxlens from '@/components/special/sale/usedsale/contax/lens.vue';
import Saleusedcontaxfilmcamera from '@/components/special/sale/usedsale/contax/filmcamera.vue';
import Saleusedmamiyalens from '@/components/special/sale/usedsale/mamiya/lens.vue';
import Saleusedmamiyafilmcamera from '@/components/special/sale/usedsale/mamiya/filmcamera.vue';
import Usedcameracoupon from '@/components/special/sale/usedcameracoupon/usedcameracoupon.vue';
import Sale569 from '@/components/special/sale/feature03/569.vue';
import Sale2150 from '@/components/special/sale/feature03/2150.vue';
import UsedcameracouponItem from '@/components/special/sale/usedcameracoupon/usedcameracouponitem.vue';
// reuse
import Reusewatch from '@/components/special/reuse/watch.vue';
import Reusesmartphone from '@/components/special/reuse/smartphone.vue';
import Reusewatchsample from '@/components/special/reuse/watch-hinagata.vue';
import ReuseRpc from '@/components/special/reuse/rpc-usedpc.vue';
//import Reuserefurbish from '@/components/special/reuse/refurbish.vue';
import ReuseUsedpc from '@/components/special/reuse/usedpc.vue';
import ReuseUsededitingpc from '@/components/special/reuse/photo-editing-pc.vue';
import ReuseUsedpcranking from '@/components/special/reuse/ranking.vue';
// reuse sale
import Reusesale from '@/components/special/sale/reusesale/219.vue';
// reuse sell
//import Reusewatchsell from '@/components/special/reuse/watchsell.vue';
//import Reusesmartphonesell from '@/components/special/reuse/smartphonesell.vue';
//import Reusesplogicenter from '@/components/special/reuse/splogicenter.vue';
import Reusewatchrolex from '@/components/special/reuse/rolex.vue';
import Reusewatchomega from '@/components/special/reuse/omega.vue';
import Reusejapanmadewatch from '@/components/special/reuse/japanmadewatch.vue';
import Reusewatchnakano from '@/components/special/reuse/kitamuracamera-nakano.vue';
import Reusewatchclearancesale from '@/components/special/reuse/clearancesale.vue';
/**
 * 読み込んだコンポーネントを適用させるための設定です。
 * 適用させたいコンポーネントを追加してください。
 *
 * 任意の'コンポーネント名（区切る場合は、ハイフンを使用してください。）': 上記でimportしたコンポーネント名
 */
const COMPONENT_LIST = {
  // 表示期間外の表示用のため、削除しないでください。
  'not-found': NotFound,
  // ---- ↓ サンプル ------
  sample1: SampleComponent1,
  sample2: SampleComponent2,
  sample3: SampleComponent3,
  sample4: SampleComponent4,
  'sample-amount-simulator': SampleAmountSimulator,
  component20210401: Component20210401,
  'component-20210402': Component20210402,
  vuetify: VuetifyComponentList,
  // ---- ↑ サンプル ------

  //original
  'camera/original/kitamuracamera': Originalkitamuracamera,
  // compact
  'camera/compact': Componentcompact,
  'camera/compact/item01': Componentcompactitem01,
  'camera/compact/item02': Componentcompactitem02,
  // lens
  'camera/lens': Componentlens,
  'camera/lens/itemcanon': Componentilenscanon,
  'camera/lens/itemnikon': Componentilensnikon,
  'camera/lens/itempentax': Componentilenspentax,
  'camera/lens/itemsony': Componentilenssony,
  'camera/lens/itemmicroft': Componentilensmicroft,
  'camera/lens/itemfuji': Componentilensfuji,
  'camera/lens/itembouen': Componentilensbouen,
  'camera/lens/itemtansyouten': Componentilenstansyouten,
  'camera/lens/itemmacro': Componentilensmacro,
  'camera/lens/itemkoukaku': Componentilenskoukaku,
  'camera/lens/batis': LensBatis,
  'camera/lens/fujinon': Fujinon,
  'camera/lens/sony/5074': LensSony5074,
  'camera/lens/mzuiko': LensMzuiko,
  'camera/lens/lumixg': Lumixg,
  'camera/lens/809': Lens809,
  'camera/lens/fujifilm/xf100-400': LensFujifilmXf100400,
  'camera/lens/touit': LensTouit,
  'camera/lens/tamron18270/1402': LensTamron182701402,
  'camera/lens/sonye': LensSonye,

  // page
  'page/deliver': Deliver,
  'page/tablet/calculate/guarantee': TabletCalculateGuarantee,
  'page/used-navi': Usednavi,
  'page/wrapping-gift': WrappingGift,
  'page/telephoneorder-info': Telephoneorderinfo,
  'page/code-payment': Codepayment,
  'page/paypay': Paypay,
  // page code-payment
  'page/code-payment/paypay': Paypay2,
  'page/code-payment/rakutenpay': Rakutenpay,
  'page/code-payment/aupay': Aupay,
  'page/code-payment/merpay': Merpay,
  'page/code-payment/dbarai': Dbarai,

  // album
  'camera/album/feature': AlbumFeature,
  'camera/album/feature/1985': AlbumFeature1985,
  'camera/album/feature/1986': AlbumFeature1986,
  'camera/album/feature/1996': AlbumFeature1996,
  'camera/album/feature/1997': AlbumFeature1997,
  'camera/album/original/1119': AlbumOriginal1119,
  'camera/album': Album,
  'camera/album/1439': Album1439,
  'camera/album/1440': Album1440,
  // 'camera/album/original/2052': AlbumOriginal2052,
  'camera/album/3765': Album3765,
  'camera/album/3766': Album3766,
  'camera/album/storage/wonderphotobox': AlbumStorageWonderphotobox,

  // general
  'general/tokutokukoukan': Tokutokukoukan,
  'general/campaign': Campaign,
  'general/tuyutaisaku': Tuyutaisaku,
  'general/graduationentrance': graduationEntrance,
  //'general/prepare': Prepare,
  'general/wirelessimageshare': Componentwireless,
  'general/keirou': keirou,
  'general/pet': Pet,
  'general/tokutokusueoki': Tokutokusueoki,
  'general/story_after_taking': StoryAfterTaking,
  'general/highly_recommended': highlyRecommended,
  'general/summerhomework': Summerhomework,
  //'general/photocon-learn': Photoconlearn,
  'general/graduationentrance/942': graduation942,
  'general/graduationentrance/1442': graduation1442,
  'general/graduationentrance/2017': graduation2017,
  'general/graduationentrance/2035': graduation2035,
  'general/graduationentrance/2044': graduation2044,
  'general/graduationentrance/3278': graduation3278,
  'general/graduationentrance/2698': graduation2698,
  'general/daikansyasai': Daikansyasai,
  'general/graduationentrance/5887': graduation5887,

  // general/sportsday
  'general/sportsday': Sportsday,
  'general/sportsday/485': Sportsday485,
  'general/sportsday/531': Sportsday531,
  'general/sportsday/1196': Sportsday1196,
  'general/sportsday/photo-knowhow-1': SportsdayPhotoKnowhow1,
  'general/sportsday/532': Sportsday532,
  'general/sportsday/2786': Sportsday2786,
  'general/sportsday/486': Sportsday486,
  // general/highly_recommended
  'general/highly_recommended/2015/1120': highlyRecommended20151120,
  'general/highly_recommended/2015/0605': highlyRecommended20150605,
  'general/highly_recommended/2015/0410': highlyRecommended20150410,
  'general/highly_recommended/2015/1224': highlyRecommended20151224,
  'general/highly_recommended/2015/0501': highlyRecommended20150501,
  'general/highly_recommended/2015/0925': highlyRecommended20150925,
  'general/highly_recommended/2015/0828': highlyRecommended20150828,
  'general/highly_recommended/2015/0731': highlyRecommended20150731,
  'general/highly_recommended/2015/0522': highlyRecommended20150522,
  'general/highly_recommended/2015/0612': highlyRecommended20150612,
  'general/highly_recommended/2015/0327': highlyRecommended20150327,
  'general/highly_recommended/2015/0529': highlyRecommended20150529,
  'general/highly_recommended/2015/0807': highlyRecommended20150807,
  'general/highly_recommended/2015/0626': highlyRecommended20150626,
  'general/highly_recommended/2015/1015': highlyRecommended20151015,
  'general/highly_recommended/2015/0904': highlyRecommended20150904,
  'general/highly_recommended/2015/0403': highlyRecommended20150403,
  'general/highly_recommended/2019/4121': highlyRecommended20194121,
  'general/highly_recommended/2019/4357': highlyRecommended20194357,
  'general/highly_recommended/2019/4194': highlyRecommended20194194,
  'general/highly_recommended/2019/4261': highlyRecommended20194261,
  'general/highly_recommended/2019/4301': highlyRecommended20194301,
  'general/highly_recommended/2019/4161': highlyRecommended20194161,
  'general/highly_recommended/2019/4349': highlyRecommended20194349,
  'general/highly_recommended/2019/4380': highlyRecommended20194380,
  'general/highly_recommended/2019/4323': highlyRecommended20194323,
  'general/highly_recommended/2019/4318': highlyRecommended20194318,
  'general/highly_recommended/2019/4204': highlyRecommended20194204,
  'general/highly_recommended/2019/4367': highlyRecommended20194367,
  'general/highly_recommended/2019/4273': highlyRecommended20194273,
  'general/highly_recommended/2019/4242': highlyRecommended20194242,
  'general/highly_recommended/2020/4909': highlyRecommended20204909,
  'general/highly_recommended/2020/4725': highlyRecommended20204725,
  'general/highly_recommended/2020/4872': highlyRecommended20204872,
  'general/highly_recommended/2020/4722': highlyRecommended20204722,
  'general/highly_recommended/2020/4462': highlyRecommended20204462,
  'general/highly_recommended/2020/4463': highlyRecommended20204463,
  'general/highly_recommended/2020/4830': highlyRecommended20204830,
  'general/highly_recommended/2020/4925': highlyRecommended20204925,
  'general/highly_recommended/2020/4665': highlyRecommended20204665,
  'general/highly_recommended/2020/4541': highlyRecommended20204541,
  'general/highly_recommended/2020/4666': highlyRecommended20204666,
  'general/highly_recommended/2020/4723': highlyRecommended20204723,
  'general/highly_recommended/2020/4724': highlyRecommended20204724,
  'general/highly_recommended/2020/4829': highlyRecommended20204829,
  'general/highly_recommended/2020/4867': highlyRecommended20204867,
  'general/highly_recommended/2020/4914': highlyRecommended20204914,
  'general/highly_recommended/2015/1127': highlyRecommended20151127,

  // general/print-service
  'general/print-service/square': PrintServicesquare,
  // general/photo_mono
  'general/photo_mono/macro-lens': PhotomonoMacrolens,
  'general/photo_mono/g9pro': PhotomonoG9pro,
  'general/photo_mono/50mmlens': Photomono50mmlens,
  'general/photo_mono/a7r3-a9': PhotomonoA7r3a9,
  'general/photo_mono/sony-a9': PhotomonoSonya9,
  'general/photo_mono/sigma-lens': PhotomonoSigmaLens,
  // general/summerhomework
  'general/summerhomework/moon': SummerhomeworkMoon,
  'general/summerhomework/camera': SummerhomeworkCamera,
  'general/summerhomework/observation-diary': SummerhomeworkObservationDiary,
  'general/summerhomework/5988': Summerhomework5988,
  // general/stationery/mannenhitsu
  'general/stationery/mannenhitsu/nenga': MannenhitsuNenga,
  // general/how-to
  'general/how-to/skillup/01': HowtoSkillup01,
  'general/how-to/night-view': HowtoNightView,
  'general/how-to/hanabi': Howtohanabi,
  'general/how-to/group': HowtoGroup,
  'general/how-to/skillup/02': HowtoSkillup02,

  // general/graduation_entrance
  'general/graduation_entrance/faq': GraduationEntranceFaq,
  // general/tokutokusueoki
  'general/tokutokusueoki/fe-lens': TokutokusueokiFelens,
  'general/tokutokusueoki/rf-lens': TokutokusueokiRflens,
  'general/tokutokusueoki/ef-lens': TokutokusueokiEflens,
  'general/tokutokusueoki/z-lens': TokutokusueokiZlens,
  // general/watch
  'general/watch/omega': WatchOmega,
  // general/pet
  'general/pet/1472': Pet1472,

  // camera
  'camera/dslr': Componentdslr,
  'camera/storage': Storage,
  'camera/lensfilter': Lensfilter,
  'camera/girlscamera': Girlscamera,
  'camera/operaglass': Operaglass,
  'camera/frame': Frame,
  'camera/tripod': CanonTripod,
  'camera/photomount': Photomount,
  'camera/hosizora': Hosizora,
  'camera/travel': Travel,
  //'camera/okamoto-yutaka': OkamotoYutaka,
  'camera/wearable': Wearable,
  'camera/mirrorless-oldlens': Mirrorlessoldlens,
  'camera/strap': Strap,
  'camera/strap/380': Strap380,
  'camera/waterproof': Waterproof,
  'camera/waterproof/4129': Waterproof4129,
  'camera/waterproof/4131': Waterproof4131,
  'camera/coolshot': Coolshot,
  'camera/momijigari': Momijigari,
  'camera/bird': Bird,
  'camera/bird/camera': Birdcamera,

  // lensfilter
  'camera/lensfilter/1869': Lensfilter1869,
  'camera/lensfilter/2638': Lensfilter2638,
  'camera/lensfilter/1104': Lensfilter1104,
  'camera/lensfilter/nd500': LensfilterNd500,
  'camera/lensfilter/exus': LensfilterExus,
  'camera/lensfilter/nikon-arcrest': LensfilterNikonArcrest,
  'camera/lensfilter/341': Lensfilter341,
  'camera/lensfilter/343': Lensfilter343,
  'camera/lensfilter/344': Lensfilter344,
  'camera/lensfilter/nikon-arcrest-nd': LensfilterNikonArcrestnd,

  // polarie
  'camera/polarie': Polarie,
  'camera/polarie/acc': PolarieAcc,
  'camera/polarie/startrail': PolarieStartrail,

  // leica
  'camera/feature/leica': Leica,
  // astronomical
  'camera/astronomical': astronomical,
  'camera/astronomical/telescope': Telescope,
  'camera/astronomical/moonlook': Moonlook,
  'camera/astronomical/telescope-feature': TelescopeFeature,
  //Printer
  'camera/printer/759': Printer759,
  'camera/printer/3133': Printer3133,
  'camera/printer': Printer,
  'camera/photoprinter': PhotoPrinter,
  // travel
  'camera/travel/accessory': travelAccessory,
  'camera/travel/moving_image': travelMovingimage,
  'camera/travel/recommended_camera': travelRecommendcamera,
  'camera/travel/waterproof_360': travelWaterproof360,

  // feature
  'camera/feature': Componentfeature,
  'camera/feature/dslr': Componentfeaturedslr,
  'camera/feature/lens': Componentfeaturelens,
  'camera/feature/compact': Componentfeaturecompact,
  'camera/feature/video': Componentfeaturevideo,
  // canon
  'camera/feature/canon/eosr5r6': CanonEosr5r6,
  'camera/feature/canon/rf1435mmf4lisusm': CanonRf1435mmf4lisusm,
  'camera/feature/canon/rf100f28l': CanonRf100f28l,
  'camera/feature/canon/rf400f28l': CanonRf400f28l,
  'camera/feature/canon/eos90d': CanonEos90d,
  'camera/feature/canon/eoskissm2': CanonEoskissm2,
  'camera/feature/canon/rf50f18': CanonRf50f18,
  'camera/feature/canon/eosrp': CanonEosrp,
  'camera/feature/canon/eoskissx10i': CanonEoskissx10i,
  'camera/feature/canon/6dmk2': Canon6dmk2,
  'camera/feature/canon/7dmk2': Canon7dmk2,
  'camera/feature/canon/rf100500mmf4571lisusm': CanonrF100500mmf4571lisusm,
  'camera/feature/canon/eosr3': CanonrEosr3,
  'camera/feature/canon/1dxmk3': Canonr1dxmk3,
  'camera/feature/canon/rf600mm-rf800mm_f11_is_stm': CanonrRf600mmRf800mmF11IsStm,
  'camera/feature/canon/rf70-200-f4l': Canonrf70200,
  'camera/feature/canon/eos80d': CanonEos80d,
  'camera/feature/canon/5dmk4': Canon5dmk4,
  'camera/feature/canon/eosr': CanonEosr,
  'camera/feature/canon/5ds': Canon5ds,
  'camera/feature/canon/eoskiss10I/4496': CanonEoskiss10I4496,
  'camera/feature/canon/fv100': CanonFv100,
  'camera/feature/canon/1dxmk2': Canon1dxmk2,
  'camera/feature/canon/f28lis': CanonF28lis,
  'camera/feature/canon/rf600mm-rf800mm_f11_is_stm/4806': CanonRf600mmrf800mmf11isstm4806,
  'camera/feature/canon/rf100-400_rf16': CanonRf100mm400mmRf16,
  'camera/feature/canon/rf800mmf56-rf1200mmf8': CanonRf800mmf56,
  'camera/feature/canon/eosr7': CanonEosr7,
  'camera/feature/canon/rf24f18_rf15-30f45-63': CanonRf24f18,
  'camera/feature/canon/rf135': CanonRf135,
  'camera/feature/canon/eosr6mk2': CanonEosr6mk2,
  'camera/feature/canon/eosr8': CanonEosr8,
  'camera/feature/canon/rf100-300-f28l': CanonRf100mmf300,
  'camera/feature/canon/rf10-20mmf4l': CanonRf1020mmf4l,
  'camera/feature/canon/rf200800mm-rf24105mm': CanonRf200800mmRf24105mm,
  'camera/feature/canon/eosr5mk2': CanonEosr5mk2,
  'camera/feature/canon/eosr1': CanonEosr1,
  // nikon
  'camera/feature/nikon/zfc': NikonZfc,
  'camera/feature/nikon/z5': NikonZ5,
  'camera/feature/nikon/D780': NikonD780,
  'camera/feature/nikon/z50': NikonZ50,
  'camera/feature/nikon/z6IIz7II': NikonZ6IIZ7II,
  'camera/feature/nikon/D750': NikonD750,
  'camera/feature/nikon/D500': NikonD500,
  'camera/feature/nikon/d6': NikonD6,
  'camera/feature/nikon/p950': NikonP950,
  'camera/feature/nikon/d850': NikonD850,
  'camera/feature/nikon/d5': NikonD5,
  // 'camera/feature/nikon/z_40mm_f2': NikonZ40mm,
  'camera/feature/nikon/z7z6': NikonZ7z6,
  'camera/feature/nikon/d7500': NikonD7500,
  'camera/feature/nikon/z50mm_z14-24mm': Nikonz50mmz14mm,
  'camera/feature/nikon/nikkorz_70-200mm_f2.8_vr_s': NikonNikkorz70200mmf28vrs,
  'camera/feature/nikon/nikon1': NikonNikon1,
  'camera/feature/nikon/d810': NikonD810,
  'camera/feature/nikon/d4s': NikonD4s,
  'camera/feature/nikon/z_9': NikonZ9,
  'camera/feature/nikon/df': NikonDf,
  'camera/feature/nikon/z24-200mm_f4-6.3_vr': Nikonz24200mmf463vr,
  'camera/feature/nikon/nikkor_120_300mm_f2.8_e_fl_ed_sr_vr': NikonZnikkor120300mmf28efledsrvr,
  'camera/feature/nikon/z20mmf18s': NikonZ20mmf18s,
  'camera/feature/nikon/z100-400mmf45-56vrs': NikonZ100mm400mm,
  'camera/feature/nikon/z_mc_105mm_f28_vr_s': NikonZmc105mmf28,
  'camera/feature/nikon/z800mmf63vrs': Nikonz800mmf63vrs,
  'camera/feature/nikon/z30': NikonZ30,
  'camera/feature/nikon/z400mmf45': NikonZ400mmf45,
  'camera/feature/nikon/z600mmf4': NikonZ600mmf4,
  'camera/feature/nikon/z_9-list': NikonZ9list,
  'camera/feature/nikon/z_8': NikonZ8,
  'camera/feature/nikon/z_8-list': NikonZ8list,
  'camera/feature/nikon/z180-600mmf56-63vr': NikonZ180600mmf5663,
  'camera/feature/nikon/z_f': NikonZf,
  'camera/feature/nikon/z600f63vrs': NikonZ600f63vrs,
  'camera/feature/nikon/z6III': NikonZ6III,

  // olympus
  'camera/feature/olympus/penEP7': OlympusPenEP7,
  'camera/feature/olympus/em1m2': OlympusEm1m2,
  'camera/feature/olympus/em1m3': OlympusEm1m3,
  'camera/feature/olympus/em10': OlympusEm10,
  'camera/feature/olympus/lens40-150': OlympusLens40150,
  'camera/feature/olympus/em1m3/4484': OlympusEm1m34484,
  // panasonic
  'camera/feature/panasonic/gh5m2': PanasonicGh5m2,
  'camera/feature/panasonic/g100': Panasonicg100,
  'camera/feature/panasonic/g9': Panasonicg9,
  'camera/feature/panasonic/technics': Panasonictechnics,
  'camera/feature/panasonic/cm10': PanasonicCm10,
  'camera/feature/panasonic/gh6': PanasonicGh6,
  'camera/feature/panasonic/dcs5m2': PanasonicDcs5m2,
  'camera/feature/panasonic/dcg9m2': PanasonicDcg9m2,

  // sony
  'camera/feature/sony/7rm4a7rm3a': Sony7rm4a7rm3a,
  'camera/feature/sony/fe14f18gm': Sonyfe14f18gm,
  'camera/feature/sony/sel24f28g': SonySel24f28g,
  'camera/feature/sony/sel50f12gm': SonySel50f12gm,
  'camera/feature/sony/cinemalinefx3': SonyCinemalineFx3,
  'camera/feature/sony/a1': SonyA1,
  'camera/feature/sony/sel35f14gm': SonySel35f14gm,
  'camera/feature/sony/actioncam': SonyActioncam,
  'camera/feature/sony/vlogcamzv1zv1g': SonyVlogcamZv1Zv1g,
  'camera/feature/sony/7sm3': Sony7sm3,
  'camera/feature/sony/7c': Sony7c,
  'camera/feature/sony/7m3': Sony7m3,
  'camera/feature/sony/6600': Sony6600,
  'camera/feature/sony/7r4': Sony7r4,
  'camera/feature/sony/RX100M7': SonyRx100m7,
  'camera/feature/sony/a5100': SonyA5100,
  'camera/feature/sony/wf-c500': SonyWfc500,
  'camera/feature/sony/9II': Sony9II,
  'camera/feature/sony/sel70200gm2': SonySel70200gm2,
  'camera/feature/sony/rx100m3': SonyRx100m3,
  'camera/feature/sony/vlogcam_zv1_zv1g/4709': SonyVlogcamzv1zv1g4709,
  'camera/feature/sony/a9': SonyA9,
  'camera/feature/sony/a7r3': SonyA7r3,
  'camera/feature/sony/hx90vwx500': SonyHx90vwx500,
  'camera/feature/sony/a99ii': SonyA99ii,
  'camera/feature/sony/a7r2': SonyA7r2,
  'camera/feature/sony/a7r/689': SonyA7r689,
  'camera/feature/sony/wh-1000xm4lm': SonyWh1000xm4lm,
  'camera/feature/sony/a7iv': SonyA7iv,
  'camera/feature/sony/vlogcam_zv_e10': SonyZve10,
  'camera/feature/sony/wm1zm2_wm1am2': SonyWm1zm2Wm1am2,
  'camera/feature/sony/linkbuds_wf-l900': Sonywfl900,
  'camera/feature/sony/selp1635g': Sonyselp1635g,
  'camera/feature/sony/sel2470gm2': Sonysel2470gm2,
  'camera/feature/sony/wh-1000xm5': Sonywh1000xm5,
  'camera/feature/sony/wf-ls900n': Sonywfls900n,
  'camera/feature/sony/epz1020-e11-e15': Sonyepz1020e11e15,
  'camera/feature/sony/inzone_h9_h7_h3_m9': Sonyinzoneh9h7h3m9,
  'camera/feature/sony/fx30': Sonyfx30,
  'camera/feature/sony/a7rv': Sonya7rv,
  'camera/feature/sony/nw-zx707': Sonyzx707,
  'camera/feature/sony/fe20-70mmf4g': Sonyfe2070mm,
  'camera/feature/sony/fe50mmf14gm': Sonyfe50mmf14gm,
  'camera/feature/sony/vlogcam_zv_e1': SonyZve1,
  'camera/feature/sony/a6700': SonyA6700,
  'camera/feature/sony/fe70-200mmf4goss2': Sonyfe70200mm,
  'camera/feature/sony/fe16-35mmf28gm2': Sonyfe1635mm,
  'camera/feature/sony/zv-e10m2': SonyZve10m2,
  'camera/feature/sony/a9III': SonyA9III,
  'camera/feature/sony/fe300mmf28gmoss': SonyFe300mmf28gmoss,
  'camera/feature/sony/fe24mm50mmf28g': SonyFe24mm50mmf28g,
  'camera/feature/sony/fe16-25mmf28g': SonyFe1625mmf28g,
  // 'camera/feature/sony/a7c2-a7cr': Sonya7c2a7cr,

  // tamron
  'camera/feature/tamron/150500mmf567': Tamron150500mmf567,
  // fujifilm
  'camera/feature/fujifilm/xf18mmf14rlmwr': FujifilmXf18mmf14rlmwr,
  'camera/feature/fujifilm/xs10': FujifilmXs10,
  'camera/feature/fujifilm/gfx100s': FujifilmGfx100s,
  'camera/feature/fujifilm/xf70300mmf456rlmoiswr': FujifilmXf70300mmf456rlmoiswr,
  'camera/feature/fujifilm/xe4': FujifilmXe4,
  'camera/feature/fujifilm/xt4': FujifilmXt4,
  'camera/feature/fujifilm/xf33f14': Fujifilmxf33f14,
  'camera/feature/fujifilm/newgoods': Fujifilnewgoods,
  'camera/feature/fujifilm/x100v': FujifilmX100v,
  'camera/feature/fujifilm/x-t200': FujifilmXt200,
  'camera/feature/fujifilm/xpro3': FujifilmXpro3,
  'camera/feature/fujifilm/xf50mm-f1rwr': Fujifilmxf50mmf1rwr,
  'camera/feature/fujifilm/xt4/4531': FujifilmXt44531,
  'camera/feature/fujifilm/instax/mini11': FujifilmInstaxMini11,
  'camera/feature/fujifilm/gfx50sII': Fujifilgfx50sII,
  'camera/feature/fujifilm/xt3': FujifilmXt3,
  'camera/feature/fujifilm/xt2': FujifilmXt2,
  'camera/feature/fujifilm/xc35mm_f2': FujifilmXc35mmf2,
  'camera/feature/fujifilm/x100v/4475': FujifilmX100v4475,
  'camera/feature/fujifilm/xh2s': FujifilmXh2s,
  'camera/feature/fujifilm/xf150-600': FujifilmXf150600,
  'camera/feature/fujifilm/xh2': FujifilmXh2,
  'camera/feature/fujifilm/xf18-200_xf56': FujifilmXf18200xf56,
  'camera/feature/fujifilm/xt5': FujifilmXt5,
  'camera/feature/fujifilm/xt5-list': FujifilmXt5list,
  'camera/feature/fujifilm/xs20': FujifilmXs20,
  'camera/feature/fujifilm/x-100vi': FujifilmX100vi,

  // pentax
  'camera/feature/pentax/k3mk3': Pentaxk3mk3,
  'camera/feature/pentax/k-1': Pentaxk1,
  'camera/feature/pentax/645z': Pentax645z,
  // sigma
  'camera/feature/sigma/fpl': SigmaFpl,
  // ricoh
  'camera/feature/ricoh/gr3x': Ricohgr3x,
  'camera/feature/ricoh/thetasc': RicohThetasc,
  // meishokougaku
  'camera/feature/meishokougaku/11mm_f28': Meishokougaku11mmf28,
  // tokina
  'camera/feature/tokina/4332': Tokina4332,
  // omsystem
  'camera/feature/omsystem/om1': OmsystemOm1,
  'camera/feature/omsystem/om5': OmsystemOm5,
  'camera/feature/omsystem/ed90mmf35macro': OmsystemEd90mmf35macro,
  'camera/feature/omsystem/om1m2': OmsystemOm1m2,
  'camera/feature/omsystem/ed150-600mm': OmsystemEd150600mm,

  // その他
  'camera/filmcamera/utsurundesu': Componentutsurundesu,
  'camera/feature/backnumber': Backnumber,
  'sale-fair': Salefair,
  'camera/filmcamera': filmcamera,
  'camera/filmcamera/889': filmcamera889,
  'camera/filmcamera/890': filmcamera890,
  'camera/filmcamera/891': filmcamera891,
  'camera/filmcamera/892': filmcamera892,

  // kaden
  'kaden/airpurifier/aircleanercompare': Componentaircleanercompare,
  'kaden/smartphone/iphone': Iphone,
  'kaden/smartphone/iphone/xsxsmaxxr': XsXsMaxXr,
  'kaden/rice-cooker_bestbuy': KadenRiceCookerBestbuy,
  'kaden/smart_phone/iphone7': KadenSmartPhoneIphone7,
  'kaden/toaster-oven': KadenToasterOven,
  'kaden/ricemill/interview': KadenRicemillInterview,
  'kaden/air-purifier_fair/daikin': KadenAirPurifierDaikin,
  'kaden/heyabosi': KadenHeyabosi,
  'kaden/smart_phone/iphone7/iphone7-camera': KadenSmartphoneIphone7Iphone7camera,
  'kaden/dcfan': KadenDcfan,
  'kaden/bottle': KadenBottle,
  'kaden/bento': KadenBento,
  'kaden/fan-fair': KadenFanfair,
  'kaden/smart_phone/iphone12': KadenSmartPhoneIphone12,
  'kaden/panasonic-dishwasher': KadenPanasonicDishwasher,
  'kaden/air-purifier_fair': Kadenaircleanerfair,
  'kaden/bottle/kids': KadenBottleKids,
  'kaden/bottle/sports': KadenBottleSports,
  'kaden/bottle/office': KadenBottleOffice,
  'kaden/heater-best': Kadenheater,
  'kaden/ricemill': KadenRicemill,
  'kaden/fall-of-taste': KadenFall,
  'kaden/sixpad': KadenSixpad,
  'kaden/digitalization': KadenDigitalization,
  // kadencampaign
  'kaden/campaign/irisohyama240412': KadenCpirisohyama240412,

  // bag/feature
  'camera/bag/feature/trekking': Trekking,
  'camera/bag/trial': Trial,
  'camera/bag': Bag,
  // photomount
  'camera/photomount/244': Photomount244,
  'camera/photomount/253': Photomount253,
  'camera/photomount/252': Photomount252,
  'camera/photomount/254': Photomount254,
  'camera/photomount/262': Photomount262,
  'camera/photomount/266': Photomount266,
  'camera/photomount/269': Photomount269,
  'camera/photomount/316': Photomount316,
  'camera/photomount/318': Photomount318,
  'camera/photomount/914': Photomount914,
  'camera/photomount/1362': Photomount1362,
  'camera/photomount/2433': Photomount2433,
  'camera/photomount/feature': PhotomountFeature,
  'camera/photomount/feature/317': PhotomountFeature317,
  'camera/photomount/246': Photomount246,
  'camera/photomount/245': Photomount245,
  // okamoto-yutaka
  //'camera/okamotoyutaka/leofoto': OkamotoYutakaLeofoto,
  //'camera/okamoto-yutaka/set': OkamotoYutakaSet,

  // tripod
  'camera/tripod/type01': TripodType01,
  'camera/tripod/type02': TripodType02,
  'camera/tripod/type03': TripodType03,
  'camera/tripod/type04': TripodType04,
  'camera/tripod/type05': TripodType05,
  'camera/tripod/type06': TripodType06,
  'camera/tripod/parts01': TripodParts01,
  'camera/tripod/parts02': TripodParts02,
  'camera/tripod/parts03': TripodParts03,
  // dslr
  'camera/dslr/3228': Dslr3228,
  'camera/dslr/3229': Dslr3229,
  // frame
  'camera/frame/ordermade': Frameordermade,
  'camera/frame/shacolla': FrameShacolla,
  'camera/frame/4086': Frame4086,
  'camera/frame/2037': Frame2037,
  'camera/frame/833': Frame833,
  'camera/frame/443': Frame443,
  'camera/frame/2441': Frame2441,
  'camera/frame/470': Frame470,
  'camera/frame/2446': Frame2446,
  'camera/frame/2889': Frame2889,
  'camera/frame/2442': Frame2442,
  'camera/frame/459': Frame459,
  'camera/frame/2440': Frame2440,
  'camera/frame/456': Frame456,
  'camera/frame/471': Frame471,
  'camera/frame/cork-frame': Corkframe,
  'camera/frame/sapele': Sapele,

  // storage
  'camera/storage/363': Storage363,
  'camera/storage/366': Storage366,
  'camera/storage/368': Storage368,
  'camera/storage/371': Storage371,
  // sale
  'sale/feature01': Sale534,
  'sale/feature01/535': Sale535,
  'sale/feature01/536': Sale536,
  'sale/feature01/537': Sale537,
  'sale/usedsale': Saleused,
  'sale/usedsale/nikon': Saleusednikon,
  'sale/usedsale/nikon/lens': Saleusednikonlens,
  'sale/usedsale/nikon/dslr': Saleusednikondslr,
  'sale/usedsale/nikon/mirrorless': Saleusednikonmirrorless,
  'sale/usedsale/nikon/compact': Saleusednikoncompact,
  'sale/usedsale/nikon/filmcamera': Saleusednikonfilmcamera,
  'sale/usedsale/nikon/other': Saleusednikonother,
  'sale/usedsale/canon': Saleusedcanon,
  'sale/usedsale/canon/lens': Saleusedcanonlens,
  'sale/usedsale/canon/dslr': Saleusedcanondslr,
  'sale/usedsale/canon/mirrorless': Saleusedcanonmirrorless,
  'sale/usedsale/canon/compact': Saleusedcanoncompact,
  'sale/usedsale/canon/filmcamera': Saleusedcanonfilmcamera,
  'sale/usedsale/canon/other': Saleusedcanonother,
  'sale/usedsale/sony': Saleusedsony,
  'sale/usedsale/sony/lens': Saleusedsonylens,
  'sale/usedsale/sony/dslr': Saleusedsonydslr,
  'sale/usedsale/sony/mirrorless': Saleusedsonymirrorless,
  'sale/usedsale/sony/compact': Saleusedsonycompact,
  'sale/usedsale/sony/other': Saleusedsonyother,
  'sale/usedsale/fujifilm': Saleusedfujifilm,
  'sale/usedsale/fujifilm/lens': Saleusedfujifilmlens,
  'sale/usedsale/fujifilm/dslr': Saleusedfujifilmdslr,
  'sale/usedsale/fujifilm/mirrorless': Saleusedfujifilmmirrorless,
  'sale/usedsale/fujifilm/compact': Saleusedfujifilmcompact,
  'sale/usedsale/fujifilm/filmcamera': Saleusedfujifilmfilmcamera,
  'sale/usedsale/fujifilm/other': Saleusedfujifilmother,
  'sale/usedsale/olympus': Saleusedolympus,
  'sale/usedsale/olympus/lens': Saleusedolympuslens,
  'sale/usedsale/olympus/dslr': Saleusedolympusdslr,
  'sale/usedsale/olympus/mirrorless': Saleusedolympusmirrorless,
  'sale/usedsale/olympus/compact': Saleusedolympuscompact,
  'sale/usedsale/olympus/filmcamera': Saleusedolympusfilmcamera,
  'sale/usedsale/olympus/other': Saleusedolympusother,
  'sale/usedsale/panasonic': Saleusedpanasonic,
  'sale/usedsale/panasonic/lens': Saleusedpanasoniclens,
  'sale/usedsale/panasonic/mirrorless': Saleusedpanasonicmirrorless,
  'sale/usedsale/panasonic/compact': Saleusedpanasoniccompact,
  'sale/usedsale/panasonic/other': Saleusedpanasonicother,
  'sale/usedsale/pentax': Saleusedpentax,
  'sale/usedsale/pentax/lens': Saleusedpentaxlens,
  'sale/usedsale/pentax/dslr': Saleusedpentaxdslr,
  'sale/usedsale/pentax/mirrorless': Saleusedpentaxmirrorless,
  'sale/usedsale/pentax/compact': Saleusedpentaxcompact,
  'sale/usedsale/pentax/filmcamera': Saleusedpentaxfilmcamera,
  'sale/usedsale/pentax/other': Saleusedpentaxother,
  'sale/usedsale/sigma': Saleusedsigma,
  'sale/usedsale/sigma/lens': Saleusedsigmalens,
  'sale/usedsale/sigma/mirrorless': Saleusedsigmamirrorless,
  'sale/usedsale/sigma/other': Saleusedsigmaother,
  'sale/usedsale/tamron': Saleusedtamron,
  'sale/usedsale/tamron/lens': Saleusedtamronlens,
  'sale/usedsale/tamron/other': Saleusedtamronother,
  'sale/usedsale/zeiss': Saleusedzeiss,
  'sale/usedsale/zeiss/lens': Saleusedzeisslens,
  'sale/usedsale/leica': Saleusedleica,
  'sale/usedsale/leica/lens': Saleusedleicalens,
  'sale/usedsale/leica/dslr': Saleusedleicadslr,
  'sale/usedsale/leica/mirrorless': Saleusedleicamirrorless,
  'sale/usedsale/leica/compact': Saleusedleicacompact,
  'sale/usedsale/leica/filmcamera': Saleusedleicafilmcamera,
  'sale/usedsale/leica/other': Saleusedleicaother,
  'sale/usedsale/other': Saleusedother,
  'sale/usedsale/other/lens': Saleusedotherlens,
  'sale/usedsale/other/dslr': Saleusedotherdslr,
  'sale/usedsale/other/mirrorless': Saleusedothermirrorless,
  'sale/usedsale/other/compact': Saleusedothercompact,
  'sale/usedsale/other/filmcamera': Saleusedotherfilmcamera,
  'sale/usedsale/other/other': Saleusedotherother,
  'sale/feature02': Sale716,
  'sale/feature02/718': Sale718,
  'sale/feature02/717': Sale717,
  'sale/feature01/789': Sale789,
  'sale/feature01/4897': Sale4897,
  'sale/usedsaleweek': Saleusedweek,
  'sale/usedsale/nikon/lens2': Saleusednikonlens2,
  'sale/usedsale/canon/lens2': Saleusedcanonlens2,
  'sale/feature01/4898': Sale4898,
  'sale/feature01/5827': Sale5827,
  'sale/hatsuuri': Salehatsuuri,
  'sale/hatsuuri/01': Sale1905,
  'sale/hatsuuri/02': Sale1906,
  'sale/hatsuuri/03': Sale1907,
  'sale/hatsuuri/04': Sale1908,
  'sale/hatsuuri/05': Sale2175,
  'sale/hatsuuri/06': Sale4398,
  'sale/hatsuuri/07': Sale1738,
  'sale/hatsuuri/u01': Sale5731,
  'sale/hatsuuri/u02': Sale5732,
  'sale/hatsuuri/u03': Sale5733,
  'sale/hatsuuri/5507': Sale5507,
  'sale/hatsuuri/m02': Sale5734,
  'sale/hatsuuri/m03': Sale5735,
  'sale/hatsuuri/m04': Sale5736,
  'sale/usedflashsale': Saleusedflash,
  'sale/usedflashsale/5864': Saleusedflash5864,
  'sale/usedflashsale/5865': Saleusedflash5865,
  'sale/usedflashsale/5866': Saleusedflash5866,
  'sale/usedflashsale/5867': Saleusedflash5867,
  'sale/usedsale/minolta/lens': Saleusedminoltalens,
  'sale/usedsale/minolta/filmcamera': Saleusedminoltafilmcamera,
  'sale/usedsale/contax/lens': Saleusedcontaxlens,
  'sale/usedsale/contax/filmcamera': Saleusedcontaxfilmcamera,
  'sale/usedsale/mamiya/lens': Saleusedmamiyalens,
  'sale/usedsale/mamiya/filmcamera': Saleusedmamiyafilmcamera,
  'sale/usedcameracoupon': Usedcameracoupon,
  'sale/feature03': Sale569,
  'sale/feature03/2150': Sale2150,
  'sale/usedcameracouponitem': UsedcameracouponItem,
  // reuse
  'reuse/watch': Reusewatch,
  'reuse/smartphone': Reusesmartphone,
  'reuse/watch-sample': Reusewatchsample,
  'reuse/rpc-usedpc/': ReuseRpc,
  //'reuse/smartphone/refurbish': Reuserefurbish,
  'reuse/usedpc/': ReuseUsedpc,
  'reuse/usedpc/photo-editing-pc/': ReuseUsededitingpc,
  'reuse/usedpc/ranking/': ReuseUsedpcranking,
  // reusesale
  'reuse/reusesale': Reusesale,
  // reusesell
  //'watch/kitamuracamera/': Reusewatchsell,
  //'smartphone/kitamuracamera/': Reusesmartphonesell,
  //'smartphone/splogicenter/': Reusesplogicenter,
  'watch/rolex/': Reusewatchrolex,
  'watch/omega/': Reusewatchomega,
  'watch/japanmadewatch/': Reusejapanmadewatch,
  'watch/kitamuracamera-nakano/': Reusewatchnakano,
  'watch/kitamuracamera-nakano/clearancesale/': Reusewatchclearancesale
};

/**
 * URLとコンポーネント名のマッピング設定用です。
 * 以下に基づいて、適用するページURLを追加してください。
 *
 * 'ページURL': 上記で任意に設定した'コンポーネント名'
 */
const URL_MAPPING_LIST = {
  // ---- ↓ サンプル ------
  sample1: 'sample1',
  sample2: 'sample2',
  sample3: 'sample3',
  sample4: 'sample4',
  'sample-amount-simulator': 'sample-amount-simulator',
  '2021/0401': 'component20210401',
  '2021/0402-test': 'component-20210402',
  vuetify: 'vuetify',
  // ---- ↑ サンプル ------

  //original
  'camera/original/kitamuracamera': 'camera/original/kitamuracamera',

  // compact
  'camera/compact': 'camera/compact',
  'camera/compact/item01': 'camera/compact/item01',
  'camera/compact/item02': 'camera/compact/item02',
  // lens
  'camera/lens': 'camera/lens',
  'camera/lens/itemcanon': 'camera/lens/itemcanon',
  'camera/lens/itemnikon': 'camera/lens/itemnikon',
  'camera/lens/itempentax': 'camera/lens/itempentax',
  'camera/lens/itemsony': 'camera/lens/itemsony',
  'camera/lens/itemmicroft': 'camera/lens/itemmicroft',
  'camera/lens/itemfuji': 'camera/lens/itemfuji',
  'camera/lens/itembouen': 'camera/lens/itembouen',
  'camera/lens/itemtansyouten': 'camera/lens/itemtansyouten',
  'camera/lens/itemmacro': 'camera/lens/itemmacro',
  'camera/lens/itemkoukaku': 'camera/lens/itemkoukaku',
  'camera/lens/batis': 'camera/lens/batis',
  'camera/lens/fujinon': 'camera/lens/fujinon',
  'camera/lens/sony/5074': 'camera/lens/sony/5074',
  'camera/lens/mzuiko': 'camera/lens/mzuiko',
  'camera/lens/lumixg': 'camera/lens/lumixg',
  'camera/lens/809': 'camera/lens/809',
  'camera/lens/fujifilm/xf100-400': 'camera/lens/fujifilm/xf100-400',
  'camera/lens/touit': 'camera/lens/touit',
  'camera/lens/tamron18270/1402': 'camera/lens/tamron18270/1402',
  'camera/lens/sonye': 'camera/lens/sonye',
  // page
  'page/deliver': 'page/deliver',
  'page/tablet/calculate/guarantee': 'page/tablet/calculate/guarantee',
  'page/used-navi': 'page/used-navi',
  'page/wrapping-gift': 'page/wrapping-gift',
  'page/telephoneorder-info': 'page/telephoneorder-info',
  'page/code-payment': 'page/code-payment',
  // paypayリダイレクトがかかったら削除
  'page/paypay': 'page/paypay',
  // page code-payment
  'page/code-payment/paypay': 'page/code-payment/paypay',
  'page/code-payment/rakutenpay': 'page/code-payment/rakutenpay',
  'page/code-payment/aupay': 'page/code-payment/aupay',
  'page/code-payment/merpay': 'page/code-payment/merpay',
  'page/code-payment/dbarai': 'page/code-payment/dbarai',

  // album
  'camera/album/feature': 'camera/album/feature',
  'camera/album/feature/1985': 'camera/album/feature/1985',
  'camera/album/feature/1986': 'camera/album/feature/1986',
  'camera/album/feature/1996': 'camera/album/feature/1996',
  'camera/album/feature/1997': 'camera/album/feature/1997',
  'camera/album/original/1119': 'camera/album/original/1119',
  'camera/album': 'camera/album',
  'camera/album/1439': 'camera/album/1439',
  'camera/album/1440': 'camera/album/1440',
  // 'camera/album/original/2052': 'camera/album/original/2052',
  'camera/album/3765': 'camera/album/3765',
  'camera/album/3766': 'camera/album/3766',
  'camera/album/storage/wonderphotobox': 'camera/album/storage/wonderphotobox',
  // general
  'general/wirelessimageshare': 'general/wirelessimageshare',
  //'general/prepare': 'general/prepare',
  'general/tokutokukoukan': 'general/tokutokukoukan',
  'general/campaign': 'general/campaign',
  'general/tuyutaisaku': 'general/tuyutaisaku',
  'general/graduation_entrance': 'general/graduationentrance',
  'general/keirou': 'general/keirou',
  'general/pet': 'general/pet',
  'general/tokutokusueoki': 'general/tokutokusueoki',
  'general/story_after_taking': 'general/story_after_taking',
  'general/highly_recommended': 'general/highly_recommended',
  'general/summerhomework': 'general/summerhomework',
  //'general/photocon-learn': 'general/photocon-learn',
  'general/graduation_entrance/942': 'general/graduationentrance/942',
  'general/graduation_entrance/1442': 'general/graduationentrance/1442',
  'general/graduation_entrance/2017': 'general/graduationentrance/2017',
  'general/graduation_entrance/2035': 'general/graduationentrance/2035',
  'general/graduation_entrance/2044': 'general/graduationentrance/2044',
  'general/graduation_entrance/3278': 'general/graduationentrance/3278',
  'general/graduation_entrance/2698': 'general/graduationentrance/2698',
  'general/daikansyasai': 'general/daikansyasai',
  'general/graduation_entrance/5887': 'general/graduationentrance/5887',

  // general/highly_recommended
  'general/highly_recommended/2015/1120': 'general/highly_recommended/2015/1120',
  'general/highly_recommended/2015/0605': 'general/highly_recommended/2015/0605',
  'general/highly_recommended/2015/0410': 'general/highly_recommended/2015/0410',
  'general/highly_recommended/2015/1224': 'general/highly_recommended/2015/1224',
  'general/highly_recommended/2015/0501': 'general/highly_recommended/2015/0501',
  'general/highly_recommended/2015/0925': 'general/highly_recommended/2015/0925',
  'general/highly_recommended/2015/0828': 'general/highly_recommended/2015/0828',
  'general/highly_recommended/2015/0731': 'general/highly_recommended/2015/0731',
  'general/highly_recommended/2015/0522': 'general/highly_recommended/2015/0522',
  'general/highly_recommended/2015/0612': 'general/highly_recommended/2015/0612',
  'general/highly_recommended/2015/0327': 'general/highly_recommended/2015/0327',
  'general/highly_recommended/2015/0529': 'general/highly_recommended/2015/0529',
  'general/highly_recommended/2015/0807': 'general/highly_recommended/2015/0807',
  'general/highly_recommended/2015/0626': 'general/highly_recommended/2015/0626',
  'general/highly_recommended/2015/1015': 'general/highly_recommended/2015/1015',
  'general/highly_recommended/2015/0904': 'general/highly_recommended/2015/0904',
  'general/highly_recommended/2015/0403': 'general/highly_recommended/2015/0403',
  'general/highly_recommended/2019/4121': 'general/highly_recommended/2019/4121',
  'general/highly_recommended/2019/4357': 'general/highly_recommended/2019/4357',
  'general/highly_recommended/2019/4194': 'general/highly_recommended/2019/4194',
  'general/highly_recommended/2019/4261': 'general/highly_recommended/2019/4261',
  'general/highly_recommended/2019/4301': 'general/highly_recommended/2019/4301',
  'general/highly_recommended/2019/4161': 'general/highly_recommended/2019/4161',
  'general/highly_recommended/2019/4349': 'general/highly_recommended/2019/4349',
  'general/highly_recommended/2019/4380': 'general/highly_recommended/2019/4380',
  'general/highly_recommended/2019/4323': 'general/highly_recommended/2019/4323',
  'general/highly_recommended/2019/4318': 'general/highly_recommended/2019/4318',
  'general/highly_recommended/2019/4204': 'general/highly_recommended/2019/4204',
  'general/highly_recommended/2019/4367': 'general/highly_recommended/2019/4367',
  'general/highly_recommended/2019/4273': 'general/highly_recommended/2019/4273',
  'general/highly_recommended/2019/4242': 'general/highly_recommended/2019/4242',
  'general/highly_recommended/2020/4909': 'general/highly_recommended/2020/4909',
  'general/highly_recommended/2020/4725': 'general/highly_recommended/2020/4725',
  'general/highly_recommended/2020/4872': 'general/highly_recommended/2020/4872',
  'general/highly_recommended/2020/4462': 'general/highly_recommended/2020/4462',
  'general/highly_recommended/2020/4722': 'general/highly_recommended/2020/4722',
  'general/highly_recommended/2020/4925': 'general/highly_recommended/2020/4925',
  'general/highly_recommended/2020/4463': 'general/highly_recommended/2020/4463',
  'general/highly_recommended/2020/4830': 'general/highly_recommended/2020/4830',
  'general/highly_recommended/2020/4665': 'general/highly_recommended/2020/4665',
  'general/highly_recommended/2020/4541': 'general/highly_recommended/2020/4541',
  'general/highly_recommended/2020/4666': 'general/highly_recommended/2020/4666',
  'general/highly_recommended/2020/4723': 'general/highly_recommended/2020/4723',
  'general/highly_recommended/2020/4724': 'general/highly_recommended/2020/4724',
  'general/highly_recommended/2020/4829': 'general/highly_recommended/2020/4829',
  'general/highly_recommended/2020/4867': 'general/highly_recommended/2020/4867',
  'general/highly_recommended/2020/4914': 'general/highly_recommended/2020/4914',
  'general/highly_recommended/2015/1127': 'general/highly_recommended/2015/1127',

  // general/print-service
  'general/print-service/square': 'general/print-service/square',
  // general/photo_mono
  'general/photo_mono/macro-lens': 'general/photo_mono/macro-lens',
  'general/photo_mono/g9pro': 'general/photo_mono/g9pro',
  'general/photo_mono/50mmlens': 'general/photo_mono/50mmlens',
  'general/photo_mono/a7r3-a9': 'general/photo_mono/a7r3-a9',
  'general/photo_mono/sony-a9': 'general/photo_mono/sony-a9',
  'general/photo_mono/sigma-lens': 'general/photo_mono/sigma-lens',

  // general/sportsday
  'general/sportsday': 'general/sportsday',
  'general/sportsday/485': 'general/sportsday/485',
  'general/sportsday/531': 'general/sportsday/531',
  'general/sportsday/1196': 'general/sportsday/1196',
  'general/sportsday/photo-knowhow-1': 'general/sportsday/photo-knowhow-1',
  'general/sportsday/532': 'general/sportsday/532',
  'general/sportsday/2786': 'general/sportsday/2786',
  'general/sportsday/486': 'general/sportsday/486',
  // general/stationery/mannenhitsu
  'general/stationery/mannenhitsu/nenga': 'general/stationery/mannenhitsu/nenga',
  // general/how-to
  'general/how-to/skillup/01': 'general/how-to/skillup/01',
  'general/how-to/night-view': 'general/how-to/night-view',
  'general/how-to/hanabi': 'general/how-to/hanabi',
  'general/how-to/group': 'general/how-to/group',
  'general/how-to/skillup/02': 'general/how-to/skillup/02',

  // general/graduation_entrance
  'general/graduation_entrance/faq': 'general/graduation_entrance/faq',
  // general/tokutokusueoki
  'general/tokutokusueoki/fe-lens': 'general/tokutokusueoki/fe-lens',
  'general/tokutokusueoki/rf-lens': 'general/tokutokusueoki/rf-lens',
  'general/tokutokusueoki/ef-lens': 'general/tokutokusueoki/ef-lens',
  'general/tokutokusueoki/z-lens': 'general/tokutokusueoki/z-lens',
  // general/watch
  'general/watch/omega': 'general/watch/omega',
  // general/pet
  'general/pet/1472': 'general/pet/1472',
  // general/summerhomework
  'general/summerhomework/moon': 'general/summerhomework/moon',
  'general/summerhomework/camera': 'general/summerhomework/camera',
  'general/summerhomework/observation-diary': 'general/summerhomework/observation-diary',
  'general/summerhomework/5988': 'general/summerhomework/5988',

  // camera
  'camera/dslr': 'camera/dslr',
  'camera/storage': 'camera/storage',
  'camera/lensfilter': 'camera/lensfilter',
  'camera/girlscamera': 'camera/girlscamera',
  'camera/operaglass': 'camera/operaglass',
  'camera/frame': 'camera/frame',
  'camera/tripod': 'camera/tripod',
  'camera/photomount': 'camera/photomount',
  'camera/hosizora': 'camera/hosizora',
  'camera/travel': 'camera/travel',
  //'camera/okamoto-yutaka': 'camera/okamoto-yutaka',
  'camera/wearable': 'camera/wearable',
  'camera/mirrorless-oldlens': 'camera/mirrorless-oldlens',
  'camera/strap': 'camera/strap',
  'camera/strap/380': 'camera/strap/380',
  'camera/waterproof': 'camera/waterproof',
  'camera/waterproof/4129': 'camera/waterproof/4129',
  'camera/waterproof/4131': 'camera/waterproof/4131',
  'camera/coolshot': 'camera/coolshot',
  'camera/momijigari': 'camera/momijigari',
  'camera/bird': 'camera/bird',
  'camera/bird/camera': 'camera/bird/camera',

  // lensfilter
  'camera/lensfilter/1869': 'camera/lensfilter/1869',
  'camera/lensfilter/2638': 'camera/lensfilter/2638',
  'camera/lensfilter/1104': 'camera/lensfilter/1104',
  'camera/lensfilter/nd500': 'camera/lensfilter/nd500',
  'camera/lensfilter/exus': 'camera/lensfilter/exus',
  'camera/lensfilter/nikon-arcrest': 'camera/lensfilter/nikon-arcrest',
  'camera/lensfilter/341': 'camera/lensfilter/341',
  'camera/lensfilter/343': 'camera/lensfilter/343',
  'camera/lensfilter/344': 'camera/lensfilter/344',
  'camera/lensfilter/nikon-arcrest-nd': 'camera/lensfilter/nikon-arcrest-nd',

  // polarie
  'camera/polarie': 'camera/polarie',
  'camera/polarie/acc': 'camera/polarie/acc',
  'camera/polarie/startrail': 'camera/polarie/startrail',
  // leica
  'camera/feature/leica': 'camera/feature/leica',
  // astronomical
  'camera/astronomical': 'camera/astronomical',
  'camera/astronomical/telescope': 'camera/astronomical/telescope',
  'camera/astronomical/moonlook': 'camera/astronomical/moonlook',
  'camera/astronomical/telescope-feature': 'camera/astronomical/telescope-feature',
  // printer
  'camera/printer/759': 'camera/printer/759',
  'camera/printer/3133': 'camera/printer/3133',
  'camera/printer': 'camera/printer',
  'camera/photoprinter': 'camera/photoprinter',

  // travel
  'camera/travel/accessory': 'camera/travel/accessory',
  'camera/travel/moving_image': 'camera/travel/moving_image',
  'camera/travel/recommended_camera': 'camera/travel/recommended_camera',
  'camera/travel/waterproof_360': 'camera/travel/waterproof_360',

  // feature
  'camera/feature': 'camera/feature',
  'camera/feature/dslr': 'camera/feature/dslr',
  'camera/feature/lens': 'camera/feature/lens',
  'camera/feature/compact': 'camera/feature/compact',
  'camera/feature/video': 'camera/feature/video',
  // canon
  'camera/feature/canon/eosr5r6': 'camera/feature/canon/eosr5r6',
  'camera/feature/canon/rf1435mmf4lisusm': 'camera/feature/canon/rf1435mmf4lisusm',
  'camera/feature/canon/rf100f28l': 'camera/feature/canon/rf100f28l',
  'camera/feature/canon/rf400f28l': 'camera/feature/canon/rf400f28l',
  'camera/feature/canon/eos90d': 'camera/feature/canon/eos90d',
  'camera/feature/canon/eoskissm2': 'camera/feature/canon/eoskissm2',
  'camera/feature/canon/rf50f18': 'camera/feature/canon/rf50f18',
  'camera/feature/canon/eosrp': 'camera/feature/canon/eosrp',
  'camera/feature/canon/eoskissx10i': 'camera/feature/canon/eoskissx10i',
  'camera/feature/canon/6dmk2': 'camera/feature/canon/6dmk2',
  'camera/feature/canon/7dmk2': 'camera/feature/canon/7dmk2',
  'camera/feature/canon/rf100-500mm_f45_71l_is_usm': 'camera/feature/canon/rf100500mmf4571lisusm',
  'camera/feature/canon/eosr3': 'camera/feature/canon/eosr3',
  'camera/feature/canon/1dxmk3': 'camera/feature/canon/1dxmk3',
  'camera/feature/canon/rf600mm-rf800mm_f11_is_stm': 'camera/feature/canon/rf600mm-rf800mm_f11_is_stm',
  'camera/feature/canon/rf70-200-f4l': 'camera/feature/canon/rf70-200-f4l',
  'camera/feature/canon/eos80d': 'camera/feature/canon/eos80d',
  'camera/feature/canon/5dmk4': 'camera/feature/canon/5dmk4',
  'camera/feature/canon/eosr': 'camera/feature/canon/eosr',
  'camera/feature/canon/5ds': 'camera/feature/canon/5ds',
  'camera/feature/canon/eoskiss10I/4496': 'camera/feature/canon/eoskiss10I/4496',
  'camera/feature/canon/fv100': 'camera/feature/canon/fv100',
  'camera/feature/canon/1dxmk2': 'camera/feature/canon/1dxmk2',
  'camera/feature/canon/f28lis': 'camera/feature/canon/f28lis',
  'camera/feature/canon/rf600mm-rf800mm_f11_is_stm/4806': 'camera/feature/canon/rf600mm-rf800mm_f11_is_stm/4806',
  'camera/feature/canon/rf100-400_rf16': 'camera/feature/canon/rf100-400_rf16',
  'camera/feature/canon/rf800mmf56-rf1200mmf8': 'camera/feature/canon/rf800mmf56-rf1200mmf8',
  'camera/feature/canon/eosr7': 'camera/feature/canon/eosr7',
  'camera/feature/canon/rf24f18_rf15-30f45-63': 'camera/feature/canon/rf24f18_rf15-30f45-63',
  'camera/feature/canon/rf135': 'camera/feature/canon/rf135',
  'camera/feature/canon/eosr6mk2': 'camera/feature/canon/eosr6mk2',
  'camera/feature/canon/eosr8': 'camera/feature/canon/eosr8',
  'camera/feature/canon/rf100-300-f28l': 'camera/feature/canon/rf100-300-f28l',
  'camera/feature/canon/rf10-20mmf4l': 'camera/feature/canon/rf10-20mmf4l',
  'camera/feature/canon/rf200800mm-rf24105mm': 'camera/feature/canon/rf200800mm-rf24105mm',
  'camera/feature/canon/eosr5mk2': 'camera/feature/canon/eosr5mk2',
  'camera/feature/canon/eosr1': 'camera/feature/canon/eosr1',

  // nikon
  'camera/feature/nikon/zfc': 'camera/feature/nikon/zfc',
  'camera/feature/nikon/z5': 'camera/feature/nikon/z5',
  'camera/feature/nikon/D780': 'camera/feature/nikon/D780',
  'camera/feature/nikon/z50': 'camera/feature/nikon/z50',
  'camera/feature/nikon/z6IIz7II': 'camera/feature/nikon/z6IIz7II',
  'camera/feature/nikon/D750': 'camera/feature/nikon/D750',
  'camera/feature/nikon/D500': 'camera/feature/nikon/D500',
  'camera/feature/nikon/d6': 'camera/feature/nikon/d6',
  //'camera/feature/nikon/z_40mm_f2': 'camera/feature/nikon/z_40mm_f2',
  'camera/feature/nikon/z7z6': 'camera/feature/nikon/z7z6',
  'camera/feature/nikon/d7500': 'camera/feature/nikon/d7500',
  'camera/feature/nikon/z50mm_z14-24mm': 'camera/feature/nikon/z50mm_z14-24mm',
  'camera/feature/nikon/p950': 'camera/feature/nikon/p950',
  'camera/feature/nikon/d850': 'camera/feature/nikon/d850',
  'camera/feature/nikon/d5': 'camera/feature/nikon/d5',
  'camera/feature/nikon/nikkorz_70-200mm_f2.8_vr_s': 'camera/feature/nikon/nikkorz_70-200mm_f2.8_vr_s',
  'camera/feature/nikon/nikon1': 'camera/feature/nikon/nikon1',
  'camera/feature/nikon/d810': 'camera/feature/nikon/d810',
  'camera/feature/nikon/d4s': 'camera/feature/nikon/d4s',
  'camera/feature/nikon/z_9': 'camera/feature/nikon/z_9',
  'camera/feature/nikon/df': 'camera/feature/nikon/df',
  'camera/feature/nikon/z24-200mm_f4-6.3_vr': 'camera/feature/nikon/z24-200mm_f4-6.3_vr',
  'camera/feature/nikon/nikkor_120_300mm_f2.8_e_fl_ed_sr_vr': 'camera/feature/nikon/nikkor_120_300mm_f2.8_e_fl_ed_sr_vr',
  'camera/feature/nikon/z20mmf18s': 'camera/feature/nikon/z20mmf18s',
  'camera/feature/nikon/z100-400mmf45-56vrs': 'camera/feature/nikon/z100-400mmf45-56vrs',
  'camera/feature/nikon/z_mc_105mm_f28_vr_s': 'camera/feature/nikon/z_mc_105mm_f28_vr_s',
  'camera/feature/nikon/z800mmf63vrs': 'camera/feature/nikon/z800mmf63vrs',
  'camera/feature/nikon/z30': 'camera/feature/nikon/z30',
  'camera/feature/nikon/z400mmf45': 'camera/feature/nikon/z400mmf45',
  'camera/feature/nikon/z600mmf4': 'camera/feature/nikon/z600mmf4',
  'camera/feature/nikon/z_9-list': 'camera/feature/nikon/z_9-list',
  'camera/feature/nikon/z_8': 'camera/feature/nikon/z_8',
  'camera/feature/nikon/z_8-list': 'camera/feature/nikon/z_8-list',
  'camera/feature/nikon/z180-600mmf56-63vr': 'camera/feature/nikon/z180-600mmf56-63vr',
  'camera/feature/nikon/z_f': 'camera/feature/nikon/z_f',
  'camera/feature/nikon/z600f63vrs': 'camera/feature/nikon/z600f63vrs',
  'camera/feature/nikon/z6III': 'camera/feature/nikon/z6III',

  // olympus
  'camera/feature/olympus/penEP7': 'camera/feature/olympus/penEP7',
  'camera/feature/olympus/em1m2': 'camera/feature/olympus/em1m2',
  'camera/feature/olympus/em1m3': 'camera/feature/olympus/em1m3',
  'camera/feature/olympus/em10': 'camera/feature/olympus/em10',
  'camera/feature/olympus/lens40-150': 'camera/feature/olympus/lens40-150',
  'camera/feature/olympus/em1m3/4484': 'camera/feature/olympus/em1m3/4484',

  // panasonic
  'camera/feature/panasonic/gh5m2': 'camera/feature/panasonic/gh5m2',
  'camera/feature/panasonic/g100': 'camera/feature/panasonic/g100',
  'camera/feature/panasonic/g9': 'camera/feature/panasonic/g9',
  'camera/feature/panasonic/technics': 'camera/feature/panasonic/technics',
  'camera/feature/panasonic/cm10': 'camera/feature/panasonic/cm10',
  'camera/feature/panasonic/gh6': 'camera/feature/panasonic/gh6',
  'camera/feature/panasonic/dcs5m2': 'camera/feature/panasonic/dcs5m2',
  'camera/feature/panasonic/dcg9m2': 'camera/feature/panasonic/dcg9m2',

  // sony
  'camera/feature/sony/7rm4a7rm3a': 'camera/feature/sony/7rm4a7rm3a',
  'camera/feature/sony/fe14f18gm': 'camera/feature/sony/fe14f18gm',
  'camera/feature/sony/sel24f28g': 'camera/feature/sony/sel24f28g',
  'camera/feature/sony/sel50f12gm': 'camera/feature/sony/sel50f12gm',
  'camera/feature/sony/cinemalinefx3': 'camera/feature/sony/cinemalinefx3',
  'camera/feature/sony/a1': 'camera/feature/sony/a1',
  'camera/feature/sony/sel35f14gm': 'camera/feature/sony/sel35f14gm',
  'camera/feature/sony/actioncam': 'camera/feature/sony/actioncam',
  'camera/feature/sony/vlogcamzv1zv1g': 'camera/feature/sony/vlogcamzv1zv1g',
  'camera/feature/sony/7sm3': 'camera/feature/sony/7sm3',
  'camera/feature/sony/7c': 'camera/feature/sony/7c',
  'camera/feature/sony/7m3': 'camera/feature/sony/7m3',
  'camera/feature/sony/6600': 'camera/feature/sony/6600',
  'camera/feature/sony/7r4': 'camera/feature/sony/7r4',
  'camera/feature/sony/RX100M7': 'camera/feature/sony/RX100M7',
  'camera/feature/sony/a5100': 'camera/feature/sony/a5100',
  'camera/feature/sony/wf-c500': 'camera/feature/sony/wf-c500',
  'camera/feature/sony/9II': 'camera/feature/sony/9II',
  'camera/feature/sony/sel70200gm2': 'camera/feature/sony/sel70200gm2',
  'camera/feature/sony/rx100m3': 'camera/feature/sony/rx100m3',
  'camera/feature/sony/vlogcam_zv1_zv1g/4709': 'camera/feature/sony/vlogcam_zv1_zv1g/4709',
  'camera/feature/sony/a9': 'camera/feature/sony/a9',
  'camera/feature/sony/a7r3': 'camera/feature/sony/a7r3',
  'camera/feature/sony/hx90vwx500': 'camera/feature/sony/hx90vwx500',
  'camera/feature/sony/a99ii': 'camera/feature/sony/a99ii',
  'camera/feature/sony/a7r2': 'camera/feature/sony/a7r2',
  'camera/feature/sony/a7r/689': 'camera/feature/sony/a7r/689',
  'camera/feature/sony/wh-1000xm4lm': 'camera/feature/sony/wh-1000xm4lm',
  'camera/feature/sony/a7iv': 'camera/feature/sony/a7iv',
  'camera/feature/sony/vlogcam_zv_e10': 'camera/feature/sony/vlogcam_zv_e10',
  'camera/feature/sony/wm1zm2_wm1am2': 'camera/feature/sony/wm1zm2_wm1am2',
  'camera/feature/sony/linkbuds_wf-l900': 'camera/feature/sony/linkbuds_wf-l900',
  'camera/feature/sony/selp1635g': 'camera/feature/sony/selp1635g',
  'camera/feature/sony/sel2470gm2': 'camera/feature/sony/sel2470gm2',
  'camera/feature/sony/wh-1000xm5': 'camera/feature/sony/wh-1000xm5',
  'camera/feature/sony/wf-ls900n': 'camera/feature/sony/wf-ls900n',
  'camera/feature/sony/epz1020-e11-e15': 'camera/feature/sony/epz1020-e11-e15',
  'camera/feature/sony/inzone_h9_h7_h3_m9': 'camera/feature/sony/inzone_h9_h7_h3_m9',
  'camera/feature/sony/fx30': 'camera/feature/sony/fx30',
  'camera/feature/sony/a7rv': 'camera/feature/sony/a7rv',
  'camera/feature/sony/nw-zx707': 'camera/feature/sony/nw-zx707',
  'camera/feature/sony/fe20-70mmf4g': 'camera/feature/sony/fe20-70mmf4g',
  'camera/feature/sony/fe50mmf14gm': 'camera/feature/sony/fe50mmf14gm',
  'camera/feature/sony/vlogcam_zv_e1': 'camera/feature/sony/vlogcam_zv_e1',
  'camera/feature/sony/a6700': 'camera/feature/sony/a6700',
  'camera/feature/sony/fe70-200mmf4goss2': 'camera/feature/sony/fe70-200mmf4goss2',
  'camera/feature/sony/fe16-35mmf28gm2': 'camera/feature/sony/fe16-35mmf28gm2',
  // 'camera/feature/sony/a7c2-a7cr': 'camera/feature/sony/a7c2-a7cr',
  'camera/feature/sony/a9III': 'camera/feature/sony/a9III',
  'camera/feature/sony/fe300mmf28gmoss': 'camera/feature/sony/fe300mmf28gmoss',
  'camera/feature/sony/fe24mm50mmf28g': 'camera/feature/sony/fe24mm50mmf28g',
  'camera/feature/sony/fe16-25mmf28g': 'camera/feature/sony/fe16-25mmf28g',
  'camera/feature/sony/zv-e10m2': 'camera/feature/sony/zv-e10m2',

  // tamron
  'camera/feature/tamron/150500mmf567': 'camera/feature/tamron/150500mmf567',

  // fujifilm
  'camera/feature/fujifilm/xf18mmf14rlmwr': 'camera/feature/fujifilm/xf18mmf14rlmwr',
  'camera/feature/fujifilm/xs10': 'camera/feature/fujifilm/xs10',
  'camera/feature/fujifilm/gfx100s': 'camera/feature/fujifilm/gfx100s',
  'camera/feature/fujifilm/xf70300mmf456rlmoiswr': 'camera/feature/fujifilm/xf70300mmf456rlmoiswr',
  'camera/feature/fujifilm/xe4': 'camera/feature/fujifilm/xe4',
  'camera/feature/fujifilm/xt4': 'camera/feature/fujifilm/xt4',
  'camera/feature/fujifilm/xf33f14': 'camera/feature/fujifilm/xf33f14',
  'camera/feature/fujifilm/newgoods': 'camera/feature/fujifilm/newgoods',
  'camera/feature/fujifilm/x100v': 'camera/feature/fujifilm/x100v',
  'camera/feature/fujifilm/x-t200': 'camera/feature/fujifilm/x-t200',
  'camera/feature/fujifilm/xpro3': 'camera/feature/fujifilm/xpro3',
  'camera/feature/fujifilm/xf50mm-f1rwr': 'camera/feature/fujifilm/xf50mm-f1rwr',
  'camera/feature/fujifilm/xt4/4531': 'camera/feature/fujifilm/xt4/4531',
  'camera/feature/fujifilm/instax/mini11': 'camera/feature/fujifilm/instax/mini11',
  'camera/feature/fujifilm/gfx50sII': 'camera/feature/fujifilm/gfx50sII',
  'camera/feature/fujifilm/xt3': 'camera/feature/fujifilm/xt3',
  'camera/feature/fujifilm/xt2': 'camera/feature/fujifilm/xt2',
  'camera/feature/fujifilm/xc35mm_f2': 'camera/feature/fujifilm/xc35mm_f2',
  'camera/feature/fujifilm/x100v/4475': 'camera/feature/fujifilm/x100v/4475',
  'camera/feature/fujifilm/xh2s': 'camera/feature/fujifilm/xh2s',
  'camera/feature/fujifilm/xf150-600': 'camera/feature/fujifilm/xf150-600',
  'camera/feature/fujifilm/xh2': 'camera/feature/fujifilm/xh2',
  'camera/feature/fujifilm/xf18-200_xf56': 'camera/feature/fujifilm/xf18-200_xf56',
  'camera/feature/fujifilm/xt5': 'camera/feature/fujifilm/xt5',
  'camera/feature/fujifilm/xt5-list': 'camera/feature/fujifilm/xt5-list',
  'camera/feature/fujifilm/xs20': 'camera/feature/fujifilm/xs20',
  'camera/feature/fujifilm/x-100vi': 'camera/feature/fujifilm/x-100vi',
  // pentax
  'camera/feature/pentax/k3mk3': 'camera/feature/pentax/k3mk3',
  'camera/feature/pentax/k-1': 'camera/feature/pentax/k-1',
  'camera/feature/pentax/645z': 'camera/feature/pentax/645z',

  // sigma
  'camera/feature/sigma/fpl': 'camera/feature/sigma/fpl',

  // ricoh
  'camera/feature/ricoh/gr3x': 'camera/feature/ricoh/gr3x',
  'camera/feature/ricoh/thetasc': 'camera/feature/ricoh/thetasc',
  // meishokougaku
  'camera/feature/meishokougaku/11mm_f28': 'camera/feature/meishokougaku/11mm_f28',
  // tokina
  'camera/feature/tokina/4332': 'camera/feature/tokina/4332',
  // omsystem
  'camera/feature/omsystem/om1': 'camera/feature/omsystem/om1',
  'camera/feature/omsystem/om5': 'camera/feature/omsystem/om5',
  'camera/feature/omsystem/ed90mmf35macro': 'camera/feature/omsystem/ed90mmf35macro',
  'camera/feature/omsystem/om1m2': 'camera/feature/omsystem/om1m2',
  'camera/feature/omsystem/ed150-600mm': 'camera/feature/omsystem/ed150-600mm',

  // その他
  'camera/filmcamera/utsurundesu': 'camera/filmcamera/utsurundesu',
  'camera/feature/backnumber': 'camera/feature/backnumber',
  'sale-fair': 'sale-fair',
  'camera/filmcamera': 'camera/filmcamera',
  'camera/filmcamera/889': 'camera/filmcamera/889',
  'camera/filmcamera/890': 'camera/filmcamera/890',
  'camera/filmcamera/891': 'camera/filmcamera/891',
  'camera/filmcamera/892': 'camera/filmcamera/892',

  // kaden
  'kaden/airpurifier/aircleanercompare': 'kaden/airpurifier/aircleanercompare',
  'kaden/smartphone/iphone': 'kaden/smartphone/iphone',
  'kaden/smartphone/iphone/xsxsmaxxr': 'kaden/smartphone/iphone/xsxsmaxxr',
  'kaden/rice-cooker_bestbuy': 'kaden/rice-cooker_bestbuy',
  'kaden/smart_phone/iphone7': 'kaden/smart_phone/iphone7',
  'kaden/toaster-oven': 'kaden/toaster-oven',
  'kaden/ricemill/interview': 'kaden/ricemill/interview',
  'kaden/air-purifier_fair/daikin': 'kaden/air-purifier_fair/daikin',
  'kaden/heyabosi': 'kaden/heyabosi',
  'kaden/smart_phone/iphone7/iphone7-camera': 'kaden/smart_phone/iphone7/iphone7-camera',
  'kaden/dcfan': 'kaden/dcfan',
  'kaden/bottle': 'kaden/bottle',
  'kaden/bento': 'kaden/bento',
  'kaden/fan-fair': 'kaden/fan-fair',
  'kaden/smart_phone/iphone12': 'kaden/smart_phone/iphone12',
  'kaden/panasonic-dishwasher': 'kaden/panasonic-dishwasher',
  'kaden/air-purifier_fair': 'kaden/air-purifier_fair',
  'kaden/bottle/kids': 'kaden/bottle/kids',
  'kaden/bottle/sports': 'kaden/bottle/sports',
  'kaden/bottle/office': 'kaden/bottle/office',
  'kaden/heater-best': 'kaden/heater-best',
  'kaden/ricemill': 'kaden/ricemill',
  'kaden/fall-of-taste': 'kaden/fall-of-taste',
  'kaden/sixpad': 'kaden/sixpad',
  'kaden/digitalization': 'kaden/digitalization',
  // kadencampaign
  'kaden/campaign/irisohyama240412': 'kaden/campaign/irisohyama240412',

  // bag/feature
  'camera/bag/feature/trekking': 'camera/bag/feature/trekking',
  'camera/bag/trial': 'camera/bag/trial',
  'camera/bag': 'camera/bag',
  // okamoto-yutaka
  //'camera/okamoto-yutaka/leofoto': 'camera/okamotoyutaka/leofoto',
  //'camera/okamoto-yutaka/set': 'camera/okamoto-yutaka/set',
  // photomount
  'camera/photomount/244': 'camera/photomount/244',
  'camera/photomount/253': 'camera/photomount/253',
  'camera/photomount/252': 'camera/photomount/252',
  'camera/photomount/254': 'camera/photomount/254',
  'camera/photomount/262': 'camera/photomount/262',
  'camera/photomount/266': 'camera/photomount/266',
  'camera/photomount/269': 'camera/photomount/269',
  'camera/photomount/316': 'camera/photomount/316',
  'camera/photomount/318': 'camera/photomount/318',
  'camera/photomount/914': 'camera/photomount/914',
  'camera/photomount/1362': 'camera/photomount/1362',
  'camera/photomount/2433': 'camera/photomount/2433',
  'camera/photomount/feature': 'camera/photomount/feature',
  'camera/photomount/feature/317': 'camera/photomount/feature/317',
  'camera/photomount/246': 'camera/photomount/246',
  'camera/photomount/245': 'camera/photomount/245',

  // tripod
  'camera/tripod/type01': 'camera/tripod/type01',
  'camera/tripod/type02': 'camera/tripod/type02',
  'camera/tripod/type03': 'camera/tripod/type03',
  'camera/tripod/type04': 'camera/tripod/type04',
  'camera/tripod/type05': 'camera/tripod/type05',
  'camera/tripod/type06': 'camera/tripod/type06',
  'camera/tripod/parts01': 'camera/tripod/parts01',
  'camera/tripod/parts02': 'camera/tripod/parts02',
  'camera/tripod/parts03': 'camera/tripod/parts03',
  // dslr
  'camera/dslr/3228': 'camera/dslr/3228',
  'camera/dslr/3229': 'camera/dslr/3229',
  // frame
  'camera/frame/ordermade': 'camera/frame/ordermade',
  'camera/frame/shacolla': 'camera/frame/shacolla',
  'camera/frame/4086': 'camera/frame/4086',
  'camera/frame/2037': 'camera/frame/2037',
  'camera/frame/833': 'camera/frame/833',
  'camera/frame/443': 'camera/frame/443',
  'camera/frame/2441': 'camera/frame/2441',
  'camera/frame/470': 'camera/frame/470',
  'camera/frame/2446': 'camera/frame/2446',
  'camera/frame/2889': 'camera/frame/2889',
  'camera/frame/2442': 'camera/frame/2442',
  'camera/frame/459': 'camera/frame/459',
  'camera/frame/2440': 'camera/frame/2440',
  'camera/frame/456': 'camera/frame/456',
  'camera/frame/471': 'camera/frame/471',
  'camera/frame/cork-frame': 'camera/frame/cork-frame',
  'camera/frame/sapele': 'camera/frame/sapele',

  // storage
  'camera/storage/363': 'camera/storage/363',
  'camera/storage/366': 'camera/storage/366',
  'camera/storage/368': 'camera/storage/368',
  'camera/storage/371': 'camera/storage/371',
  // sale
  'sale/feature01': 'sale/feature01',
  'sale/feature01/535': 'sale/feature01/535',
  'sale/feature01/536': 'sale/feature01/536',
  'sale/feature01/537': 'sale/feature01/537',
  'sale/usedsale': 'sale/usedsale',
  'sale/usedsale/nikon': 'sale/usedsale/nikon',
  'sale/usedsale/nikon/lens': 'sale/usedsale/nikon/lens',
  'sale/usedsale/nikon/dslr': 'sale/usedsale/nikon/dslr',
  'sale/usedsale/nikon/mirrorless': 'sale/usedsale/nikon/mirrorless',
  'sale/usedsale/nikon/compact': 'sale/usedsale/nikon/compact',
  'sale/usedsale/nikon/filmcamera': 'sale/usedsale/nikon/filmcamera',
  'sale/usedsale/nikon/other': 'sale/usedsale/nikon/other',
  'sale/usedsale/canon': 'sale/usedsale/canon',
  'sale/usedsale/canon/lens': 'sale/usedsale/canon/lens',
  'sale/usedsale/canon/dslr': 'sale/usedsale/canon/dslr',
  'sale/usedsale/canon/mirrorless': 'sale/usedsale/canon/mirrorless',
  'sale/usedsale/canon/compact': 'sale/usedsale/canon/compact',
  'sale/usedsale/canon/filmcamera': 'sale/usedsale/canon/filmcamera',
  'sale/usedsale/canon/other': 'sale/usedsale/canon/other',
  'sale/usedsale/sony': 'sale/usedsale/sony',
  'sale/usedsale/sony/lens': 'sale/usedsale/sony/lens',
  'sale/usedsale/sony/dslr': 'sale/usedsale/sony/dslr',
  'sale/usedsale/sony/mirrorless': 'sale/usedsale/sony/mirrorless',
  'sale/usedsale/sony/compact': 'sale/usedsale/sony/compact',
  'sale/usedsale/sony/other': 'sale/usedsale/sony/other',
  'sale/usedsale/fujifilm': 'sale/usedsale/fujifilm',
  'sale/usedsale/fujifilm/lens': 'sale/usedsale/fujifilm/lens',
  'sale/usedsale/fujifilm/dslr': 'sale/usedsale/fujifilm/dslr',
  'sale/usedsale/fujifilm/mirrorless': 'sale/usedsale/fujifilm/mirrorless',
  'sale/usedsale/fujifilm/compact': 'sale/usedsale/fujifilm/compact',
  'sale/usedsale/fujifilm/filmcamera': 'sale/usedsale/fujifilm/filmcamera',
  'sale/usedsale/fujifilm/other': 'sale/usedsale/fujifilm/other',
  'sale/usedsale/olympus': 'sale/usedsale/olympus',
  'sale/usedsale/olympus/lens': 'sale/usedsale/olympus/lens',
  'sale/usedsale/olympus/dslr': 'sale/usedsale/olympus/dslr',
  'sale/usedsale/olympus/mirrorless': 'sale/usedsale/olympus/mirrorless',
  'sale/usedsale/olympus/compact': 'sale/usedsale/olympus/compact',
  'sale/usedsale/olympus/filmcamera': 'sale/usedsale/olympus/filmcamera',
  'sale/usedsale/olympus/other': 'sale/usedsale/olympus/other',
  'sale/usedsale/panasonic': 'sale/usedsale/panasonic',
  'sale/usedsale/panasonic/lens': 'sale/usedsale/panasonic/lens',
  'sale/usedsale/panasonic/mirrorless': 'sale/usedsale/panasonic/mirrorless',
  'sale/usedsale/panasonic/compact': 'sale/usedsale/panasonic/compact',
  'sale/usedsale/panasonic/other': 'sale/usedsale/panasonic/other',
  'sale/usedsale/pentax': 'sale/usedsale/pentax',
  'sale/usedsale/pentax/lens': 'sale/usedsale/pentax/lens',
  'sale/usedsale/pentax/dslr': 'sale/usedsale/pentax/dslr',
  'sale/usedsale/pentax/mirrorless': 'sale/usedsale/pentax/mirrorless',
  'sale/usedsale/pentax/compact': 'sale/usedsale/pentax/compact',
  'sale/usedsale/pentax/filmcamera': 'sale/usedsale/pentax/filmcamera',
  'sale/usedsale/pentax/other': 'sale/usedsale/pentax/other',
  'sale/usedsale/sigma': 'sale/usedsale/sigma',
  'sale/usedsale/sigma/lens': 'sale/usedsale/sigma/lens',
  'sale/usedsale/sigma/mirrorless': 'sale/usedsale/sigma/mirrorless',
  'sale/usedsale/sigma/other': 'sale/usedsale/sigma/other',
  'sale/usedsale/tamron': 'sale/usedsale/tamron',
  'sale/usedsale/tamron/lens': 'sale/usedsale/tamron/lens',
  'sale/usedsale/tamron/other': 'sale/usedsale/tamron/other',
  'sale/usedsale/zeiss': 'sale/usedsale/zeiss',
  'sale/usedsale/zeiss/lens': 'sale/usedsale/zeiss/lens',
  'sale/usedsale/leica': 'sale/usedsale/leica',
  'sale/usedsale/leica/lens': 'sale/usedsale/leica/lens',
  'sale/usedsale/leica/dslr': 'sale/usedsale/leica/dslr',
  'sale/usedsale/leica/mirrorless': 'sale/usedsale/leica/mirrorless',
  'sale/usedsale/leica/compact': 'sale/usedsale/leica/compact',
  'sale/usedsale/leica/filmcamera': 'sale/usedsale/leica/filmcamera',
  'sale/usedsale/leica/other': 'sale/usedsale/leica/other',
  'sale/usedsale/other': 'sale/usedsale/other',
  'sale/usedsale/other/lens': 'sale/usedsale/other/lens',
  'sale/usedsale/other/dslr': 'sale/usedsale/other/dslr',
  'sale/usedsale/other/mirrorless': 'sale/usedsale/other/mirrorless',
  'sale/usedsale/other/compact': 'sale/usedsale/other/compact',
  'sale/usedsale/other/filmcamera': 'sale/usedsale/other/filmcamera',
  'sale/usedsale/other/other': 'sale/usedsale/other/other',
  'sale/feature02': 'sale/feature02',
  'sale/feature02/717': 'sale/feature02/717',
  'sale/feature02/718': 'sale/feature02/718',
  'sale/feature01/789': 'sale/feature01/789',
  'sale/feature01/4897': 'sale/feature01/4897',
  'sale/usedsaleweek': 'sale/usedsaleweek',
  'sale/usedsale/nikon/lens2': 'sale/usedsale/nikon/lens2',
  'sale/usedsale/canon/lens2': 'sale/usedsale/canon/lens2',
  'sale/feature01/4898': 'sale/feature01/4898',
  'sale/feature01/5827': 'sale/feature01/5827',
  'sale/hatsuuri': 'sale/hatsuuri',
  'sale/hatsuuri/01': 'sale/hatsuuri/01',
  'sale/hatsuuri/02': 'sale/hatsuuri/02',
  'sale/hatsuuri/03': 'sale/hatsuuri/03',
  'sale/hatsuuri/04': 'sale/hatsuuri/04',
  'sale/hatsuuri/05': 'sale/hatsuuri/05',
  'sale/hatsuuri/06': 'sale/hatsuuri/06',
  'sale/hatsuuri/07': 'sale/hatsuuri/07',
  'sale/hatsuuri/u01': 'sale/hatsuuri/u01',
  'sale/hatsuuri/u02': 'sale/hatsuuri/u02',
  'sale/hatsuuri/u03': 'sale/hatsuuri/u03',
  'sale/hatsuuri/5507': 'sale/hatsuuri/5507',
  'sale/hatsuuri/m01': 'sale/hatsuuri/m01',
  'sale/hatsuuri/m02': 'sale/hatsuuri/m02',
  'sale/hatsuuri/m03': 'sale/hatsuuri/m03',
  'sale/usedflashsale': 'sale/usedflashsale',
  'sale/usedflashsale/5864': 'sale/usedflashsale/5864',
  'sale/usedflashsale/5865': 'sale/usedflashsale/5865',
  'sale/usedflashsale/5866': 'sale/usedflashsale/5866',
  'sale/usedflashsale/5867': 'sale/usedflashsale/5867',
  'sale/usedsale/minolta/lens': 'sale/usedsale/minolta/lens',
  'sale/usedsale/minolta/filmcamera': 'sale/usedsale/minolta/filmcamera',
  'sale/usedsale/contax/lens': 'sale/usedsale/contax/lens',
  'sale/usedsale/contax/filmcamera': 'sale/usedsale/contax/filmcamera',
  'sale/usedsale/mamiya/lens': 'sale/usedsale/mamiya/lens',
  'sale/usedsale/mamiya/filmcamera': 'sale/usedsale/mamiya/filmcamera',
  'sale/usedcameracoupon': 'sale/usedcameracoupon',
  'sale/feature03': 'sale/feature03',
  'sale/feature03/2150': 'sale/feature03/2150',
  'sale/usedcameracouponitem': 'sale/usedcameracouponitem',
  // reuse
  'reuse/watch': 'reuse/watch',
  'reuse/smartphone': 'reuse/smartphone',
  'reuse/watch-sample': 'reuse/watch-sample',
  'reuse/rpc-usedpc/': 'reuse/rpc-usedpc/',
  //'reuse/smartphone/refurbish': 'reuse/smartphone/refurbish',
  'reuse/usedpc/': 'reuse/usedpc/',
  'reuse/usedpc/photo-editing-pc/': 'reuse/usedpc/photo-editing-pc/',
  'reuse/usedpc/ranking/': 'reuse/usedpc/ranking/',
  // reuse sale
  'reuse/reusesale': 'reuse/reusesale',
  // reuse sell
  //'watch/kitamuracamera/': 'watch/kitamuracamera/',
  //'smartphone/kitamuracamera/': 'smartphone/kitamuracamera/',
  //'smartphone/splogicenter/': 'smartphone/splogicenter/',
  'watch/rolex/': 'watch/rolex/',
  'watch/omega/': 'watch/omega/',
  'watch/japanmadewatch/': 'watch/japanmadewatch/',
  'watch/kitamuracamera-nakano/': 'watch/kitamuracamera-nakano/',
  'watch/kitamuracamera-nakano/clearancesale/': 'watch/kitamuracamera-nakano/clearancesale/'
} as { [key: string]: string };

export default Vue.extend({
  name: 'special-page',
  components: COMPONENT_LIST,
  setup(props, context) {
    const query = context.root.$route.query;
    const state = reactive({
      currentComponent: '',
      // 表示期間外メッセージ
      errorMsg: ''
    });

    onMounted(() => {
      const splitUrl = context.root.$route.path.split('/ec/special/');
      const targetPage = splitUrl[splitUrl.length - 1];
      // URL_MAPPING_LISTに設定されていないページや存在しないURLは、NotFoundPageに遷移させる
      if (Object.keys(URL_MAPPING_LIST).includes(targetPage)) {
        state.currentComponent = URL_MAPPING_LIST[targetPage];
      } else {
        context.root.$router.push({ name: 'not-found-page' });
      }
    });

    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async (from: string, to: string) => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
      if (!validationPeriod(today, from, to)) {
        state.errorMsg = '該当ページは掲載期間が終了いたしました。';
      }
    };
    return {
      ...toRefs(state),
      verifyDate
    };
  }
});
