










































































































































































































































































































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'polarie',
  components: {
    breadcrumbs: Breadcrumbs,
    orangeBtn: OrangeBtn,
    watchInVideo: WatchInVideo,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    priceAndPopular: PriceAndPopular,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'ポラリエとカメラ用望遠レンズで星空撮影 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ポラリエとカメラ用望遠レンズを使っての星空撮影・天体撮影におすすめの商品をご紹介☆ポータブル赤道儀「星空雲台ポラリエ」と「ポラリエ用ステップアップキットII」などのカスタムパーツで、星空の望遠撮影にチャレンジしてみませんか？'
      );

    const state = reactive({
      visible: false,
      isReinforcedParts: false,
      visibleGallery: false,
      index: 0,
      imgs: [
        '/ec/images2/special/camera/polarie/acc/ex01.jpg',
        '/ec/images2/special/camera/polarie/acc/ex02.jpg',
        '/ec/images2/special/camera/polarie/acc/ex03.jpg',
        '/ec/images2/special/camera/polarie/acc/ex04.jpg'
      ],
      naviList: [
        { naviItem: '星空写真・天体写真とは？ ', href: '#acc01' },
        { naviItem: '人気の中・望遠レンズ ', href: '#acc06' },
        { naviItem: '星空撮影おすすめカメラ ', href: '#acc05' },
        { naviItem: 'ポラリエと強化パーツ ', href: '#acc03' },
        { naviItem: '星空写真作例 ギャラリー ', href: '#acc02' },
        { naviItem: '星空撮影におすすめ商品一覧 ', href: '#acc07' }
      ],
      naviList2: [
        {
          name: '① クイックリリースクランプセット',
          src: 'https://shopimg.kitamura.jp/images/pd/6d8/2a8/4b1/dae/883/212/a19/066/013/8a1/ca4/mlc/qgy/c/M.jpg ',
          href: '#kyoka01',
          color: '#FF4100'
        },
        {
          name: '② 星空雲台 ポラリエ',
          src: 'https://shopimg.kitamura.jp/images/pd/b60/a10/665/298/232/292/861/b83/346/80b/ea1/89n/zn0/x/M.jpg',
          href: '#acc03',
          color: '#FF7D00'
        },
        {
          name: '② ステップアップキットII',
          src: '	https://shopimg.kitamura.jp/images/pd/a3a/cf3/e47/2d2/ebe/9fa/bd5/961/8a0/16a/57c/v0r/w9q/m/M.jpg',
          href: '#kyoka02',
          color: '#FF7D00'
        },
        {
          name: '③ APフォトガイダー用ウェイト軸',
          src: 'https://shopimg.kitamura.jp/images/pd/12f/ee2/5af/adb/761/f0d/e6a/336/cf3/5bb/67s/9nm/h09/5/M.jpg',
          href: '#kyoka03',
          color: '#E4BF00'
        },
        {
          name: '③ バランスウェイト1kg',
          src: 'https://shopimg.kitamura.jp/images/pd/fb4/a63/491/ae2/ddf/990/97e/623/ded/32c/6e9/a5t/118/6/M.jpg',
          href: '#kyoka03',
          color: '#E4BF00'
        }
      ],
      popularLensList: [
        {
          name: 'OM SYSTEM M.ZUIKO DIGITAL ED 40-150mm F2.8 PRO',
          src: 'https://shopimg.kitamura.jp/images/pd/27a/441/0ae/066/7a3/d53/9ce/b4a/483/73e/19z/cru/ctl/6/TN.jpg',
          href: '/ec/pd/4545350054182'
        },
        {
          name: 'シグマ 105mm F2.8 DG DN MACRO Art',
          src: 'https://shopimg.kitamura.jp/images/pd/0dc/bc4/db0/d71/158/ca6/82d/21a/483/de4/cb1/72b/pt9/n/TN.jpg',
          href: '/ec/list?keyword=%E3%82%B7%E3%82%B0%E3%83%9E%20105mm%20F2.8%20DG%20DN%20MACRO%20Art&narrow18=0'
        },
        {
          name: 'タムロン 35-150mm F/2-2.8 Di III VXD',
          src: 'https://shopimg.kitamura.jp/images/pd/62d/44e/174/52b/9b0/7c0/2c2/0cb/626/7ee/689/9gc/pbv/z/TN.jpg',
          href: '/ec/list?keyword=Model%20A058&narrow18=0'
        },
        {
          name: 'ソニー FE 85mm F1.4 GM II [SEL85F14GM2]',
          src: 'https://shopimg.kitamura.jp/images/pd/21c/b8b/d3a/291/c11/cd7/0d4/2b1/776/5c3/bff/v0t/3bp/0/TN.jpg',
          href: '/ec/pd/4548736156760'
        },
        {
          name: 'ニコン NIKKOR Z 70-180mm f/2.8',
          src: 'https://shopimg.kitamura.jp/images/pd/741/ca9/2e8/fe7/60a/caa/466/c83/cfb/dfb/4fi/sg7/9c9/1/TN.jpg',
          href: '/ec/pd/4960759911087'
        }
      ],
      starrySkyShootingList: [
        {
          name: 'OM SYSTEM\nマイクロフォーサーズ',
          href:
            '/ec/list?narrow18=0&keyword=オリンパス&category=ミラーレス一眼&limit=40&n20c=完了商品は除く&index=&searchbox=1&q=オリンパス&path=ミラーレス一眼',
          src: 'https://shopimg.kitamura.jp/images/pd/173/73c/7b4/90c/923/936/5bb/fda/a01/557/2ej/519/7p5/l/TN.jpg',
          txt:
            'OM SYSTEMのミラーレス一眼レフも赤外領域が写り込みます。小型軽量のため持ち運びがしやすく大変便利。 OM SYSTEMを星空撮影で使用する最大のメリットは、マイクロフォーサーズ規格により実現した、周辺減光・サジタルコマフレアの少ないレンズ群。星空撮影向きのレンズが多いことが特徴です。\n星景写真で活躍する「ライブコンポジット機能」など、他社にはない機能も満載です。'
        },
        {
          name: 'フジフイルム\nXシリーズ',
          href:
            '/ec/list?narrow18=0&keyword=富士フイルム&category=ミラーレス一眼&index=&searchbox=1&sort=number20,Number17,Score&q=富士フイルム&path=ミラーレス一眼&n20c=完了商品は除く',
          src: 'https://shopimg.kitamura.jp/images/pd/05c/a9d/9d7/c13/7ef/788/bd3/8ff/d48/4a3/0cu/7i2/xmx/w/TN.jpg',
          txt:
            'フジフイルムのXシリーズに搭載されているAPS-Cセンサー「X-Trans CMOS」は赤外領域がよく写る、ということで星空撮影カメラマンたちの間では、非常に人気があります。昼間の撮影で色再現性がいいとされるフジフイルムですが、その発色は星空の撮影でも同様に威力が発揮されるようです。\nミラーレス一眼レフはミラーショックが少なく、小型・軽量のため、赤道儀へ搭載するときに非常に使い回しがよく、バランスウェイトなどの機材も少なく済みます。'
        },
        {
          name: 'ペンタックス\nK-1 MarkII',
          href:
            '/ec/list?category=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&keyword=%E3%83%9A%E3%83%B3%E3%82%BF%E3%83%83%E3%82%AF%E3%82%B9%20K-1%20MarkII&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity',
          src: 'https://shopimg.kitamura.jp/images/pd/a7a/30a/23f/6c7/756/34a/b4f/450/bde/519/33j/rhz/1hy/0/TN.jpg',
          txt:
            '有効約3640万画素ローパスフィルターレス仕様のフルサイズセンサー搭載モデル。ISO感度も100～819200の常用感度から超高感度域まで全域で高い解像感を維持しながら、徹底的にノイズを抑えた高画質を実現しています。また、星を言追いかける機能であるアストロトレーサー機能を3パターン搭載しており、撮影目的に応じて使い分けることが可能。防塵・防滴構造とマイナス10℃まで耐えられる仕様なので、安心して撮影出来ることも人気の理由です。'
        }
      ],
      galleryList: [
        {
          src: '/ec/images2/special/camera/polarie/acc/m33.jpg',
          title: 'M33・メラク・M31',
          txt:
            'アンドロメダ座のメラク（真ん中の黄色い星）を挟んで、ほぼ等間隔で右下にアンドロメダ銀河、左上にさんかく座の渦巻銀河・M33があります。100mm前後の焦点距離で、この構図を撮影することができます。この２つの天体は、非常に写真写りがよく、さらに拡大して撮影するのも楽しいです。\n秋に撮影できる人気のスポットです。',
          camera: 'Fujifilm X-T1',
          lens: 'XF50-140mm f2.8R LM OIS WR',
          aperture: 'f/2.8',
          speed: '露出90秒',
          focalLength: '105mm',
          iso: '1600'
        },
        {
          src: '/ec/images2/special/camera/polarie/acc/oron.jpg',
          title: 'オリオン座中心部',
          txt:
            'オリオン座・中央部分にオリオンベルトと呼ばれる三ツ星があります。この三ツ星・左側に、有名な「燃える木星雲」「馬頭星雲」があり、その下には「M42・オリオン大星雲」がある、冬の人気スポットです。\n200mmでもここまで写るので、初心者の方に大変おすすめです。',
          camera: 'Fujifilm X-T2',
          lens: 'XF50-140mm f2.8R LM OIS WR',
          aperture: 'f/5.6',
          speed: '露出185秒',
          focalLength: '210mm',
          iso: '2500'
        },
        {
          src: '/ec/images2/special/camera/polarie/acc/btoo.jpg',
          title: '馬頭星雲と燃える木星雲',
          txt: 'オリオンベルト・左の星、左側に「燃える木星雲」、その下に「馬頭星雲」があります。\n一度は撮影してみたい、人気のある天体です。',
          camera: 'Fujifilm X-T2',
          lens: 'XF100-400mm f4.5-5.6 R LM OIS WR',
          aperture: 'f/7.1',
          speed: '露出178秒',
          focalLength: '600mm',
          iso: '6400'
        },
        {
          src: '/ec/images2/special/camera/polarie/acc/crisu.jpg',
          title: 'カリフォルニア星雲とすばる',
          txt:
            '右にある青い星の集まりが「M45・すばる」。左の赤いナメクジようなものが「カリフォルニア星雲」です。\nこちらも100mm前後で撮影ができますが、それぞれをさらに長い焦点距離で撮影するのもいいでしょう。',
          camera: 'Fujifilm X-T2',
          lens: 'XF50-140mm f2.8R LM OIS WR',
          aperture: 'f/2.8',
          speed: '露出92秒',
          focalLength: '105mm',
          iso: '3200'
        },
        {
          src: '/ec/images2/special/camera/polarie/acc/noseamr.jpg',
          title: '北アメリカ星雲とデネヴ・サドル付近',
          txt:
            '夏の大三角のひとつ・はくちょう座のデネヴ周辺は、赤い散光星雲が多い領域です。また、天の川上にあるため、微光星がたくさんあります。微光星のあるところと、ないところでの濃淡が激しく、非常に写真写りのいい撮影スポットです。',
          camera: 'Olympus PEN-F',
          lens: 'M.ZUIKO DIGITAL ED40-150mm f2.8 PRO',
          aperture: 'f/2.8',
          speed: '露出60秒',
          focalLength: '80mm',
          iso: '2500'
        },
        {
          src: '/ec/images2/special/camera/polarie/acc/bambi.jpg',
          title: 'バンビの横顔 ',
          txt:
            '夏の天の川で一番濃淡がある領域です。真ん中やや上の部分が小鹿が横を向いているように見えるため、このように呼ばれています。\nこの領域の下には「干潟星雲」「三裂星雲」があり、上には「わし星雲」「オメガ星雲」があるため、非常に派手な写真写りをします。\n夏の大人気スポットです。',
          camera: 'EOS 5D MK2',
          lens: 'EF70-200 f2.8L ISII USM',
          aperture: 'f/3.5',
          speed: '露出104秒',
          focalLength: '175mm',
          iso: '1600'
        }
      ],
      recommendedFeaturesList: [
        {
          href: 'https://shop.kitamura.jp/special/sale-fair/camera/hosizora/',
          img: 'https://shopimg.kitamura.jp/images/banner/5032.png',
          alt: '星空総合ページ'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        },
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '電話注文のご案内'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '星空総合ページ',
          linkUrl: '/ec/special/camera/hosizora',
          disabled: false
        },
        {
          path: 'ポラリエとカメラ用望遠レンズで星空・天体撮影',
          disabled: true
        }
      ],
      // 取得するJancode
      productJanCodeList: ['4955295355341', '4955295355280', '4955295355051', '2239530000053', '4955295258260', '4955295258017', '4955295354313'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };

    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };

    const handleHide = () => {
      state.visible = false;
      state.isReinforcedParts = false;
      state.visibleGallery = false;
    };

    const showSystmImg = () => {
      state.isReinforcedParts = true;
    };

    const showGalleryImg = (index: number) => {
      state.index = index;
      state.visibleGallery = true;
    };

    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg,
      handleHide,
      showSystmImg,
      showGalleryImg
    };
  }
});
