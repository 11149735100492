






























































import Vue from 'vue';
import { computed, reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'frame',
  components: {
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '写真の額縁(フォトフレーム)のおすすめ一覧【カメラのキタムラ】';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'カメラのキタムラで販売している写真の額縁・フォトフレームを掲載しています。人気の商品やサイズ、贈り物などで絞り込めるので、写真の額縁が欲しい方は是非ご覧ください。キタムラの店舗受取りが可能な商品なら送料無料です'
      );
    const state = reactive({
      category: 'all',
      narrowingList: [
        { src: '/ec/images2/special/camera/frame/btn-print-w.png', value: 'all' },
        { src: '/ec/images2/special/camera/frame/btn-new-w.png', value: 'popularity' },
        { src: '/ec/images2/special/camera/frame/btn-general-w.png', value: 'lpf' },
        { src: '/ec/images2/special/camera/frame/btn-camera-w.png', value: 'pf' },
        { src: '/ec/images2/special/camera/frame/btn-p.png', value: 'ppf' },
        { src: '/ec/images2/special/camera/frame/btn-sale-w.png', value: 'sale' }
      ],
      photoFrameList: [
        {
          category: ['pf', 'all'],
          href: '/ec/special/camera/frame/cork-frame',
          name: 'リサイクル素材で作ったフォトスタンド',
          src: 'https://shopimg.kitamura.jp/images/pd/905/9b4/498/5ae/ac8/bbc/6e3/043/402/ff3/5ai/5zw/gve/8/L.jpg',
          txt: 'エコ素材で地球にやさしいフォトスタンド',
          size: 'L/2L'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=万丈 飾れるMAGフレーム &narrow18=0',
          name: '万丈 飾れるMAGフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/02e/c05/5f2/423/819/579/44f/bbe/dc1/4b7/a1r/7sq/bw3/n/L.jpg',
          txt: '全面マグネットタイプのフレーム',
          size: '2L/A4'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=万丈 画用紙額 &narrow18=0',
          name: '万丈 画用紙額',
          src: 'https://shopimg.kitamura.jp/images/pd/dbf/fbe/fa2/c5d/4b6/35f/c66/970/6ba/cb2/6fv/l5a/cn4/t/L.jpg',
          txt: '画用紙にぴったりなサイズの額',
          size: '八切'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/pd/4950486445603',
          name: '万丈 ツインフレーム ワイン',
          src: 'https://shopimg.kitamura.jp/images/pd/1ad/2ce/553/4f4/429/da0/6e4/9c4/2fc/fe3/547/y1o/vhf/m/L.jpg',
          txt: '見開きで2面飾れる定番フォトフレーム',
          size: 'L判/ポスト判/2L判'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/pd/4977187510119',
          name: 'ハクバ TRG-01 NT Ｔシャツ・レコード額',
          src: 'https://shopimg.kitamura.jp/images/pd/67d/e11/404/35d/a23/3c0/0c7/eff/7d7/62d/08l/ryb/hp5/5/L.jpg',
          txt: 'お気に入りのTシャツやレコードジャケットを飾ろう',
          size: ''
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/2037',
          name: 'オリジナル 木製額',
          src: 'https://shopimg.kitamura.jp/images/pd/004/95f/d42/666/046/096/bf5/b68/5e1/f55/fcy/009/mc2/h/M.jpg',
          txt: 'A4/W4サイズの写真が飾れるシンプルな木製額',
          size: 'A4 W4'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/list?keyword=%E3%82%B1%E3%83%B3%E3%82%B3%E3%83%BC%20%E3%82%A2%E3%82%BF%E3%83%B3%E3%83%89&narrow18=0',
          name: 'ケンコー アタンド',
          src: 'https://shopimg.kitamura.jp/images/pd/50e/3aa/977/6e0/68f/fc6/38e/36c/cd6/17e/eek/bwb/inb/i/M.jpg',
          txt: 'シンプルな形状のアルミフレーム',
          size: 'A4 W4 4切 6切 A3 A3ノビ 2L'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/special/camera/frame/450',
          name: 'パステルカラーフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/fea/748/093/702/287/a0f/7e3/d37/003/f4e/8a9/9ur/0u4/g/M.jpg',
          txt: 'パステル色がやさしい印象のメタルカラーフレーム',
          size: 'L 2L'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?category=%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0&keyword=Chululu&narrow18=0&limit=40&page=1&sort=popularity',
          name: 'Chululu フォトフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/27c/63d/c6d/69c/29e/a01/de4/ffd/870/66c/146/now/aju/3/L.jpg',
          txt: '3つのサイズの写真を飾れる3WAYフォトフレーム',
          size: 'L ハガキ 2L'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=%E3%82%A8%E3%83%A9%E3%83%9E&narrow18=0',
          name: 'エラマ インテリアフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/19c/e9b/75f/5e3/02a/609/af0/f08/02e/a6a/2cm/ufk/q7u/k/M.jpg',
          txt: 'ぬくもりを感じる天然木を使用したフォトフレーム',
          size: 'L ハガキ 2L A4'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=%E3%83%86%E3%83%AA%E3%82%A23&narrow18=0',
          name: 'フォトフレーム テリア3',
          src: 'https://shopimg.kitamura.jp/images/pd/4fa/baf/45b/6dd/292/f50/1ac/0e6/b72/8a1/91x/w5z/vrp/u/M.jpg',
          txt: '上品な凹凸のついた棹を使用したフォトフレーム',
          size: 'L 2L'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=%E3%81%99%E3%81%9A%E3%81%8B%E3%81%9C&narrow18=0',
          name: 'キング 木製額 すずかぜ',
          src: 'https://shopimg.kitamura.jp/images/pd/2e9/4d9/91b/f3c/175/678/0a8/073/bfe/c0d/947/u3r/ik4/7/M.jpg',
          txt: 'Vカットマット付きのシンプルな木製額縁',
          size: '2L/6切/W6/A4/4切/W4'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/special/camera/frame/2440',
          name: 'キング JWC 木目調カラーフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/123/a15/0f2/ba2/ef7/8ee/2b6/67c/fe8/7c2/daj/ubj/9i7/6/M.JPG',
          txt: 'ナチュラルな木目を活かしたカラーフレーム',
          size: 'L/ハガキ/2L'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/2441',
          name: 'ナカバヤシ アルミ製軽量写真額',
          src: 'https://shopimg.kitamura.jp/images/pd/ac1/a93/7a8/11f/a74/fc9/392/291/2e9/110/9au/imo/dld/e/L.jpg',
          txt: '軽量・割れなく安全！',
          size: '6切/A3/'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=OKF-BF&narrow18=0',
          name: '万丈 Boxフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/5ab/624/4dc/d7a/0a2/95f/571/8a4/594/f10/23w/icj/23v/5/M.jpg',
          txt: '平面から立体まで作品を飾れるボックスフレーム',
          size: 'A5/ハガキ'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/2446',
          name: 'ケンコー ギャラリー フレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/f85/fa2/355/305/99d/75c/59d/ed3/ce1/86c/23k/q5w/t4y/g/M.jpg',
          txt: '写真展では定番な作品額',
          size: '2L/6切/W6/4切/W4/A4/A3/半切/全紙'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/440',
          name: 'フジカラー マットパネル WPII',
          src: 'https://shopimg.kitamura.jp/images/pd/2f8/f33/744/e13/009/a05/893/052/5cc/a09/20i/d8z/e8e/v/M.jpg',
          txt: '水、ノリ不要、簡単に写真パネル',
          size: '2L/6切/W6/4切/W4/A4/A3/A3ノビ/半切/全紙'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/special/camera/frame/448',
          name: 'ケンコー ツイード',
          src: 'https://shopimg.kitamura.jp/images/pd/e3f/a74/001/058/f93/155/e7a/05f/161/486/b1m/4c4/cu0/y/M.jpg',
          txt: 'カジュアルかつトラッドな雰囲気の写真立て',
          size: 'L/2L'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/446',
          name: 'フジカラー アルミ額縁 A105A',
          src: 'https://shopimg.kitamura.jp/images/pd/725/8c2/105/9b1/957/6a7/0e5/243/f18/ed9/0dk/efk/hv9/y/M.jpg',
          txt: '大伸ばしプリントにおすすめ',
          size: '2L/6切/W6/4切/W4/A4/半切/全紙'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/456',
          name: 'フジカラー ワイドマットフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/e9b/0f3/e7d/1ef/0e7/4c6/d22/ac6/21c/ecd/5fp/w4u/pmx/4/M.jpg',
          txt: 'キリッと引き締まったブラックフェースが魅力',
          size: '4切/W4切/半切/全紙'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/430',
          name: 'ハクバ アルミ額縁 ペオリア',
          src: 'https://shopimg.kitamura.jp/images/pd/4cc/8de/cae/73c/33d/cbb/9bf/dfc/0bc/e00/5fw/0yr/op1/p/L.jpg',
          txt: '展示会・フォーマルな用途に合うアルミ製フレーム',
          size: 'A3'
        },
        {
          category: ['pf', 'popularity', 'all'],
          href: '/ec/list?keyword=%E3%83%A9%E3%83%89%E3%83%B3%E3%83%8A%20DF85&narrow18=0',
          name: 'ラドンナ DF85 インテリアフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/021/c04/aa1/ab3/e39/f35/60b/a8b/2a6/75c/bau/xag/bs8/9/M.jpg',
          txt: 'おしゃれなインテリアフレーム',
          size: 'L/ハガキ/2L/A4/A3'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/457',
          name: 'フジカラー 木製額縁 M10',
          src: 'https://shopimg.kitamura.jp/images/pd/f70/98e/ab7/580/21c/36b/4ce/6df/bb0/a9a/01m/iwx/don/p/M.jpg',
          txt: '丸みを帯びたスリムなフレーム',
          size: '6切'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/list?keyword=%E6%9C%A8%E8%A3%BD%E9%A1%8D%E7%B8%81%20M12&narrow18=0',
          name: 'フジカラー 木製額縁 M12',
          src: 'https://shopimg.kitamura.jp/images/pd/d11/fc7/14a/b5e/831/e47/8ca/f82/e67/b9c/b98/axz/6u8/v/M.jpg',
          txt: '透明アクリル板使用',
          size: '6切/4切/W4/W6/全紙/半切'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/605',
          name: 'フジカラー IM-30 木製額',
          src: 'https://shopimg.kitamura.jp/images/pd/d92/279/9bf/5f7/4b5/371/909/7f4/f9d/893/91i/hde/02x/8/M.jpg',
          txt: '前面板にPET素材を使用した軽量の木製額縁です',
          size: '2L/A4/W6/6切/4切/W4'
        },

        {
          category: ['lpf', 'popularity', 'all'],
          href: '/ec/special/camera/frame/405',
          name: 'キタムラオリジナル木製額縁',
          src: 'https://shopimg.kitamura.jp/images/pd/93d/633/dd0/92b/1db/44e/302/acf/eb0/dc0/65m/98c/vmw/4/L.jpg',
          txt: '飽きのこないシンプルでスリムなデザイン',
          size: 'A4/W4 '
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/428',
          name: 'ハクバ HFA-03 アルミ額縁 ',
          src: 'https://shopimg.kitamura.jp/images/pd/131/4db/78c/ae6/0df/b24/0e2/5f9/cce/02b/d39/1ar/wce/7/M.jpg',
          txt: '細身の棹とワイドマットが特徴のアルミ製フレーム',
          size: 'A4/W4/A3/A3ノビ/半切/全紙/'
        },
        //{
        //  category: ['lpf', 'popularity', 'all'],
        //  href: '/ec/special/camera/frame/443',
        //  name: 'ハンザ 差替えマットパネル',
        //  src: 'https://shopimg.kitamura.jp/images/pd/ee2/f99/850/a11/67a/f31/67b/05f/8f9/061/87s/ga6/b5r/4/M.jpg',
        //  txt: '写真の差替えが出来る木製パネル',
        //  size: '4切/W4/A4/A3/A3ノビ/半切/全紙'
        //},
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/452',
          name: 'フジカラー プロフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/264/5bd/dc2/966/731/daf/14e/a86/d69/a3f/66j/rof/5ct/g/M.jpg',
          txt: '厚さ3mmのアクリル板使用',
          size: 'L/2L/4切/6切/半切'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/453',
          name: 'フジカラー マットパネル DX',
          src: 'https://shopimg.kitamura.jp/images/pd/170/0a7/5a4/91e/37b/d04/6d4/85b/57f/fb7/68y/2jy/brx/2/M.jpg',
          txt: '厚手Ｖカット台紙でさらにスマート',
          size: '4切/W4/A3/A3ノビ/半切/全紙'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/445',
          name: 'フジカラー アートフレームホワイト',
          src: 'https://shopimg.kitamura.jp/images/pd/690/266/edc/ae6/035/601/b18/ce0/e30/730/19v/a11/b9d/k/M.jpg',
          txt: '木製・透明ガラス使用',
          size: '2L/6切/W6/4切/W4/半切/全紙'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/special/camera/frame/2793',
          name: 'ハクバ FWMM01 木製フレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/185/4c1/2d7/54b/007/4b2/17e/550/3b6/787/f79/xav/ixi/2/M.jpg',
          txt: '飾りのないシンプルな角形のデザイン',
          size: '2L/6切/W6/4切/W4/A3/A3ノビ'
        },
        {
          category: ['lpf', 'all'],
          href: '/ec/list?keyword=%E3%82%BD%E3%83%95%E3%82%B1%E3%83%B3&narrow18=0',
          name: 'ソフケン ワンタッチアルミパネル スリムエイト',
          src: 'https://shopimg.kitamura.jp/images/pd/051/ed5/bd1/7cf/d90/545/756/833/968/5b8/a7b/29v/ppd/s/M.jpg',
          txt: '軽快前開きパネルで作品の出し入れ簡単',
          size: 'A1/A2/A3/A4/B3/B4 '
        },
        //{
        //  category: ['pf', 'all'],
        //  href: '/ec/special/camera/frame/2442',
        //  name: 'チクマ 木製額FI フレーム ',
        //  src: 'https://shopimg.kitamura.jp/images/pd/b72/3de/b02/f4b/3c3/a84/535/a38/491/133/862/p5a/29d/3/M.jpg',
        // txt: '',
        //  size: 'L/2L 多窓 '
        //},
        {
          category: ['pf', 'all'],
          href: '/ec/special/camera/frame/467',
          name: 'ラドンナ ベビーフォトフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/72c/7cd/c3f/515/4bc/520/e9d/8e0/841/9f7/93p/f8y/xwr/o/M.jpg',
          txt: 'かわいいディテールのベビーフレーム',
          size: 'L/ハガキ/他 '
        },
        //{
        //  category: ['ppf', 'all'],
        //  href: '/ec/special/camera/frame/412',
        //  name: ' チクマ ニューパティーサム 多面 ',
        //  src: 'https://shopimg.kitamura.jp/images/pd/c9f/1db/260/379/90d/645/066/140/7bf/a9a/94i/v2t/luo/w/M.jpg',
        // txt: '',
        //  size: 'all'
        //},
        //{
        //  category: ['ppf', 'all'],
        //  href: '/ec/special/camera/frame/410',
        //  name: ' チクマ CマットPuz 紙製フォトフレーム ',
        //  src: 'https://shopimg.kitamura.jp/images/pd/cde/d0a/de7/bbf/525/9fe/069/0a9/a0d/2f3/846/mqe/7jc/b/M.jpg',
        // txt: '',
        //  size: '規格外'
        //},
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=KP%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0&narrow18=0',
          name: 'オリジナル フォトフレーム KPフレーム',
          src: 'https://shopimg.kitamura.jp/images/pd/c3a/baf/599/c55/1af/240/745/473/944/d0b/d79/87w/ev3/j/M.jpg',
          txt: 'シンプルで落ち着きのあるフレーム',
          size: 'L/はがき'
        },

        {
          category: ['ppf', 'all'],
          href: '/ec/special/camera/frame/2889',
          name: 'ナカバヤシ Vカットペーパースタンド 差込式',
          src: 'https://shopimg.kitamura.jp/images/pd/20f/af1/6bb/5f1/329/2b7/cf5/bd3/d8d/042/73i/rcx/7ls/p/M.jpg',
          txt: '出し入れに便利な差し込み式',
          size: 'L/はがき/A4/A3/2L'
        },
        {
          category: ['pf', 'all'],
          href: '/ec/list?keyword=%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%91%E3%83%BC%E3%83%86%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%B3%E5%9E%8B&narrow18=0',
          name: 'メタルパーテンション型',
          src: 'https://shopimg.kitamura.jp/images/pd/1b9/8da/880/8e1/e12/0a2/f69/014/c3c/d86/1cq/hmj/cm4/g/M.jpg',
          txt: 'センスあふれるスタイリッシュなフォトフレーム',
          size: 'L/他'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/frame/cork-frame',
          img: 'https://shopimg.kitamura.jp/images/banner/9237.jpg',
          alt: 'リサイクル素材で作ったフォトスタンド'
        },
        //{
        //  href: '/special/sale-fair/sale/lotbuying',
        //  img: 'https://shopimg.kitamura.jp/images/banner/3773.gif',
        //  alt: 'まとめ買いコーナー'
        //},
        {
          href: 'https://fremia.com/kitamura',
          img: 'https://shopimg.kitamura.jp/images/banner/1655.gif',
          alt: 'オーダーメイド額フレミア'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    const filterByCategory = computed(() => {
      const result = state.photoFrameList.filter((value) => {
        if (value.category.indexOf(state.category) !== -1) {
          return value;
        }
      });
      return result;
    });

    const searchByCategory = (value: string) => {
      state.category = value;
    };
    return {
      ...toRefs(state),
      filterByCategory,
      searchByCategory
    };
  }
});
