














































































































































































































































































































































































































































































import Vue from 'vue';
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'lensfilter',
  components: {
    breadcrumbs: Breadcrumbs,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    topTitleImg: TopTitleImg,
    tableOfContentsNavi: TableOfContentsNavi,
    orangeBtn: OrangeBtn,
    'product-list-by-special-id': ProductListBySpecialId,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'レンズフィルターのおすすめを紹介！種類別の選び方も解説します | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'ミラーレス一眼カメラやデジタル一眼レフの交換レンズにおすすめのレンズフィルターを紹介しています。使用する場面に応じたレンズフィルター(ND・PL・保護)の解説や選び方を記載しているので、フィルターごとの詳細な効果を知りたい方やうまく撮影ができない初心者の方もぜひ参考にしてください。'
      );
    const state = reactive({
      naviList: [
        { naviItem: 'NDフィルター・減光フィルター', href: '#ND' },
        { naviItem: '保護フィルター・プロテクター', href: '#protector' },
        { naviItem: 'PLフィルター・偏光フィルター', href: '#pl' },
        { naviItem: '季節のおすすめフィルター', href: '#season' }
      ],
      filterTypeList: [
        {
          type: 'プロテクター・保護フィルター',
          href:
            '/ec/list?narrow18=0&keyword=プロテクター・保護フィルター&category=交換レンズアクセサリー&index=&sort=number20,Number17,Score&searchbox=1&q=プロテクター・保護フィルター&path=交換レンズアクセサリー&n20c=完了商品は除く'
        },
        {
          type: '偏光・PLフィルター',
          href:
            '/ec/list?narrow18=0&keyword=%E5%81%8F%E5%85%89%E3%83%BBPL%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&category=&index=&searchbox=1&sort=number20,Number17,Score&q=%E5%81%8F%E5%85%89%E3%83%BBPL%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&path=&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F'
        },
        {
          type: '減光・NDフィルター',
          href:
            '/ec/list?narrow18=0&keyword=%EF%BC%AE%EF%BC%A4%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC&r=&index=&sort=number20,Number17,Score&searchbox=1&q=%EF%BC%AE%EF%BC%A4%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&path=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F'
        },
        {
          type: 'クロスフィルター',
          href:
            '/ec/list?narrow18=0&keyword=%E3%82%AF%E3%83%AD%E3%82%B9%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC&r=&index=&sort=number20,Number17,Score&searchbox=1&q=%E3%82%AF%E3%83%AD%E3%82%B9%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&path=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F'
        },
        {
          type: 'ソフトフィルター',
          href:
            '/ec/ct/C009/?category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC%3A%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC%3A%E3%82%BD%E3%83%95%E3%83%88%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity'
        },
        {
          type: '特殊効果用フィルター',
          href: '/ec/ct/C009/001/006/'
        },
        {
          type: 'クローズアップ',
          href:
            '/ec/list?narrow18=0&keyword=%E3%82%AF%E3%83%AD%E3%83%BC%E3%82%BA%E3%82%A2%E3%83%83%E3%83%97%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF&category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC&r=&index=&sort=number20,Number17,Score&searchbox=1&q=%E3%82%AF%E3%83%AD%E3%83%BC%E3%82%BA%E3%82%A2%E3%83%83%E3%83%97%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF&path=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F'
        }
        //{
        //  type: 'キタムラオリジナルフィルター',
        //  href: '/ec/special/camera/lensfilter/2638'
        //}
      ],
      FunctionalFliterList: [
        {
          type: '可変NDフィルター',
          href: '/ec/list?category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC:%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC:%EF%BC%AE%EF%BC%A4%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC&keyword=%E3%83%90%E3%83%AA%E3%82%A2%E3%83%96%E3%83%AB&narrow18=0&limit=100&page=1&sort=keyword'
        },
        {
          type: 'ND500でアートな風景写真',
          href: 'ec/special/camera/lensfilter/nd500'
        }
      ],
      seasonFliterList: [
        { type: '春★桜や花の撮影に', href: '#spring' },
        { type: '夏★NDで水の流れを表現', href: '#summer' },
        { type: '秋★PLで紅葉撮影', href: '#autumn' },
        { type: '冬★イルミネーション撮影', href: '#winter' }
      ],
      recommendedSeasonalFilterList: [
        {
          id: 'spring',
          subtitle: '春★桜や花の風景撮影におすすめフィルター',
          txt:
            'やわらかくファンタジックな雰囲気にできる「ソフトフィルター」、もうちょっと寄れる♪「クローズアップレンズ」、風景撮影におすすめの「PL(偏光)フィルター」',
          img2: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-spring.webp',
          img1: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-spring.jpg',
          href: '/ec/special/camera/lensfilter/1869?limit=100',
          linktxt: '≫ 春の写真撮影におすすめフィルターを見る'
        },
        {
          id: 'summer',
          subtitle: '夏★渓流や滝・水の流れを撮るのにおすすめND（減光）フィルター',
          txt:
            'ND（減光）フィルターで水の流れを撮る！ 風景写真「流れる夏色 魅惑の渓流・滝写真」。水表現の決め手はNDフィルターによるシャッタースピードコントロール',
          img2: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-summer.webp',
          img1: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-summer.jpg',
          href: '/ec/special/camera/lensfilter/1104',
          linktxt: '≫ 夏の写真撮影におすすめフィルターを見る'
        },
        {
          id: 'autumn',
          subtitle: '秋★紅葉撮影におすすめPL(偏光)フィルター',
          txt:
            'マルミ EXUS サーキュラーPLで紅葉を撮る！ 風景写真「秋色の光景 PLフィルターが引き出す紅葉の妙」。秋の表現の決め手はPLフィルター。写真家の思いを満たすEXUS',
          img2: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-autumn.webp',
          img1: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-autumn.jpg',
          href: '/ec/special/camera/lensfilter/exus',
          linktxt: '≫ 秋の写真撮影におすすめフィルターを見る'
        },
        {
          id: 'winter',
          subtitle: '冬★夜景やイルミネーション撮影におすすめクロス＆ソフトフィルター',
          txt:
            '光を強調するクロスフィルターは、4本線や6本線のものがあり、光のきらめきを強調した幻想的な写真が撮れます。クリスマスイルミネーション撮影におすすめ',
          img2: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-winter.webp',
          img1: 'https://shop.kitamura.jp/ec/images2/special/camera/lensfilter/img_season-winter.jpg',
          href: '/ec/special/camera/lensfilter/344',
          linktxt: '≫ 冬の写真撮影におすすめフィルターを見る'
        }
      ],
      convenientItemList: [
        { type: 'ステップアップリング', href: '/ec/ct/C009/010/?category=%E4%BA%A4%E6%8F%9B%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC:%E3%82%B9%E3%83%86%E3%83%83%E3%83%97%E3%82%A2%E3%83%83%E3%83%97%E3%83%AA%E3%83%B3%E3%82%B0&narrow18=0&limit=100&page=1&sort=popularity' },
        { type: 'ステップダウンリング', href: '/ec/ct/C009/011/' },
        { type: 'フィルターケース', href: '/ec/ct/C009/001/013/' }
      ],
      LensProtectionFilterList: [
        {
          name: 'マルミ EXUS レンズプロテクト SOLID',
          txt: '従来品の7倍の強度の強化ガラス使用！無色透明なレンズ保護フィルター',
          href: '/ec/list?keyword=LensProtect%20SOLID&narrow18=0',
          img: 'https://shopimg.kitamura.jp/images/pd/e2e/6e1/544/bf2/70a/481/459/214/236/911/adh/in7/5xw/3/L.jpg'
        },
        {
          name: 'マルミ DHG スーパーレンズプロテクト 「My Color Filter」',
          txt: 'レンズを守りながらカラーで遊べる保護フィルター',
          href: '/ec/list?keyword=My%20Color%20Filter&narrow18=0',
          img: 'https://shopimg.kitamura.jp/images/pd/503/42e/319/d91/581/228/9b8/871/f31/82a/6bx/dy9/hnq/x/L.jpg'
        },
        {
          name: 'ケンコー PRO1D ロータス II プロテクター 保護フィルター',
          txt: '水や油を強力に弾く撥水・撥油機能',
          href:
            '/ec/list?keyword=PRO1D%20%E3%83%AD%E3%83%BC%E3%82%BF%E3%82%B9%20II%20%E3%83%97%E3%83%AD%E3%83%86%E3%82%AF%E3%82%BF%E3%83%BC&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity',
          img: 'https://shopimg.kitamura.jp/images/pd/ff1/8d4/e7e/3bf/a91/694/9fe/6da/de4/70d/f11/3cb/fgl/r/L.jpg'
        },
        {
          name: 'ニコン ARCREST II\nPROTECTION FILTER',
          txt: '新たに帯電防止コート処理を前面に施した高性能保護フィルター',
          href:
            '/ec/list?keyword=ARCREST%20II%20PROTECTION%20FILTER&narrow18=0&n20c=%E5%AE%8C%E4%BA%86%E5%95%86%E5%93%81%E3%81%AF%E9%99%A4%E3%81%8F&limit=40&page=1&sort=popularity',
          img: 'https://shopimg.kitamura.jp/images/pd/cc0/598/b4f/609/cb7/c3b/9ab/a0b/1f9/439/d7t/l94/dzb/y/L.jpg'
        }
      ],
      productDetailtxt: [
        '＜安心・お手入れ保管4点セット＞カメラ・ムービーの保管と基本メンテナンスはこのセットで完璧！',
        '固く締まったフィルターを楽に取り外せる',
        '固く締まったフィルターを楽に取り外せる',
        '37mm口径のレンズに58mmフィルターを使用する変換用ステップアップリング',
        '58mm口径のレンズに67mmフィルターを使用する変換用ステップアップリング',
        '58mm口径のレンズに77mmフィルターを使用する変換用ステップアップリング',
        'ブロアーの定番中の定番品使いやすい手頃なサイズとこのクラス一番の強力なエアーがベストセラーの秘密',
        '季節を問わずデリケートなカメラ・レンズをカビから守ります',
        'クリーナー液30mlとクリーニングペーパー30枚のセットレンズや液晶画面の汚れをしっかり除去',
        '薄葉紙に特殊加工を施したレンズ清浄用のペーパー'
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/lensfilter/nikon-arcrest',
          img: 'https://shopimg.kitamura.jp/images/banner/4162.jpg',
          alt: 'ナノクリスタルコート採用のNIKKORレンズにおすすめ ARCREST 保護フィルター'
        },
        {
          href: '/ec/special/camera/lensfilter/nikon-arcrest-nd',
          img: 'https://shopimg.kitamura.jp/images/banner/1437.jpg',
          alt: 'カメラバッグトライアルサービス'
        },
        {
          href: '/ec/special/camera/lensfilter/nikon-arcrest-nd',
          img: 'https://shopimg.kitamura.jp/images/banner/7485.png',
          alt: 'ニコン ARCREST NDフィルター'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'https://shopimg.kitamura.jp/images/banner/8422.png',
          alt: 'メンテナンス'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'カメラレンズフィルターで写真がもっと楽しくなる！種類・選び方・効果',
          disabled: true
        }
      ],
      productJanCodeList: ['4977187329704'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
