import { render, staticRenderFns } from "./wrapping-gift.vue?vue&type=template&id=5d482abc&scoped=true&"
import script from "./wrapping-gift.vue?vue&type=script&lang=ts&"
export * from "./wrapping-gift.vue?vue&type=script&lang=ts&"
import style0 from "./wrapping-gift.vue?vue&type=style&index=0&id=5d482abc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d482abc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VApp,VCard,VImg})
