













































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import SubText from '@/components/common/special/subtext.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
//import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ShootingInformation from '@/components/common/special/shootingInformation.vue';
export default Vue.extend({
  name: 'birdcamera',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    subText: SubText,
    priceAndPurchase: PriceAndPurchase,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    tableOfContentsNavi: TableOfContentsNavi,
    //watchInVideo: WatchInVideo,
    shootingInformation: ShootingInformation,
    notfound: Notfound,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '【野鳥好き必見】野鳥撮影がもっと楽しめるカメラはこれだ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '野鳥撮影を始めたい方やスキルアップを目指す方に！撮影の基本から、野鳥を鮮明に捉えるためのおすすめ機材まで徹底解説。自然の中での新しい楽しみ方を見つけましょう'
      );
    const state = reactive({
      naviList: [
        { naviItem: '野鳥撮影におすすめのメーカーは？', href: '#r00' },
        { naviItem: '野鳥撮影に最もおすすめのカメラ', href: '#r01' },
        { naviItem: '野鳥撮影におすすめのレンズ', href: '#r02' },
        { naviItem: '野鳥撮影マナーについて', href: '#r03' },
        { naviItem: 'まとめ', href: '#r04' },
        { naviItem: 'ShaSha：野鳥撮影の記事一覧', href: '#r05' }
        //{ naviItem: '野鳥撮影におすすめ商品一覧', href: '#r06' }
      ],
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- 1 期間 ----
      fanValidFrom: '2024/12/16 10:00',
      fanValidTo: '2999/04/10 00:00',
      // ↓ ---- 2 期間 ----
      reserveValidFrom: '2024/12/16 10:00',
      reserveValidTo: '2999/04/10 00:00',
      // ↓ ---- 3 期間 ----
      releaseValidFrom: '2024/12/16 10:00',
      releaseValidTo: '2999/04/10 00:00',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',
      model: null,
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/operaglass',
          img: 'https://shopimg.kitamura.jp/images/banner/2462.gif',
          alt: '双眼鏡の選び方♪双眼鏡で、もっと楽しく♪'
        },
        {
          href: '/ec/special/camera/bird',
          img: 'https://shopimg.kitamura.jp/images/banner/9793.jpg',
          alt: '野鳥撮影の魅力'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '野鳥撮影の魅力やイチオシ機材をご紹介',
          linkUrl: '/ec/special/camera/bird',
          disabled: false
        },
        {
          path: '野鳥撮影がもっと楽しめるカメラはこれ',
          disabled: true
        }
      ],
      mainProductJanCode: ['4545350049423', '4545350056056', '4545350054298', '4545350053055', '4545350056070', '4545350054175'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549980624180'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['2500000515361'],
      productDetailList: [] as Array<ProductDetail>, // 結果格納用 ランキング1
      productJanCodeList2: ['2500000514548'],
      productDetailList2: [] as Array<ProductDetail>, // 結果格納用 ランキング2
      productJanCodeList3: ['2500000517525'],
      productDetailList3: [] as Array<ProductDetail>, // 結果格納用 ランキング3
      productJanCodeList4: ['4549292181661'],
      productDetailList4: [] as Array<ProductDetail>, // 結果格納用 ランキング4
      productJanCodeList5: ['4549292181661'],
      productDetailList5: [] as Array<ProductDetail> // 結果格納用 ランキング5
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
        const productListResult3 = await ProductService.fetchProducts(state.productJanCodeList3, true);
        state.productDetailList3 = productListResult3.items;
        const productListResult4 = await ProductService.fetchProducts(state.productJanCodeList4, true);
        state.productDetailList4 = productListResult4.items;
        const productListResult5 = await ProductService.fetchProducts(state.productJanCodeList5, true);
        state.productDetailList5 = productListResult5.items;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
        state.productDetailList2 = [] as Array<ProductDetail>;
        state.productDetailList3 = [] as Array<ProductDetail>;
        state.productDetailList4 = [] as Array<ProductDetail>;
        state.productDetailList5 = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
