















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice, isDebugMode, validationPeriod } from '@/logic/utils';
import ProductService from '@/logic/product.service';
//import SubText from '@/components/common/special/subtext.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import SpecialProductService from '@/logic/special-product.service';
import dayjs from 'dayjs';
import Notfound from '@/components/common/not-found.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
//import ShaSha from '@/components/common/special/shasha.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
export default Vue.extend({
  name: 'bird',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    //subText: SubText,
    //shasha: ShaSha,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    tableOfContentsNavi: TableOfContentsNavi,
    notfound: Notfound,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    const query = context.root.$route.query;
    document.title = '【野鳥撮影とは】魅力や必要な機材を特徴やメリットを交えてご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '一瞬の美を捉える、野鳥撮影の世界へようこそ！その魅力や必要な道具、撮影のポイントをまとめた完全ガイドを公開中。大自然でのひとときを、最高の写真に残しましょう'
      );
    const state = reactive({
      // ↓ ---- 画面自体の表示制御 ----
      isShow: false,
      // ↓ ---- 1 期間 ----
      fanValidFrom: '2024/11/22 10:00',
      fanValidTo: '2999/04/10 00:00',
      // ↓ ---- 2 期間 ----
      reserveValidFrom: '2024/12/22 10:00',
      reserveValidTo: '2999/04/10 00:00',
      // ↓ ---- 3 期間 ----
      releaseValidFrom: '2024/12/22 10:00',
      releaseValidTo: '2999/04/10 00:00',
      // ↓ ---- プレコンテンツを表示するかどうか ----
      isShowFan: false,
      // ↓ ---- 予約コンテンツを表示するかどうか ----
      isShowReserve: false,
      // ↓ ---- 発売コンテンツを表示するかどうか ----
      isShowRelease: false,
      // 表示期間外メッセージ
      errorMsg: '',
      model: null,
      naviList: [
        { naviItem: '野鳥撮影に必要な機材', href: '#r00' },
        { naviItem: '野鳥撮影の撮影テクニック', href: '#r01' },
        { naviItem: 'まとめ', href: '#r02' },
        { naviItem: 'ShaSha：野鳥撮影の記事一覧', href: '#r03' },
        { naviItem: '商品一覧', href: '#r04' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/operaglass',
          img: 'https://shopimg.kitamura.jp/images/banner/2462.gif',
          alt: '双眼鏡の選び方♪双眼鏡で、もっと楽しく♪'
        },
        {
          href: '/ec/special/camera/bird/camera',
          img: 'https://shopimg.kitamura.jp/images/banner/9792.jpg',
          alt: '野鳥好き必見'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '野鳥撮影の魅力やイチオシ機材をご紹介',
          disabled: true
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4545350056056'],
      productDetailList: [] as Array<ProductDetail>, // カメラ
      productJanCodeList2: ['4545350056070'],
      productDetailList2: [] as Array<ProductDetail>, // レンズ1
      productJanCodeList3: ['4545350053055'],
      productDetailList3: [] as Array<ProductDetail>, // レンズ2
      productJanCodeList4: ['4545350049423'],
      productDetailList4: [] as Array<ProductDetail>, // レンズ3
      productJanCodeList5: ['4545350054298'],
      productDetailList5: [] as Array<ProductDetail>, // レンズ4
      productJanCodeList6: ['4545350054175'],
      productDetailList6: [] as Array<ProductDetail>, // レンズ5
      productJanCodeTel: ['4545350047733'],
      productDetailTel: [] as Array<ProductDetail>, // テレコン1
      productJanCodeTel2: ['4545350052645'],
      productDetailTel2: [] as Array<ProductDetail>, // テレコン2
      productJanCodeSan: ['4595989135134'],
      productDetailSan: [] as Array<ProductDetail>, // 三脚1
      productJanCodeSan2: ['8024221640472'],
      productDetailSan2: [] as Array<ProductDetail>, // 三脚2
      productJanCodeSan3: ['4907990500038'],
      productDetailSan3: [] as Array<ProductDetail>, // 三脚3
      productJanCodeSan4: ['4906752109366'],
      productDetailSan4: [] as Array<ProductDetail>, // 三脚4
      productJanCodeUnn: ['4595989135226'],
      productDetailUnn: [] as Array<ProductDetail>, // 雲台1
      productJanCodeUnn2: ['8024221668346'],
      productDetailUnn2: [] as Array<ProductDetail>, // 雲台2
      productJanCodeUnn3: ['4907990501684'],
      productDetailUnn3: [] as Array<ProductDetail>, // 雲台3
      productJanCodeDot: ['4545350054847'],
      productDetailDot: [] as Array<ProductDetail>, // ドット1
      productJanCodeDot2: ['4960759149282'],
      productDetailDot2: [] as Array<ProductDetail>, // ドット2
      productJanCodeDot3: ['4595989135400'],
      productDetailDot3: [] as Array<ProductDetail>, // ドット3
      productJanCodeBag: ['4545350051082'],
      productDetailBag: [] as Array<ProductDetail>, // バッグ1
      productJanCodeBag2: ['4545350045814'],
      productDetailBag2: [] as Array<ProductDetail>, // バッグ2
      productJanCodeBag3: ['4595989135165'],
      productDetailBag3: [] as Array<ProductDetail>, // バッグ3
      productJanCodeBag4: ['4961607705025'],
      productDetailBag4: [] as Array<ProductDetail>, // バッグ4
      productJanCodeGr: ['4545350052522'],
      productDetailGr: [] as Array<ProductDetail>, // 双眼鏡
      productJanCodeGr2: ['4545350038298'],
      productDetailGr2: [] as Array<ProductDetail>, // 双眼鏡2
      productJanCodeGr3: ['4595989135219'],
      productDetailGr3: [] as Array<ProductDetail>, // 双眼鏡3
      productJanCodeGr4: ['4047865000512'],
      productDetailGr4: [] as Array<ProductDetail>, // 双眼鏡4
      productJanCodeGr5: ['4955295115167'],
      productDetailGr5: [] as Array<ProductDetail>, // 双眼鏡5
      productJanCodeGr6: ['9006325088168'],
      productDetailGr6: [] as Array<ProductDetail>, // 双眼鏡6
      productJanCodeGr7: ['4580130921650'],
      productDetailGr7: [] as Array<ProductDetail>, // 双眼鏡7
      productJanCodeAc: ['4961607076255'],
      productDetailAc: [] as Array<ProductDetail>, // アクセ
      productJanCodeAc2: ['4595989135073'],
      productDetailAc2: [] as Array<ProductDetail>, // アクセ2
      productJanCodeAc3: ['4595989135257'],
      productDetailAc3: [] as Array<ProductDetail>, // アクセ3
      productJanCodeAc4: ['4907990301536'],
      productDetailAc4: [] as Array<ProductDetail>, // アクセ4
      // ↑ ---- 価格・人気アクセサリー ----
      mainProductJanCode: ['4549292181661'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292181661'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>
      // ↑ ---- 比較 ----
    });
    /**
     * 今日の日付が広告掲載期間よりも前かチェックする
     */
    const checkBeforePreperiod = async () => {
      const fanValidFrom = dayjs(state.fanValidFrom);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isBefore(fanValidFrom)) {
          state.errorMsg = '掲載準備中\n大変ご迷惑おかけしますが開始時間までお待ちください。';
        }
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 今日の日付が発売掲載期間よりも後かチェックする
     */
    const checkAfterReleaseperiod = async () => {
      const releaseValidTo = dayjs(state.releaseValidTo);
      try {
        const response = await SpecialProductService.getHealthCheck();
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const formatToday = dayjs(today);
        if (formatToday.isAfter(releaseValidTo)) {
          state.errorMsg = '該当ページは掲載期間が終了いたしました。';
        }
      } catch (error) {
        console.error(error);
      }
    };
    /**
     * 表示期間制御
     * @param from from日付
     * @param to to日付
     */
    const verifyDate = async () => {
      let today = '';
      try {
        const response = await SpecialProductService.getHealthCheck();
        today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        if (validationPeriod(today, state.fanValidFrom, state.fanValidTo)) {
          state.isShowFan = true;
        }
        if (validationPeriod(today, state.reserveValidFrom, state.reserveValidTo)) {
          state.isShowReserve = true;
        }
        if (validationPeriod(today, state.releaseValidFrom, state.releaseValidTo)) {
          state.isShowRelease = true;
        }
      } catch (error) {
        console.error(error);
        today = dayjs().toString();
      }
    };
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
        const productListResult3 = await ProductService.fetchProducts(state.productJanCodeList3, true);
        state.productDetailList3 = productListResult3.items;
        const productListResult4 = await ProductService.fetchProducts(state.productJanCodeList4, true);
        state.productDetailList4 = productListResult4.items;
        const productListResult5 = await ProductService.fetchProducts(state.productJanCodeList5, true);
        state.productDetailList5 = productListResult5.items;
        const productListResult6 = await ProductService.fetchProducts(state.productJanCodeList6, true);
        state.productDetailList6 = productListResult6.items;
        const productListTel = await ProductService.fetchProducts(state.productJanCodeTel, true);
        state.productDetailTel = productListTel.items;
        const productListTel2 = await ProductService.fetchProducts(state.productJanCodeTel2, true);
        state.productDetailTel2 = productListTel2.items;
        const productListSan = await ProductService.fetchProducts(state.productJanCodeSan, true);
        state.productDetailSan = productListSan.items;
        const productListSan2 = await ProductService.fetchProducts(state.productJanCodeSan2, true);
        state.productDetailSan2 = productListSan2.items;
        const productListSan3 = await ProductService.fetchProducts(state.productJanCodeSan3, true);
        state.productDetailSan3 = productListSan3.items;
        const productListSan4 = await ProductService.fetchProducts(state.productJanCodeSan4, true);
        state.productDetailSan4 = productListSan4.items;
        const productListUnn = await ProductService.fetchProducts(state.productJanCodeUnn, true);
        state.productDetailUnn = productListUnn.items;
        const productListUnn2 = await ProductService.fetchProducts(state.productJanCodeUnn2, true);
        state.productDetailUnn2 = productListUnn2.items;
        const productListUnn3 = await ProductService.fetchProducts(state.productJanCodeUnn3, true);
        state.productDetailUnn3 = productListUnn3.items;
        const productListDot = await ProductService.fetchProducts(state.productJanCodeDot, true);
        state.productDetailDot = productListDot.items;
        const productListDot2 = await ProductService.fetchProducts(state.productJanCodeDot2, true);
        state.productDetailDot2 = productListDot2.items;
        const productListDot3 = await ProductService.fetchProducts(state.productJanCodeDot3, true);
        state.productDetailDot3 = productListDot3.items;
        const productListBag = await ProductService.fetchProducts(state.productJanCodeBag, true);
        state.productDetailBag = productListBag.items;
        const productListBag2 = await ProductService.fetchProducts(state.productJanCodeBag2, true);
        state.productDetailBag2 = productListBag2.items;
        const productListBag3 = await ProductService.fetchProducts(state.productJanCodeBag3, true);
        state.productDetailBag3 = productListBag3.items;
        const productListBag4 = await ProductService.fetchProducts(state.productJanCodeBag4, true);
        state.productDetailBag4 = productListBag4.items;
        const productListGr = await ProductService.fetchProducts(state.productJanCodeGr, true);
        state.productDetailGr = productListGr.items;
        const productListGr2 = await ProductService.fetchProducts(state.productJanCodeGr2, true);
        state.productDetailGr2 = productListGr2.items;
        const productListGr3 = await ProductService.fetchProducts(state.productJanCodeGr3, true);
        state.productDetailGr3 = productListGr3.items;
        const productListGr4 = await ProductService.fetchProducts(state.productJanCodeGr4, true);
        state.productDetailGr4 = productListGr4.items;
        const productListGr5 = await ProductService.fetchProducts(state.productJanCodeGr5, true);
        state.productDetailGr5 = productListGr5.items;
        const productListGr6 = await ProductService.fetchProducts(state.productJanCodeGr6, true);
        state.productDetailGr6 = productListGr6.items;
        const productListGr7 = await ProductService.fetchProducts(state.productJanCodeGr7, true);
        state.productDetailGr7 = productListGr7.items;
        const productListAc = await ProductService.fetchProducts(state.productJanCodeAc, true);
        state.productDetailAc = productListAc.items;
        const productListAc2 = await ProductService.fetchProducts(state.productJanCodeAc2, true);
        state.productDetailAc2 = productListAc2.items;
        const productListAc3 = await ProductService.fetchProducts(state.productJanCodeAc3, true);
        state.productDetailAc3 = productListAc3.items;
        const productListAc4 = await ProductService.fetchProducts(state.productJanCodeAc4, true);
        state.productDetailAc4 = productListAc4.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
        state.productDetailList2 = [] as Array<ProductDetail>;
        state.productDetailList3 = [] as Array<ProductDetail>;
        state.productDetailList4 = [] as Array<ProductDetail>;
        state.productDetailList5 = [] as Array<ProductDetail>;
        state.productDetailList6 = [] as Array<ProductDetail>;
        state.productDetailTel = [] as Array<ProductDetail>;
        state.productDetailTel2 = [] as Array<ProductDetail>;
        state.productDetailSan = [] as Array<ProductDetail>;
        state.productDetailSan2 = [] as Array<ProductDetail>;
        state.productDetailSan3 = [] as Array<ProductDetail>;
        state.productDetailUnn = [] as Array<ProductDetail>;
        state.productDetailUnn2 = [] as Array<ProductDetail>;
        state.productDetailUnn3 = [] as Array<ProductDetail>;
        state.productDetailDot = [] as Array<ProductDetail>;
        state.productDetailDot2 = [] as Array<ProductDetail>;
        state.productDetailDot3 = [] as Array<ProductDetail>;
        state.productDetailBag = [] as Array<ProductDetail>;
        state.productDetailBag2 = [] as Array<ProductDetail>;
        state.productDetailBag3 = [] as Array<ProductDetail>;
        state.productDetailBag4 = [] as Array<ProductDetail>;
        state.productDetailGr = [] as Array<ProductDetail>;
        state.productDetailGr2 = [] as Array<ProductDetail>;
        state.productDetailGr3 = [] as Array<ProductDetail>;
        state.productDetailGr4 = [] as Array<ProductDetail>;
        state.productDetailGr5 = [] as Array<ProductDetail>;
        state.productDetailGr6 = [] as Array<ProductDetail>;
        state.productDetailGr7 = [] as Array<ProductDetail>;
        state.productDetailAc = [] as Array<ProductDetail>;
        state.productDetailAc2 = [] as Array<ProductDetail>;
        state.productDetailAc3 = [] as Array<ProductDetail>;
        state.productDetailAc4 = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      await checkBeforePreperiod();
      if (!state.errorMsg) await checkAfterReleaseperiod();
      if (!state.errorMsg) {
        await verifyDate();
        await fetchProduct();
      }
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
