







































































import Vue from 'vue';
import { computed, reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
export default Vue.extend({
  name: 'sale-fair',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: () => {
    document.title = 'セール・特集 | カメラのキタムラネットショップ';
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'セール・特集・HOW TOなど、お得な情報やちょっと為になる情報ご紹介');
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          disabled: true
        }
      ],
      category: 'all',
      narrowingList: [
        { src: '/ec/images2/special/sale-fair/btn-new-w.png', value: 'all' },
        { src: '/ec/images2/special/sale-fair/btn-sale-w.png', value: 'sale' },
        { src: '/ec/images2/special/sale-fair/btn-camera-w.png', value: 'camera' },
        { src: '/ec/images2/special/sale-fair/btn-kaden-w.png', value: 'kaden' },
        { src: '/ec/images2/special/sale-fair/btn-print-w.png', value: 'print' },
        { src: '/ec/images2/special/sale-fair/btn-general-w.png', value: 'general' }
      ],
      campaignlist: [
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/feature',
          src: 'https://shopimg.kitamura.jp/images/banner/1341.jpg',
          productName: '最新デジカメ新製品'
        },
        //{
        //  category: ['all', 'general'],
        //  href: '/ec/special/general/prepare',
        //  src: 'https://shopimg.kitamura.jp/images/banner/7764.jpg',
        //  productName: '防災グッズ特集\nもしもの備えにおすすめ防災グッズ集めました'
        // },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/travel',
          src: 'https://shopimg.kitamura.jp/images/banner/7722.png',
          productName: '旅のお供に！バイヤー厳選\nおすすめ 旅カメラ'
        },
        {
          category: ['all', 'camera', 'kaden', 'general'],
          href: 'https://shop.kitamura.jp/special/sale-fair/sale/outlet/1109/',
          src: 'https://shopimg.kitamura.jp/images/banner/3810.gif',
          productName: 'カメラ・家電アウトレット'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/waterproof',
          src: 'https://shopimg.kitamura.jp/images/banner/1220.jpg',
          productName: '防水カメラ・ウェアラブルカメラ特集'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/lensfilter',
          src: 'https://shopimg.kitamura.jp/images/banner/1342.jpg',
          productName: 'カメラレンズフィルターで写真がもっと楽しくなる！種類・選び方・効果'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/air-purifier_fair',
          src: 'https://shopimg.kitamura.jp/images/banner/2393.gif',
          productName: '花粉対策特集 | おすすめ空気清浄機'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/coolshot',
          src: 'https://shopimg.kitamura.jp/images/banner/3576.png',
          productName: 'COOLSHOT特集'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/fan-fair',
          src: 'https://shopimg.kitamura.jp/images/banner/6597.jpg',
          productName: 'おすすめ扇風機特集'
        },
        {
          category: ['all', 'general'],
          href: '/ec/special/general/how-to/hanabi',
          src: 'https://shopimg.kitamura.jp/images/banner/3043.jpg',
          productName: '花火撮影特集｜打ち上げ花火の撮影のコツ＆撮影に便利な商品紹介'
        },
        {
          category: ['all', 'camera', 'general'],
          href: '/ec/special/general/sportsday',
          src: 'https://shopimg.kitamura.jp/images/banner/2006.gif',
          productName: 'おすすめカメラ・撮影のコツ・写真の残し方まで★パパ・ママ応援！運動会特集'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/lensfilter/1869',
          src: 'https://shopimg.kitamura.jp/images/banner/2746.jpg',
          productName: '春のおすすめフィルター'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/bento/',
          src: 'https://shopimg.kitamura.jp/images/banner/3807.jpg',
          productName: 'お弁当生活におすすめ商品\nキッチン商品大集合！'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/bottle',
          src: 'https://shopimg.kitamura.jp/images/banner/1921.jpg',
          productName: 'おすすめの水筒・ステンレスボトルを集めました'
        },
        {
          category: ['all', 'camera', 'general'],
          href: 'https://shasha.kitamura.jp/',
          src: 'https://shopimg.kitamura.jp/images/banner/8235.png',
          productName: 'ShaSha:写真がもっと好きになる',
          target: 'blank'
        },
        {
          category: ['all', 'general'],
          href: '/ec/special/general/summerhomework',
          src: 'https://shopimg.kitamura.jp/images/banner/1309.gif',
          productName: 'カメラ専門店のスタッフがおすすめする、夏休み 自由研究特集'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/photomount',
          src: 'https://shopimg.kitamura.jp/images/banner/3611.gif',
          productName: '写真台紙 おすすめシリーズ紹介'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/frame',
          src: 'https://shopimg.kitamura.jp/images/banner/2780.gif',
          productName: '額縁・写真フォトフレーム おすすめシリーズ紹介'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/album',
          src: 'https://shopimg.kitamura.jp/images/banner/2456.gif',
          productName: '写真アルバム おすすめシリーズ紹介'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/storage',
          src: 'https://shopimg.kitamura.jp/images/banner/2043.jpg',
          productName: '防湿庫の選び方・湿気対策特集'
        },
        {
          category: ['all', 'general'],
          href: '/ec/special/general/tuyutaisaku',
          src: 'https://shopimg.kitamura.jp/images/banner/5422.png',
          productName: '梅雨対策特集\n便利なアイテム集めました'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/bag',
          src: 'https://shopimg.kitamura.jp/images/banner/1155.gif',
          productName: 'カメラバッグ・カメラケース特集★一眼レフ/ミラーレス/デジカメ用'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/printer',
          src: 'https://shopimg.kitamura.jp/images/banner/1615.gif',
          productName: 'おすすめプリンター特集！'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/photoprinter',
          src: 'https://shopimg.kitamura.jp/images/banner/9718.png',
          productName: 'おすすめフォトプリンター特集'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/sp-accessory/',
          src: 'https://shopimg.kitamura.jp/images/banner/2314.png',
          productName: 'iPhoneアクセサリー・ケース'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/dslr',
          src: 'https://shopimg.kitamura.jp/images/banner/1350.gif',
          productName: 'おすすめのデジタル一眼レフ/ミラーレスカメラ特集'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/compact',
          src: 'https://shopimg.kitamura.jp/images/banner/1986.jpg',
          productName: 'おすすめデジカメ/デジタルカメラ特集'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/rice-cooker_bestbuy',
          src: 'https://shopimg.kitamura.jp/images/banner/2401.jpg',
          productName: 'おすすめ炊飯器特集'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/ricemill',
          src: 'https://shopimg.kitamura.jp/images/banner/5814.jpg',
          productName: 'おすすめ精米機特集'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/heater-best',
          src: 'https://shopimg.kitamura.jp/images/banner/2374.jpg',
          productName: '最新おすすめ暖房ストア'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/girlscamera',
          src: 'https://shopimg.kitamura.jp/images/banner/1390.jpg',
          productName: '女子がきゅんとくる ミラーレス一眼カメラ'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/lensfilter/344',
          src: 'https://shopimg.kitamura.jp/images/banner/2449.jpg',
          productName: 'クロスフィルター特集'
        },
        {
          category: ['all', 'print'],
          href: 'https://www.kitamura-print.com/',
          src: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          productName: '超高画質プリント',
          target: 'blank'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/operaglass',
          src: 'https://shopimg.kitamura.jp/images/banner/2462.gif',
          productName: '双眼鏡の選び方♪双眼鏡で、もっと楽しく♪'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/bird',
          src: 'https://shopimg.kitamura.jp/images/banner/9793.jpg',
          productName: '野鳥撮影の魅力やイチオシ機材をご紹介'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/bird/camera',
          src: 'https://shopimg.kitamura.jp/images/banner/9792.jpg',
          productName: '野鳥撮影がもっと楽しめるカメラ'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/kaden/sixpad',
          src: 'https://shopimg.kitamura.jp/images/banner/9796.jpg',
          productName: 'フィットネス・トレーニングアイテム SIXPAD'
        },
        {
          category: ['all', 'camera', 'general'],
          href: '/ec/special/camera/bag/feature/trekking',
          src: 'https://shopimg.kitamura.jp/images/banner/2932.gif',
          productName: '休日はフォトトレッキングに行こう！\nカメラバック・カメラ用品のご紹介'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/lensfilter/nikon-arcrest',
          src: 'https://shopimg.kitamura.jp/images/banner/4162.jpg',
          productName: 'ナノクリスタルコート採用のNIKKORレンズにおすすめ保護フィルター'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/lensfilter/nikon-arcrest-nd',
          src: 'https://shopimg.kitamura.jp/images/banner/7485.png',
          productName: 'ARCRESTシリーズからND(減光)フィルターが登場！ニコンARCREST NDフィルター'
        },
        {
          category: ['all', 'camera', 'general'],
          href: '/ec/special/camera/mirrorless-oldlens',
          src: 'https://shopimg.kitamura.jp/images/banner/4116.jpg',
          productName: 'レトロなのに新しい！ミラーレス一眼とオールドレンズで撮影しよう'
        },
        {
          category: ['all', 'camera', 'general'],
          href: '/ec/special/camera/filmcamera/utsurundesu',
          src: 'https://shopimg.kitamura.jp/images/banner/4115.gif',
          productName: '写ルンですの使い方・楽しみ方特集'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/strap',
          src: 'https://shopimg.kitamura.jp/images/banner/1392.gif',
          productName: 'おしゃれなストラップ特集'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/bag/trial',
          src: 'https://shopimg.kitamura.jp/images/banner/1437.jpg',
          productName: 'カメラバッグトライアルサービス'
        },
        {
          category: ['all', 'camera', 'general'],
          href: '/ec/special/camera/astronomical',
          src: 'https://shopimg.kitamura.jp/images/banner/1327.gif',
          productName: '天体イベント スケジュール'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/astronomical/moonlook',
          src: 'https://shopimg.kitamura.jp/images/banner/1306.jpg',
          productName: '天体観測入門★月を見よう'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/astronomical/telescope',
          src: 'https://shopimg.kitamura.jp/images/banner/1698.jpg',
          productName: '初心者におすすめ 天体望遠鏡の選び方'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/astronomical/telescope-feature',
          src: 'https://shopimg.kitamura.jp/images/banner/2381.jpg',
          productName: '本格的な天体観測・天体撮影におすすめの赤道儀シリーズ'
        },
        {
          category: ['all', 'camera', 'general'],
          href: '/ec/special/camera/polarie',
          src: 'https://shopimg.kitamura.jp/images/banner/1488.gif',
          productName: '星空を撮影しよう！星空撮影入門特集'
        },
        {
          category: ['all', 'camera', 'general'],
          href: '/ec/special/camera/polarie/acc',
          src: 'https://shopimg.kitamura.jp/images/banner/3588.gif',
          productName: 'ポラリエとカメラ用望遠レンズで星空・天体撮影'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/general/wirelessimageshare',
          src: 'https://shopimg.kitamura.jp/images/banner/2785.gif',
          productName: 'デジカメとスマホを無線でつないで写真を楽しもう'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/tripod',
          src: 'https://shopimg.kitamura.jp/images/banner/3253.gif',
          productName: 'おすすめ三脚の選び方'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/home-party/',
          src: 'https://shopimg.kitamura.jp/images/banner/3770.gif',
          productName: '誕生日会、パーティに☆使って楽しい\nキッチン家電特集'
        },
        {
          category: ['all', 'general'],
          href: '/ec/special/general/tokutokukoukan',
          src: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          productName: '差額分だけのお支払い｢トクトク交換｣の特徴'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/reuse/watch',
          src: 'https://shopimg.kitamura.jp/images/banner/9216.jpg',
          productName: '中古時計販売'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/reuse/smartphone',
          src: 'https://shopimg.kitamura.jp/images/banner/9217.jpg',
          productName: '中古スマホ販売'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/reuse/usedpc/',
          src: 'https://shopimg.kitamura.jp/images/banner/9653.jpg',
          productName: '中古パソコン販売｜おすすめ中古パソコン特集'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/reuse/rpc-usedpc/',
          src: 'https://shopimg.kitamura.jp/images/banner/9567.jpg',
          productName: '中古パソコン販売｜無期限保証付帯のパソコン「R∞PC」'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/watch/rolex/',
          src: 'https://shopimg.kitamura.jp/images/banner/9290.jpg',
          productName: 'ロレックス ROLEX（中古）販売'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/watch/omega/',
          src: 'https://shopimg.kitamura.jp/images/banner/9292.jpg',
          productName: 'オメガ OMEGA（中古）販売'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/general/watch/omega',
          src: 'https://shopimg.kitamura.jp/images/banner/1459.jpg',
          productName: 'オメガ(OMEGA) 時計特集'
        },
        {
          category: ['all', 'general'],
          href: '/ec/special/page/paypay',
          src: 'https://shopimg.kitamura.jp/images/banner/9277.png',
          productName: 'PayPayでお支払い可能'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/frame/shacolla',
          src: 'https://shopimg.kitamura.jp/images/banner/3099.gif',
          productName: 'フジフイルム シャコラで写真を壁に飾ろう！'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/filmcamera',
          src: 'https://shopimg.kitamura.jp/images/banner/3771.gif',
          productName: 'チェキ・チェキフィルム、チェキアルバムおすすめ商品'
        },
        {
          category: ['all', 'general'],
          href: '/ec/special/general/pet',
          src: 'https://shopimg.kitamura.jp/images/banner/1878.jpg',
          productName: 'ペット写真のステキな残し方'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/general/graduation_entrance',
          src: 'https://shopimg.kitamura.jp/images/banner/1929.gif',
          productName: '卒業・入学特集！おすすめのビデオカメラ・カメラ大集合！'
        },
        {
          category: ['all', 'camera', 'general'],
          href: 'https://shop.kitamura.jp/special/sale-fair/camera/album/feature/',
          src: 'https://shopimg.kitamura.jp/images/banner/3597.jpg',
          productName: '手作りアルバムの作り方特集'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/ec/special/news?news=12820&bn=9305',
          src: 'https://shopimg.kitamura.jp/images/banner/2074.jpg',
          productName: 'ボディケア レディースシェーバー特集'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/air-purifier_fair/daikin',
          src: 'https://shopimg.kitamura.jp/images/banner/4130.gif',
          productName: '夏のお悩み空気清浄機で解決！'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/camera/lens',
          src: 'https://shopimg.kitamura.jp/images/banner/3016.jpg',
          productName: 'カメラレンズ人気ランキング'
        },
        {
          category: ['all', 'sale'],
          href: 'https://shop.kitamura.jp/special/sale-fair/sale/lotbuying/',
          src: 'https://shopimg.kitamura.jp/images/banner/3773.gif',
          productName: 'まとめ買いコーナー'
        },
        {
          category: ['all', 'general'],
          href: '/ec/special/general/story_after_taking',
          src: 'https://shopimg.kitamura.jp/images/banner/2340.gif',
          productName: '｢写真撮ったあと物語｣_写真整理・贈り方・飾り方に困っている3人の物語'
        },
        {
          category: ['all', 'camera', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/smart_phone/sim_free/',
          src: 'https://shopimg.kitamura.jp/images/banner/3108.gif',
          productName: 'おすすめSIMフリースマホ特集'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/smart_phone/iphone/',
          src: 'https://shopimg.kitamura.jp/images/banner/7562.jpg',
          productName: 'iPhone 13/ iPhone 13 Pro スペック比較'
        },
        {
          category: ['all', 'camera'],
          href: '/ec/special/general/highly_recommended',
          src: 'https://shopimg.kitamura.jp/images/banner/6603.jpg',
          productName: 'スタッフイチオシ'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/airpurifier/aircleanercompare',
          src: 'https://shopimg.kitamura.jp/images/banner/3426.png',
          productName: 'ここが違う！おすすめ加湿空気清浄機。シャープ・ダイキン製品を比較！'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/toaster-oven',
          src: 'https://shopimg.kitamura.jp/images/banner/6374.jpg',
          productName: 'おすすめ高級トースター特集'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/innerscandual/',
          src: 'https://shopimg.kitamura.jp/images/banner/2099.gif',
          productName: 'タニタ 体組成計特集 -ダイエットや体力作りにおすすめの体組成計'
        },
        // {
        //  category: ['all', 'camera'],
        //  href: '/ec/special/camera/album/original/2052',
        //  src: 'https://shopimg.kitamura.jp/images/banner/2741.gif',
        //  productName: 'キタムラオリジナルベビーアルバム「はじめてがいっぱい」'
        // },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/grandx/',
          src: 'https://shopimg.kitamura.jp/images/banner/3544.gif',
          productName: 'タイガーGRAND X(グランエックス)シリーズ'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/glamping/',
          src: 'https://shopimg.kitamura.jp/images/banner/3045.gif',
          productName: 'ベランダグランピング特集'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/heyabosi',
          src: 'https://shopimg.kitamura.jp/images/banner/4005.jpg',
          productName: 'おすすめ除湿機特集'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/general/stationery/mannenhitsu/how_to/',
          src: 'https://shopimg.kitamura.jp/images/banner/2479.gif',
          productName: '万年筆の選び方'
        },
        {
          category: ['all', 'kaden'],
          href: '/ec/special/kaden/fall-of-taste',
          src: 'https://shopimg.kitamura.jp/images/banner/2287.gif',
          productName: '思わず作りたくなるグッズがいっぱい！秋の味覚特集'
        },
        {
          category: ['all', 'camera'],
          href: 'https://shop.kitamura.jp/special/sale-fair/camera/album/feature/2519/',
          src: 'https://shopimg.kitamura.jp/images/banner/3496.jpg',
          productName: '年賀状のデコレーションにおすすめ！\n年賀状に書ける「写真ペン」'
        },
        {
          category: ['all', 'kaden'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/hit-kaden/',
          src: 'https://shopimg.kitamura.jp/images/banner/5992.png',
          productName: '忘年会の景品やクリスマスプレゼントに人気の家電をご紹介♪'
        },
        {
          category: ['all', 'kaden', 'general'],
          href: 'https://shop.kitamura.jp/special/sale-fair/kaden/begin_large-size_kaden/',
          src: 'https://shopimg.kitamura.jp/images/banner/3781.gif',
          productName: '大型家電の設置・リサイクル回収承ります'
        },
        {
          category: ['all', 'general'],
          href: 'https://shop.kitamura.jp/special/sale-fair/page/telephoneorder-info/',
          src: 'https://shopimg.kitamura.jp/images/banner/1361.jpg',
          productName: '電話注文のご案内'
        },
        {
          category: ['all', 'general'],
          href: '/ec/guide/s_credit_01',
          src: 'https://shopimg.kitamura.jp/images/banner/1486.gif',
          productName: 'ショッピングクレジット'
        }
      ]
    });

    const filterByCategory = computed(() => {
      const result = state.campaignlist.filter((value) => {
        if (value.category.indexOf(state.category) !== -1) {
          return value;
        }
      });
      return result;
    });

    const searchByCategory = (value: string) => {
      state.category = value;
    };

    return {
      ...toRefs(state),
      filterByCategory,
      searchByCategory
    };
  }
});
