

































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { reactive } from '@vue/composition-api';
export default Vue.extend({
  name: 'page-wrapping-gift',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: () => {
    document.title = 'ラッピングギフトについて | カメラのキタムラネットショップ'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'プレゼントや景品にぴったり。高級感ある不織布素材使ったラッピングギフトバッグをご用意しています。カメラのキタムラ')
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップトップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'ラッピングギフトについて',
          disabled: true
        }
      ],
    });
  }
});
