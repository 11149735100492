













































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'campaign',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  methods: {
    linkToOtherWindow(url: string | undefined) {
      window.open(url, '_blank');
    }
  },
  setup: (props, context) => {
    document.title = 'メーカーキャンペーン | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '今が買い時！メーカーキャンペーン！キャッシュバックやプレゼントなど、お得なメーカーキャンペーンをご紹介♪ | カメラのキタムラ');
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップトップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: true
        },
        {
          path: 'メーカーキャンペーン',
          disabled: true
        }
      ],
      campaignList: [
        // {
        //   campaignName: '',
        //  img: '',
        //  href: '',
        //  Contents: '',
        //  TargetPeriod: ''
        // },<!--▼-->
        {
          campaignName: 'フジフイルム GFX100S II レンズ同時購入キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9831.jpg',
          href: 'https://fujifilm-x.com/ja-jp/special/cb25-gf-set/',
          Contents: '期間中、対象商品をご購入後に製品登録の上、ご応募いただいたお客様全員に最大10万円をキャッシュバック',
          TargetPeriod: '2025年1月23日(木)～2025年3月31日(月)'
        },
        {
          campaignName: 'ニコン Z 9 Cfexpressメモリーカードセットキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9573.jpg',
          href: 'https://www.nikon-image.com/event/campaign/z_9_cf_campaign2024/',
          Contents: '期間中、Z 9をご購入された方に『CFexpress type Bメモリーカード660GB MC-CF660G』をプレゼント',
          TargetPeriod: '2024年4月26日(金)～なくなり次第終了'
        },
        {
          campaignName: '銘匠光学 ビューファインダープレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/8586.jpg',
          href: 'https://stkb.co.jp/info/?p=18267',
          Contents: '期間中、TTArtisan 21mm f/1.5 ASPH Mマウントレンズを購入でビューファインダーをプレゼント！※なくなり次第終了',
          TargetPeriod: '2021年9月1日(水)～なくなり次第終了'
        },
        {
          campaignName: 'ツァイスロゴ入りグッズプレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9774.jpg',
          href: 'https://www.kenko-tokina.co.jp/lp/campaign2024/zeiss-conquest/',
          Contents: '期間中、対象商品をご購入&ご応募されたの方の中から抽選で20名様にZEISSロゴ入りネックウォーマーと使い捨てレンズクリーナーをプレゼント',
          TargetPeriod: '2024年11月22日(金)～2025年1月31日(金)'
        },
        {
          campaignName: 'トーリハン ドライキャビ QUOカードPayプレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9381.jpg',
          href: 'http://www.dry-cabi.co.jp/c/cam2311.html',
          Contents: '期間中、対象商品ご購入の方全員に最大7,000円分のQUOカードPayをプレゼント',
          TargetPeriod: '2023年11月1日(水)～2025年3月31日(月)  '
        },
        {
          campaignName: 'パナソニック ラムダッシュPRO6枚刃 キャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9727.jpg',
          href: 'https://panasonic.jp/shaver/campaign/24winter-lamdashpro6-cashback.html',
          Contents: '期間中、対象品をご購入&ご応募いただいた方に最大5,000円をもれなくプレゼント',
          TargetPeriod: '2024年11月1日(金)～2025年1月31日(金)'
        },
        {
          campaignName: 'パナソニック はくだけキュッとリフレ オリジナルホルダープレゼント',
          img: 'https://shopimg.kitamura.jp/images/banner/9681.jpg',
          href: 'https://panasonic.jp/massage_parts/campaign/2409-gift.html',
          Contents: '期間中、対象商品をご購入・応募いただいた方にもれなく専用オリジナルホルダーをプレゼント',
          TargetPeriod: '2024年9月3日(火)～2025年1月31日(金)'
        },
        {
          campaignName: 'パナソニック ラムダッシュ パームイン NAGORI専用置台プレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9563.jpg',
          href: 'https://panasonic.jp/shaver/campaign/24spring-palm-gift.html',
          Contents: '期間中、対象商品を購入後、WEBでの応募で本体と同じ色・素材を使用した専用置台をもれなくプレゼント',
          TargetPeriod: '2024年4月1日(月)～2025年1月31日(金)'
        },
        {
          campaignName: 'パナソニック レンジ IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9204.jpg',
          href: 'https://panasonic.jp/range/service/iot-ext-warranty.html',
          Contents: 'レンジを専用アプリにつないで無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        },
        {
          campaignName: 'パナソニック 全自動ディーガ IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9205.jpg',
          href: 'https://panasonic.jp/diga/service/iot-ext-warranty.html',
          Contents: 'レコーダーを専用アプリにつないで無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        },
        {
          campaignName: 'パナソニック オートクッカー IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9203.jpg',
          href: 'https://panasonic.jp/cook/service/iot-ext-warranty.html',
          Contents: 'クッカーを専用アプリにつないぐと、無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
