












































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'shacolla',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    orangeBtn: OrangeBtn,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'フジフイルム シャコラ（shacolla）で写真を壁に飾ろう！ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '壁に貼って剥がせるフォトパネル「シャコラ」を使って、お気に入りの写真を壁に飾りましょう！賃貸の壁でも安心！画鋲を使わず写真が貼れます。カメラのキタムラのショッピングサイトにお任せください。'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'フジフイルム シャコラ（shacolla）で写真を壁に飾ろう！',
          disabled: true
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/frame/cork-frame',
          img: 'https://shopimg.kitamura.jp/images/banner/9237.jpg',
          alt: 'リサイクル素材で作ったフォトスタンド'
        },
        {
          href: '/ec/special/camera/frame',
          img: 'https://shopimg.kitamura.jp/images/banner/2780.gif',
          alt: '額縁・写真フォトフレーム おすすめシリーズ紹介'
        },
        {
          href: 'https://fremia.com/kitamura',
          img: 'https://shopimg.kitamura.jp/images/banner/1655.gif',
          alt: 'オーダーメイド額フレミア'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    return {
      ...toRefs(state),
      noimage
    };
  }
});
