







































































import Vue from 'vue';
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'photomount',
  components: {
    recommendedFeatures: RecommendedFeatures,
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '写真台紙 おすすめシリーズ紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '写真台紙 シリーズ紹介。写真台紙のお買い求めは、カメラのキタムラにおまかせください！お手軽な普通写真台紙や婚礼用の写真台紙、高級写真台紙まで勢揃い♪全国のカメラ専門店カメラのキタムラネットショップへおまかせください。'
      );
    const state = reactive({
      category: 'all',
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '見て選ぶ・見て探す。おすすめ写真台紙紹介',
          disabled: true
        }
      ],
      // ↑ ---- パンくず情報 ----
      narrowingList: [
        { src: '/ec/images2/special/camera/photomount/btn-print-w.png', value: 'all' },
        { src: '/ec/images2/special/camera/photomount/btn-new-w.png', value: 'popularity' },
        { src: '/ec/images2/special/camera/photomount/btn-general-w.png', value: 'lpf' },
        { src: '/ec/images2/special/camera/photomount/btn-camera-w.png', value: 'pf' },
        { src: '/ec/images2/special/camera/photomount/btn-sale-w.png', value: 'sc' }
      ],
      photoMountList: [
        {
          category: ['all', 'pf'],
          href: '/ec/list?keyword=NO890&narrow18=0',
          name: 'ハクバ NO890',
          src: 'https://shopimg.kitamura.jp/images/pd/195/ae2/e06/9c0/f3f/314/255/80c/6be/f64/c3s/mrm/ex2/n/L.jpg',
          size: '対応サイズ：6切'
        },
        {
          category: ['all', 'lpf', 'popularity', 'sc'],
          href: '/ec/list?keyword=ハクバ M2020&narrow18=0',
          name: 'ハクバ M2020',
          src: 'https://shopimg.kitamura.jp/images/pd/4e0/e61/324/5e4/488/85e/fbc/522/300/5ba/aez/0i6/99z/m/L.jpg',
          size: '対応サイズ：2L 6切 A4'
        },
        {
          category: ['all', 'lpf'],
          href: '/ec/list?keyword=ハクバ M1770&narrow18=0',
          name: 'ハクバ M1770',
          src: 'https://shopimg.kitamura.jp/images/pd/774/155/7c2/69c/f64/944/9a4/4a6/285/cc6/0eg/k2r/os4/1/L.jpg',
          size: '対応サイズ：L 2L 6切'
        },
        {
          category: ['all', 'lpf'],
          href: '/ec/list?keyword=写真台紙 ジョイフル ハネムーン&narrow18=0',
          name: 'ハクバ 写真台紙 ジョイフル ハネムーン',
          src: 'https://shopimg.kitamura.jp/images/pd/b89/e82/2d3/46a/97f/959/c1e/25d/683/975/b3f/8f3/rqb/e/L.jpg',
          size: '対応サイズ：2L'
        },
        {
          category: ['all', 'lpf'],
          href: '/ec/list?keyword=%E3%83%8F%E3%82%AF%E3%83%90%20MWM&narrow18=0',
          name: '写真台紙 マリアージュ ブーケ',
          src: 'https://shopimg.kitamura.jp/images/pd/012/638/c53/61d/979/eef/35d/890/91f/518/d4p/e3m/97t/1/L.jpg',
          size: '対応サイズ：2L 6切'
        },
        {
          category: ['all', 'lpf'],
          href: '/ec/special/camera/photomount/269',
          name: 'ハクバ M890',
          src: 'https://shopimg.kitamura.jp/images/pd/da5/c2a/246/623/df9/478/bbe/7e5/617/d7d/921/hra/n0l/y/M.jpg',
          size: '対応サイズ：6切'
        },
        {
          category: ['all', 'lpf'],
          href: '/ec/special/camera/photomount/266',
          name: 'ハクバ M2730',
          src: 'https://shopimg.kitamura.jp/images/pd/443/c74/564/bff/b2e/9af/e33/9e0/4b0/c1d/868/h87/6pt/x/M.jpg',
          size: '対応サイズ：２L 6切 '
        },
        {
          category: ['all', 'lpf', 'pf', 'popularity'],
          href: '/ec/special/camera/photomount/254',
          name: 'ハクバ MRCDO ランス ドゥシリーズ',
          src: 'https://shopimg.kitamura.jp/images/pd/299/db3/5c7/11e/f1a/1d1/cdb/8bc/0e9/09c/355/zfu/b0f/m/M.jpg',
          size: '対応サイズ：L 2L はがき'
        },
        //{
        //  category: ['all', 'lpf', 'pf', 'sc'],
        //  href: '/ec/special/camera/photomount/252',
        //  name: 'チクマ V-700 お祝いの写真台紙',
        //  src: 'https://shopimg.kitamura.jp/images/pd/5b6/1b0/f9a/08e/74e/8da/ad3/959/922/78e/4fb/ci2/j3r/k/M.jpg',
        //  size: '対応サイズ：2L'
        //},
        //{
        //  category: ['all'],
        //  href: '/ec/special/camera/photomount/2431',
        //  name: 'チクマ 差し込みポートレート写真台紙 V-68',
        //  src: 'https://shopimg.kitamura.jp/images/pd/3a8/dec/103/4e5/73a/0c4/805/839/c17/095/9b0/8kc/jpn/9/M.jpg',
        //  size: '対応サイズ：ましかく'
        //}
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/sale/lotbuying',
          img: 'https://shopimg.kitamura.jp/images/banner/3773.gif',
          alt: 'まとめ買いコーナー'
        },
        {
          href: '/ec/special/camera/album/feature',
          img: 'https://shopimg.kitamura.jp/images/banner/3597.jpg',
          alt: '手作りアルバム特集'
        },
        {
          href: '/ec/special/general/graduation_entrance',
          img: 'https://shopimg.kitamura.jp/images/banner/1929.gif',
          alt: '卒業・入学特集'
        },
        {
          href: '/ec/special/general/sportsday',
          img: 'https://shopimg.kitamura.jp/images/banner/2006.gif',
          alt: 'パパ・ママ応援！運動会特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });

    const filterByCategory = computed(() => {
      const result = state.photoMountList.filter((value) => {
        if (value.category.indexOf(state.category) !== -1) {
          return value;
        }
      });
      return result;
    });

    const searchByCategory = (value: string) => {
      state.category = value;
    };

    return {
      ...toRefs(state),
      filterByCategory,
      searchByCategory
    };
  }
});
