import { render, staticRenderFns } from "./usedcameracoupon.vue?vue&type=template&id=ca17c5ee&scoped=true&"
import script from "./usedcameracoupon.vue?vue&type=script&lang=ts&"
export * from "./usedcameracoupon.vue?vue&type=script&lang=ts&"
import style0 from "./usedcameracoupon.vue?vue&type=style&index=0&id=ca17c5ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca17c5ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
