






















































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
//import Product from '@/components/product-list/product.vue';
import { noimage, formatPrice } from '@/logic/utils';
//import ProductListService from '@/logic/product-list.service';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
//import { ConditionItem } from '@/types/conditions';
//import { ProductItem } from '@/types/product-list';
//import { NEWER_SORT_LIST } from '@/constants/sort-list';
//import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
//import { SEARCH_STATE } from '@/constants/search-state';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'astronomical',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    //product: Product
    categorymenulist: Categorymenulist
  },

  setup: (props, context) => {
    document.title = '天体イベント スケジュール | 天体観測 皆既月食・部分月食・流星群 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'カメラのキタムラネットショップで、皆既月食、部分月食、流星群などの天体観測スケジュールをご覧いただけます。素晴らしい月や星空を楽しむためのおすすめ天体望遠鏡や双眼鏡も豊富に取り揃えています。天体観察愛好者のための最適な選択肢をぜひカメラのキタムラネットショップにおまかせください。'
      );
    const state = reactive({
      naviList: [
        //{ naviItem: '月と火星が\n 接近', href: '#moon02' },
        //{ naviItem: '月が火星と\nアルデバランに接近', href: '#moon03' },
        //{ naviItem: '月が火星に\n大接近', href: '#moon01' },
        { naviItem: '中秋の名月\nとは', href: '#harvestmoon' },
        { naviItem: '皆既月食\nとは', href: '#totaleclipsemoon' },
        { naviItem: 'スーパームーン\n現象', href: '#supermoon' },
        { naviItem: '星空の疑問\nTOP3', href: '#qa' },
        { naviItem: 'おすすめ\n天体望遠鏡', href: '#maaspic' },
        { naviItem: '天体観測\nおすすめ商品', href: '#osusumeitm' },
        { naviItem: '天体観測\nおすすめアプリ', href: '#osusumeapr' },
        { naviItem: 'おすすめ特集', href: '#sogo' }
      ],
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '天体イベント スケジュール | 天体観測 皆既月食・部分月食・流星群',
          linkUrl: '/ec/special/camera/astronomical',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      // ↓ ---- メイン商品 ----
      // 取得するJancode
      mainProductJanCode: ['4974019102863'],
      // 結果格納用
      mainProductList: [] as Array<ProductDetail>,
      // ↑ ---- メイン商品 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4974019102863'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });

    function loadImg(path: string) {
      return require('@/assets/categories/' + path);
    }
    return {
      ...toRefs(state),
      noimage,
      loadImg,
      formatPrice
    };
  }
});
